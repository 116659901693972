import { Injectable } from "@angular/core";
import { Location } from "@angular/common";
import { Router, NavigationEnd } from "@angular/router";

@Injectable({ providedIn: "root" })
export class NavigationService {
    previousUrl: string | null = null;
    currentUrl: string = '';
    private historyUrl: string | null = null;

    constructor(private router: Router, private location: Location) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.previousUrl = this.currentUrl;
                this.currentUrl = event.url;
                this.historyUrl = event.urlAfterRedirects;
            }
        });
    }

    getPreviousSlug(): string | null {
        if (this.previousUrl) {
            const segments = this.previousUrl.split('/');
            return segments.pop() || null;
        }
        return null;
    }

    getCurrentSlug(): string | null {
        if (this.currentUrl) {
            const segments = this.currentUrl.split('/');
            return segments.pop() || null;
        }
        return null;
    }

    back(slug?: string): void {
        if (slug) {
            setTimeout(() => {
                this.router.navigateByUrl(`/${slug}`);
            }, 0);
        } else if (this.historyUrl) {
            this.location.back();
        } else {
            setTimeout(() => {
                this.router.navigateByUrl("/");
            }, 0);
        }
    }
}