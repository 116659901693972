import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Meta, Title } from '@angular/platform-browser';
import { ScriptService, MetaDataService, BlogService } from '../services';
import { GeolocationService } from '../geolocation.service';
import { LanguageService } from '../services/language.service';
import { DicomBlog, Tag } from '../types/dicom-blog';

interface Blog {
  title: string;
  author: string;
  createAt: string;
  views: number;
  introduce: string;
  imageUrl: string;
  tags: string[];
  urlBlog: string;
}

@Component({
  selector: 'app-blog-single',
  templateUrl: './blog-single.component.html',
  styleUrls: ['./blog-single.component.css'],
})
export class BlogSingleComponent extends MetaDataService implements OnInit {
  blog: any = {};
  isLoad = false;
  blogs: any = [];
  testMarkup: SafeHtml;
  htmlString: string;
  userAvatar: string;
  categories: any[] = [];
  tags: Tag[] = [];

  public userLanguage: string;
  public userCountry: string;
  relatedBlogUrl: string = '';
  public shouldHideDiv: boolean = false;
  hasIncreasedView: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private scriptService: ScriptService,
    private sanitized: DomSanitizer,
    private geolocation: GeolocationService,
    private languageService: LanguageService,
    private blogService: BlogService,
    private location: Location,
    private router: Router,
    title: Title,
    meta: Meta,
  ) {
    super(title, meta);
    this.scriptService.load(this.scriptService.INIT).then(() => {
      this.isLoad = true;
    });

    this.geolocation.getClientLocale().subscribe((data) => {
      this.userLanguage = data.language == 'vn' ? 'vi' : data.language;
      this.userCountry = data.country;
    });
  }

  ngOnInit(): void {
    const lang = this.location.prepareExternalUrl('/').replace("/", "").replace("/", "") == 'en' ? 'en' : 'vi';

    console.log('--------------> lang', this.location.prepareExternalUrl('/'), lang)
    this.route.paramMap.subscribe((params) => {
      const urlBlog = params.get('slug');
      this.getBlog(urlBlog, lang)
        .then(({ title, excerpt, imageUrl }) => {
          this.updateTags(
            title,
            excerpt,
            this.location.prepareExternalUrl(this.router.url),
            imageUrl
          );
        });
    });

    // this.getCategories(lang);
    // this.getTags(lang);

    this.blogService.getCategories(lang)
      .subscribe((response) => {
        this.categories = response.data.map(category => {
          // count blog of category
          category.blogCount = category.dicomBlogs?.length;
          if (!category.blogCount) {
            return null;
          }
          return category
        }, error => { })
          .filter(x => x) // remove category without blog
      })

    this.blogService.getRecentBlogs(lang)
      .subscribe(
        response => {
          this.blogs = response.data;
          this.blogs.forEach((blog: DicomBlog) => {
            this.formatBlogDate(blog);
          });
        },
        error => { }
      );

    // count blog after 9 second
    // setTimeout(() =>{ this.countBlogView(this.blog); }, 9000);
  }

  private getBlog(urlBlog: string, lang: string): Promise<{ title: string, excerpt: string, imageUrl: string }> {
    return new Promise((resolve, reject) => {
      // this.route.queryParams.subscribe((queryParams) => {
      // response array with one element
      this.blogService.getBlogBySlug(urlBlog, lang).subscribe(
        (response) => {
          // if (!this.blog && lang == 'en') {
          //   return this.getBlog(urlBlog, 'vi')
          // }
          // if (!this.blog) {
          //   const changeLang = this.userLanguage == 'en' ? 'en' : 'vn';
          //   return this.router.navigateByUrl(`${changeLang}/blog/`);
          // }

          // const { localizations } = this.blog
          // if (localizations && localizations.length) {
          //   const { slug } = localizations[0];
          //   const locale = this.userLanguage == 'en' ? 'vi' : 'en';
          //   localStorage.setItem(this.blog.slug, JSON.stringify({ locale, slug }));
          // }
          if (response?.data && response?.data.length) {
            this.blog = response?.data[0];
            // format data of blog
            this.formatBlogDate(this.blog);
            this.htmlString = this.blog?.content;
            this.userAvatar = this.blog?.user?.avatar[0]?.formats?.xxsmall?.url;
            this.testMarkup = this.sanitized.bypassSecurityTrustHtml(this.htmlString);
            this.tags = this.blog?.tags || [];
            let imageUrl = this.blog?.titleImage?.formats?.small?.url || this.blog.titleImage?.formats?.thumbnail?.url || ''
            resolve({ title: this.blog?.title, excerpt: this.blog?.excerpt, imageUrl });
          } else {
            reject("Can not fetch blog");
          }
        },
        (error) => {
          this.handleBlogError(error);
          reject(error);
        }
      );
      // });
    });
  }

  // private getCategories(language: string) {
  //   this.blogService.getCategories(language)
  //     .subscribe((response) => {
  //       this.categories = response.data.map(category => {
  //         // count blog of category
  //         category.blogCount = category.dicomBlogs?.length;
  //         if (!category.blogCount) {
  //           return null;
  //         }
  //         return category
  //       })
  //         .filter(x => x) // remove category without blog
  //     })
  // }

  private getTags(language: string) {
    this.blogService.getTags(language)
      .subscribe((response) => {
        this.tags = response.data;
      })
  }

  changeLanguageAndReload(language: string, event: MouseEvent, Url: any) {
    this.languageService.changeLanguage(language, event, Url);
  }

  countBlogView(blog: DicomBlog) {
    this.blogService
      .countBlogView(blog.documentId, this.userLanguage, blog.views + 1)
      .subscribe((response) => {
        console.log('count view');
      })
  }

  handleBlogError(error: any): void {
    alert(
      'An error occurred while fetching the blog data. Please try again later.'
    );
  }

  public scrollToTop() {
    window.scrollTo(0, 0);
  }

  private formatBlogDate(blog: DicomBlog) {
    // Extract the date part (ignoring the extra number at the end)
    const datePart = blog?.defaultPublishedDate || blog?.publishedAt?.split(" ")[0];
    // Convert to Date object
    const date = new Date(datePart);

    // Format the date
    blog.blogDate = date.toLocaleDateString("en-US", {
      month: "short", // "Feb"
      day: "2-digit", // "18"
      year: "numeric" // "2025"
    });

    // return may nothing because we already set blog Object
    return blog;
  }

}
