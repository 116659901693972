<!--Breadcrumb Area-->
<section class="breadcrumb-area banner-5">
  <div class="text-block">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 v-center">
          <div class="bread-inner">
            <div class="bread-menu wow fadeInUp" data-wow-delay=".2s">
              <ul>
                <li><a [routerLink]="['/']" i18n>Home</a></li>
                <li><a [routerLink]="['/portfolio' , '']" i18n>Portfolio</a></li>
                <li><span i18n>Project Details</span></li>
              </ul>
            </div>
            <div class="bread-title wow fadeInUp" data-wow-delay=".5s">
              <h1 i18n>Truong Dinh Holdings Corporate Site</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Breadcrumb Area-->

<!--Start Case Study About-->
<section class="case-study pad-tb">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="image-block upset">
          <img src="assets/images/portfolio/image-10.webp" alt="case study" class="img-fluid no-shadow" />
        </div>
      </div>
      <div class="col-lg-6 block-1 v-center">
        <div class="common-heading inner-heading text-l pl25">
          <span i18n>About The Project</span>
          <h2 i18n>Another Beautiful Wordpress Site</h2>
          <p i18n>Welcome to TDH's new and dynamic WordPress site, showcasing the 6 subsidiaries across various industries,
            including Oil & Gas, Real Estate, and Investment.</p>
          <p i18n>The site has captivating animations, a professional design, and provides comprehensive updates on ventures
            and achievements in the portfolio and news sections.</p>
          <p i18n>Whether you're a potential investor or a curious reader, we invite you to explore our site and learn more
            about TDH's diverse and thriving businesses.</p>
          <p><strong> Wordpress 6.x | WPBakery PageBuilder | YoastSEO | Figma </strong></p>
        </div>
        <div class="project-platform mt60 pl25">
          <div class="project-platform-used -shadow"><a href="https://tdholding.dicom-interactive.com/"
              target="blank"><img src="assets/images/icons/www.webp" alt="visit"></a>
          </div>
          <div><strong> ⤺ </strong><span style="font-size: smaller;" i18n>Click here to visit the site</span></div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Case Study About-->
<section class="case-study pad-tb bg-gradient6 deep-dark">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <div class="common-heading">
          <span i18n>Service Features</span>
          <h2 class="mb30" i18n>As per client's need, we have developed a dynamic and mobile-friendly Wordpress site.
          </h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <h4 class="mt20 mb10" i18n>Elegant Animation</h4>
        <ul class="list-style-">
          <li i18n>Enhance the browsing experience with elegant animations on our TDH website. Our subtle yet captivating
            animations bring a touch of sophistication and style to the user interface, making your visit both engaging
            and visually stunning.</li>
          <li i18n>Adding a background video at the top of the website's homepage to immediately capture your attention and
            set the tone for your browsing experience. The video showcases the brand and provides a dynamic introduction
            to the site offerings.</li>
        </ul>
      </div>
      <div class="col-lg-6">
        <h4 class="mt20 mb10" i18n>YoastSEO and Mobile-friendly</h4>
        <ul class="list-style-">
          <li i18n>Improve SEO with YoastSEO on our WordPress site. This powerful tool helps optimize the content and
            increase visibility on search engines, driving more traffic to our client's site.
          </li>
          <li i18n>Explore the content on-the-go with our mobile-friendly site. Our responsive design ensures a seamless
            browsing experience across all devices.</li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <h4 class="mt20 mb10" i18n>Multi-language</h4>
        <ul class="list-style-">
          <li i18n>We offer a website that supports two languages - English and Vietnamese - to ensure that our content can
            reach a wider audience.</li>
          <li i18n>In addition to language support, we have also integrated a blog into our website, allowing you to stay
            up-to-date on the latest news and trends in our industry. With these features, we aim to provide a
            user-friendly and informative browsing experience that caters to the needs of all our visitors.
          </li>
        </ul>
      </div>
      <div class="col-lg-6">
        <h4 class="mt20 mb10" i18n>Other Features</h4>
        <ul class="list-style-">
          <li i18n>Additionally, we have integrated a dynamic gallery on our website that allows you to view our products and
            services in an interactive and visually appealing way. With these features, we aim to create an engaging and
            memorable experience for our visitors, while also providing easy access to important information about our
            business.</li>
        </ul>
      </div>
    </div>
  </div>
</section>
<!--Start Project Challenges-->
<section class="case-study pad-tb">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-sm-6">
        <h2 class="mt20 mb20" i18n>Challenges</h2>
        <p i18n>Our development team encountered challenges while building a website for a client that affected project
          progress. We initially had difficulty capturing the client's vision, leading to revisions and timeline delays.
          The client's preferred design style also required us to adapt our methods.<br><br></p>
        <p i18n>The project's tight deadline added pressure to deliver high-quality work within a short timeframe, making it
          challenging to address issues during development, which led to working long hours to meet the
          deadline.<br><br></p>
        <p i18n>Due to the client's lack of experience in managing a web project, miscommunications and misunderstandings
          arose during the development process. We assumed a project management role to ensure everyone was aligned and
          that the project progressed smoothly.<br><br></p>
        <p i18n>Our development team worked closely with the client to overcome challenges and ensure on-time delivery of a
          successful website that met their expectations. Open communication and adaptability were key to meeting their
          needs and achieving success despite the difficulties.</p>
      </div>
      <div class="col-lg-6 col-sm-6">
        <div class="project-view m-mt30">
          <img src="assets/images/case-study/goen-view.webp" alt="case study" class="img-fluid" />
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Project Challenges-->
<!--Start Solutions-->
<section class="case-study pad-tb bg-gradient5 deep-dark">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 block-1 v-center">
        <div class="common-heading inner-heading text-l pr25">
          <span i18n>Solutions</span>
          <h2 i18n>How We Work</h2>
          <h4 i18n>We chose to build our client's website using WordPress, as it met their requirements and provided the
            necessary functionality without the need for additional technologies.<br><br></h4>
          <p i18n>For two weeks, our team met with TDH on several occasions at their office to kick-off the project, listen
            to their desires and expectations, and gain a thorough understanding of their needs. Simultaneously, we
            collaborated to analyze and sketch out their ideas, working together until that sketch became our final
            interactive prototype.
          </p>
          <p i18n>After finalizing the wireframe and securing the contract, our designers joined the team to collaborate on
            the project's vision. They created two different homepage designs for TDH to choose from, which we then
            adapted and applied to all other pages. As we completed each page, we worked closely with the client to
            fine-tune and ensure that it met their expectations.
          </p>
          <p i18n>Using the Agile methodology during the development stage, we established clear milestones and delivered
            shippable packages to our client to ensure that we remained on the right track.</p>
        </div>
        <div class="card-nx row">
          <div class="col-lg-3 col-md-6 col-sm-6 col-6 mt30">
            <div class="icon-card- hfull">
              <img src="assets/images/icons/logo-and-branding.svg" alt="case study" class="img-fluid">
              <h4>UI/UX Graphic Design</h4>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-6 mt30">
            <div class="icon-card- hfull">
              <img src="assets/images/icons/branding.svg" alt="case study" class="img-fluid">
              <h4>Front-end Development</h4>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-6 mt30">
            <div class="icon-card- hfull">
              <img src="assets/images/icons/back.svg" alt="case study" class="img-fluid">
              <h4>Back-end/API Development</h4>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-6 mt30">
            <div class="icon-card- hfull">
              <img src="assets/images/icons/seo.svg" alt="case study" class="img-fluid">
              <h4>Yoast SEO Wordpress Plugin</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Solutions-->
<div class="case-study pad-tb">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="porto-slide owl-carousel">
          <div class="screen-splits"> <img src="assets/images/case-study/tdh-web-1.webp" alt="case study"
              class="img-fluid" style="border-style: solid; border-width: thin;" /></div>
          <div class="screen-splits"> <img src="assets/images/case-study/tdh-web-2.webp" alt="case study"
              class="img-fluid" style="border-style: solid; border-width: thin;" /></div>
          <div class="screen-splits"> <img src="assets/images/case-study/tdh-web-3.webp" alt="case study"
              class="img-fluid" style="border-style: solid; border-width: thin;" /></div>
          <div class="screen-splits"> <img src="assets/images/case-study/tdh-web-4.webp" alt="case study"
              class="img-fluid" style="border-style: solid; border-width: thin;" /></div>
          <div class="screen-splits"> <img src="assets/images/case-study/tdh-web-5.webp" alt="case study"
              class="img-fluid" style="border-style: solid; border-width: thin;" /></div>
          <div class="screen-splits"> <img src="assets/images/case-study/tdh-web-6.webp" alt="case study"
              class="img-fluid" style="border-style: solid; border-width: thin;" /></div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Start Navigate-->
<section class="testinomial-section pad-tb">
  <div class="container">
    <div class="row">
      <div class="col-6 v-center">
        <a [routerLink]="['/', 'portfolio', previousLink]" class="nav_link" i18n>&laquo; Previous</a>
      </div>
      <div class="col-6 v-center">
        <a [routerLink]="['/', 'portfolio', nextLink]" class="nav_link next_nav_link" i18n>Next &raquo;</a>
      </div>
    </div>
  </div>
</section>
<!--End Navigate-->


<!--Start Testinomial-->
<app-testinomial-component></app-testinomial-component>
<!--End Testinomial-->
