<section class="breadcrumb-area" data-background="assets/images/banner/13.webp">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 v-center">
        <div class="bread-inner">
          <div class="bread-menu wow fadeInUp" data-wow-delay=".1s">
            <ul>
              <li><a [routerLink]="['/']" i18n> Home</a></li>
              <li>
                <span i18n>Media Banners</span>
              </li>
            </ul>
          </div>
          <div class="bread-title wow fadeInUp" data-wow-delay=".2s">
            <h1 i18n>Media Banners</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section
  id="banners-standard"
  class="service banners standard pad-tb bg-gradient5"
>
  <div class="container" style="max-width: 800px">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div class="common-heading ptag">
          <!-- <span>Services</span> -->
          <h2 i18n>Standard Media Banners</h2>
          <p class="mb30" i18n>
            Standard Media Banners are interactive ads with video, animations,
            and dynamic elements. They boost engagement, improve click-through
            rates, and deliver impactful messages, making them essential for
            effective digital marketing campaigns.
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div
        class="col-12 col-sm-6 single-card-item wow fadeInUp"
        *ngFor="let banner of standard_banners; let i = index"
        [attr.data-wow-delay]="'0.' + (i + 1) + 's'"
        data-wow-duration="1.1s"
        data-wow-offset='150'
      >
      <a class="standard-item" href="#"
      id="{{ banner?.slug }}"
      data-toggle="modal"
      [routerLink]="['/services/media-banners', banner?.slug]"
      >
        <div class="isotope_item h-scl-">
          <div class="item-image h-scl-base">
            <img
                [src]="banner?.src"
                alt="{{ banner?.title }}"
                title="{{ banner?.title }}"
                class="img-fluid"
              />
          </div>
          <div class="item-info">
            <h4>{{banner?.title}}</h4>
            <p>{{banner?.title}}</p>
          </div>
        </div>
      </a>
      </div>
    </div>
  </div>
</section>

<div>
  <div class="modal" id="modalform">
    <div class="modal-dialog modal-banner modal-xl modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <div></div>
          <button type="button" class="closes" (click)="closeBanner()">&times;</button>
        </div>
        <!-- Modal body -->
        <div class="modal-body pt40 pb60">
            <div class="row mt-4 overflow-hidden">
              <div
                class="col-12 col-lg-5 col-xl-4 text-center"
              >
              <ng-container *ngIf="selectorBanner?.srcBanner">
                <iframe
                  [width]="selectorBanner?.width"
                  [height]="selectorBanner?.height"
                  [src]="selectorBanner?.srcBanner | safe : 'resourceUrl'"
                  loading="eager"
                  class="{{ selectorBanner?.slug }}"
                  frameborder="0"
                  scrolling="no"
                  style="border: none; overflow: hidden"
                  sandbox="allow-scripts allow-same-origin allow-popups"
                ></iframe>
              </ng-container>
              </div>
              <div
                class="col-12 col-lg-7 col-xl-8 mt-5 mt-lg-0"
                [innerHTML]="selectorBanner?.description"
              ></div>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>

<section id="banners-rich" class="service banners rich pad-tb bg-gradient5">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div class="common-heading ptag">
          <span i18n>Services</span>
          <h2 i18n>Rich Media Banners</h2>
          <p class="mb30" i18n>
            Rich Media Banners are interactive ads with video, animations, and
            dynamic elements. They boost engagement, improve click-through
            rates, and deliver impactful messages, making them essential for
            effective digital marketing campaigns.
          </p>
        </div>
      </div>
    </div>
    <div class="tab-17 tabs-layout banners mt30">
      <ul class="nav nav-tabs wow fadeInUp" data-wow-delay=".4s">
        <li class="nav-item" *ngFor="let tab of rich_banners">
          <a
            class="nav-link"
            style="cursor: pointer"
            [routerLink]="['/services/media-banners', tab?.slug]"
            [ngClass]="{ active: tab?.show }"
            [id]="tab?.slug"
            >{{ tab?.title }}</a
          >
        </li>
      </ul>
      <div class="tab-content">
        <div
          *ngFor="let tab of rich_banners"
          class="tab-pane fade show"
          [ngClass]="{ active: tab?.show }"
        >
          <div class="row mt-4 overflow-hidden">
            <div
              data-wow-delay=".5s"
              class="col-12 col-lg-5 col-xl-4 text-center wow slideInLeft {{tab?.coverStyle}}"
            >
             <p *ngIf="tab.slug === 'parallax-banner'" class="demo-text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
             </p>
              <iframe
                *ngIf="tab?.show && isScriptLoaded"
                id="myExpandableIframe"
                [width]="tab?.width"
                [height]="tab?.height"
                [src]="tab?.src | safe : 'resourceUrl'"
                loading="eager"
                class="{{ tab?.class }} {{ tab?.slug }}"
                frameborder="0"
                scrolling="no"
                style="border: none; overflow: hidden"
              ></iframe>
              <p *ngIf="tab.slug === 'parallax-banner'" class="demo-text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
               </p>
               <div *ngIf="tab.slug === 'expandable-banner'" class="bellow-expandable-banner demo-text">
                  <p class="demo-text">
                  This is some sample text below the banner. It should remain selectable when the banner is collapsed.
                  </p>
                  <br/>
                  <p class="demo-text">
                  Try selecting this text below the banner.
                  </p>
              </div>
            </div>
            <div
              data-wow-delay=".5s"
              class="col-12 col-lg-7 col-xl-8 mt-5 mt-lg-0 wow slideInRight"
              [innerHTML]="tab?.description"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="tech-stack" class="techonology-used-">
  <div class="container">
    <ul class="h-scroll tech-icons">
      <li *ngFor="let tech of techStack">
        <a *ngIf="tech?.src"
          ><img
            [src]="tech?.src"
            alt="{{ tech?.title }}"
            title="{{ tech?.title }}"
        /></a>
      </li>
    </ul>
  </div>
</section>

<section class="service-block banners bg-gradient6 pad-tb">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div class="common-heading ptag">
          <!-- <span>Services</span> -->
          <h2 i18n>Our Services</h2>
          <p class="mb30" i18n>
            We provide a comprehensive range of services to cater to all your
            HTML canvas banner needs, ensuring quality, creativity, and
            performance at every step.
          </p>
        </div>
      </div>
    </div>
    <div class="row upset link-hover overflow-hidden">
      <div
        *ngFor="let service of services"
        class="col-lg-3 col-sm-6 mt30 wow fadeInUp"
        data-wow-delay=".2s"
      >
        <div class="s-block rp-web-service hfull">
          <div class="s-card-icon" *ngIf="service?.img">
            <img
              [src]="service?.img"
              alt="{{ service?.title }}"
              title="{{ service?.title }}"
              class="img-fluid"
            />
          </div>
          <h4>{{ service?.title }}</h4>
          <p>
            {{ service?.description }}
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<!--Start Why Choose-->
<section class="service-block pad-tb bg-gradient7">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div class="common-heading ptag">
          <span i18n>We Deliver Our Best</span>
          <h2 i18n>Why Hire Dicom Interactive</h2>
          <!--p class="mb30">Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit.</p-->
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-4 col-sm-6 mt30 wow fadeIn" data-wow-delay="0.2s">
        <div class="s-block wide-sblock hfull">
          <div class="s-card-icon">
            <img
              src="assets/images/icons/ontime.svg"
              alt="service"
              class="img-fluid"
            />
          </div>
          <div class="s-block-content">
            <h4 i18n>On Time Delivery</h4>
            <p i18n>
              We value your precious time, so we try our best to deliver the
              good on time and within budget.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 mt30 wow fadeIn" data-wow-delay=".5s">
        <div class="s-block wide-sblock hfull">
          <div class="s-card-icon">
            <img
              src="assets/images/icons/quality.svg"
              alt="service"
              class="img-fluid"
            />
          </div>
          <div class="s-block-content">
            <h4 i18n>Top Quality</h4>
            <p i18n>
              Our testing experts efficiently collaborate with you to achieve
              the best product quality.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 mt30 wow fadeIn" data-wow-delay=".8s">
        <div class="s-block wide-sblock hfull">
          <div class="s-card-icon">
            <img
              src="assets/images/icons/accountability.svg"
              alt="service"
              class="img-fluid"
            />
          </div>
          <div class="s-block-content">
            <h4 i18n>Accountability</h4>
            <p i18n>
              We fully grasp the importance of listening and understanding your
              business and objectives.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 mt30 wow fadeIn" data-wow-delay="1.1s">
        <div class="s-block wide-sblock hfull">
          <div class="s-card-icon">
            <img
              src="assets/images/icons/price.svg"
              alt="service"
              class="img-fluid"
            />
          </div>
          <div class="s-block-content">
            <h4 i18n>Reasonable Price</h4>
            <p i18n>
              We optimize our operation cost so that we could provide you
              services with bargain price.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 mt30 wow fadeIn" data-wow-delay="1.4s">
        <div class="s-block wide-sblock hfull">
          <div class="s-card-icon">
            <img
              src="assets/images/icons/process.svg"
              alt="service"
              class="img-fluid"
            />
          </div>
          <div class="s-block-content">
            <h4 i18n>Professional Process</h4>
            <p i18n>
              We standardize Agile process to improve both team productivity and
              project quality.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="-cta-btn mt70">
      <div class="free-cta-title v-center wow zoomInDown" data-wow-delay="1.8s">
        <p i18n>Let's Start a <span>New Project</span> Together</p>
        <a [routerLink]="['/contact']" class="btn-main bg-btn2 lnk" i18n
          >Inquire Now<i class="fas fa-chevron-right fa-icon"></i
          ><span class="circle"></span
        ></a>
      </div>
    </div>
  </div>
</section>
<!--End Why Choose-->
<!--Stat Projects-->
<section class="featured-project pad-tb">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div class="common-heading ptag">
          <span i18n>Our Projects</span>
          <h2 i18n>Some of Our Works</h2>
          <p class="mb0" i18n>
            We think big and have hands in all leading technology platforms to
            provide you wide array of services.
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 wow fadeInUp" data-wow-delay=".2s">
        <div class="isotope_item hover-scale">
          <div class="item-image">
            <a [routerLink]="['/portfolio', 'ask2go-mobile-applications', '']"
              ><img
                src="assets/images/portfolio/image-1.webp"
                alt="portfolio"
                class="img-fluid"
              />
            </a>
          </div>
          <div class="item-info">
            <h4>
              <a [routerLink]="['/portfolio', 'ask2go-mobile-applications', '']" i18n
                >Ask2Go - App For Traveler</a
              >
            </h4>
            <p i18n>iOS, Android Mobile App</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 wow fadeInUp" data-wow-delay=".4s">
        <div class="isotope_item hover-scale">
          <div class="item-image">
            <a [routerLink]="['/portfolio', 'goen-e-learning-system', '']"
              ><img
                src="assets/images/portfolio/image-2.webp"
                alt="portfolio"
                class="img-fluid"
              />
            </a>
          </div>
          <div class="item-info">
            <h4>
              <a [routerLink]="['/portfolio', 'goen-e-learning-system', '']" i18n
                >GoEn eLearning System</a
              >
            </h4>
            <p i18n>Web Application</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 wow fadeInUp" data-wow-delay=".6s">
        <div class="isotope_item hover-scale">
          <div class="item-image">
            <a [routerLink]="['/portfolio', 'envoca-mobile-app', '']"
              ><img
                src="assets/images/portfolio/image-3.webp"
                alt="portfolio"
                class="img-fluid"
              />
            </a>
          </div>
          <div class="item-info">
            <h4>
              <a [routerLink]="['/portfolio', 'envoca-mobile-app', '']" i18n
                >Envoca Mobile App</a
              >
            </h4>
            <p i18n>iOS, Android Mobile App</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 wow fadeInUp" data-wow-delay=".8s">
        <div class="isotope_item hover-scale">
          <div class="item-image">
            <a [routerLink]="['/portfolio', 'meoto-auto-trader', '']"
              ><img
                src="assets/images/portfolio/image-4.webp"
                alt="portfolio"
                class="img-fluid"
              />
            </a>
          </div>
          <div class="item-info">
            <h4>
              <a [routerLink]="['/portfolio', 'meoto-auto-trader', '']" i18n
                >MeOTO - Auto Trader</a
              >
            </h4>
            <p i18n>Web Application</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 wow fadeInUp" data-wow-delay="1s">
        <div class="isotope_item hover-scale">
          <div class="item-image">
            <a [routerLink]="['/portfolio', '3d-hyundai-i30-banner', '']"
              ><img
                src="assets/images/portfolio/image-5.webp"
                alt="portfolio"
                class="img-fluid"
              />
            </a>
          </div>
          <div class="item-info">
            <h4>
              <a [routerLink]="['/portfolio', '3d-hyundai-i30-banner', '']" i18n
                >3D Hyundai i30 Banner</a
              >
            </h4>
            <p i18n>HTML5 Banner</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 wow fadeInUp" data-wow-delay="1.2s">
        <div class="isotope_item hover-scale">
          <div class="item-image">
            <a [routerLink]="['/portfolio', 'ascentis-homepage-design', '']"
              ><img
                src="assets/images/portfolio/image-6.webp"
                alt="portfolio"
                class="img-fluid"
              />
            </a>
          </div>
          <div class="item-info">
            <h4>
              <a [routerLink]="['/portfolio', 'ascentis-homepage-design', '']" i18n
                >Ascentis Homepage Design</a
              >
            </h4>
            <p i18n>UI/UX Design</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 maga-btn mt60">
        <a [routerLink]="['/portfolio']" class="btn-outline" i18n
          >View More Projects <i class="fas fa-chevron-right fa-icon"></i
        ></a>
      </div>
    </div>
  </div>
</section>
<!--End Projects-->

<!--Start CTA-->
<section class="cta-area pad-tb">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div class="common-heading">
          <span i18n>Let's work together</span>
          <h2 i18n>We Love to Listen to Your Requirements</h2>
          <a [routerLink]="['/contact']" class="btn-outline" i18n
            >Estimate Project <i class="fas fa-chevron-right fa-icon"></i
          ></a>
          <p class="cta-call" i18n>
            Or call us now
            <a href="tel:+61402752523"
              ><i class="fas fa-phone-alt"></i> (+61) 402 7525 23</a
            >
            or
            <a href="tel:+84907506614"
              ><i class="fas fa-phone-alt"></i> (+84) 907 5066 14</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="shape shape-a1">
    <img src="assets/images/shape/shape-3.svg" alt="shape" />
  </div>
  <div class="shape shape-a2">
    <img src="assets/images/shape/shape-4.svg" alt="shape" />
  </div>
  <div class="shape shape-a3">
    <img src="assets/images/shape/shape-13.svg" alt="shape" />
  </div>
  <div class="shape shape-a4">
    <img src="assets/images/shape/shape-11.svg" alt="shape" />
  </div>
</section>
