<!--Breadcrumb Area-->
<section class="breadcrumb-area banner-5">
  <div class="text-block">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 v-center">
          <div class="bread-inner">
            <div class="bread-menu wow fadeInUp" data-wow-delay=".2s">
              <ul>
                <li><a [routerLink]="['/']" i18n>Home</a></li>
                <li><a [routerLink]="['/portfolio' , '']" i18n>Portfolio</a></li>
                <li><span i18n>Project Details</span></li>
              </ul>
            </div>
            <div class="bread-title wow fadeInUp" data-wow-delay=".5s">
              <h1 i18n>WunderRent / KeazAccess Fleet Management System</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Breadcrumb Area-->
<!--Start Case Study About-->
<section class="case-study pad-tb">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="image-block upset">
          <img src="assets/images/portfolio/image-16.webp" alt="case study" class="img-fluid no-shadow" />
        </div>
      </div>
      <div class="col-lg-6 block-1 v-center">
        <div class="common-heading inner-heading text-l pl25">
          <span i18n>About The Project</span>
          <h2 i18n>IoT platform for managing internal vehicle lending</h2>
          <p i18n>KeazAccess has been designed as a modular, cloud-based system to allow for an end-to-end vehicle and asset
            booking system that can be extended to allow for additional functionality, such as telematics based door
            unlock/lock for vehicle access, RFID (staff card) access to vehicles, integration with ERP systems,
            integration with car rental systems for scalable vehicle numbers, etc.</p>
          <p i18n>KEAZAccess vehicle access & intelligence platform delivers tailored self-service access; by the hour, day
            or whatever you require. No lost keys!</p>
          <p><strong i18n> AngularJS | NodeJS | Telematics | Stripe | Redis | MongoDB | AWS | GeotabSDK </strong></p>
        </div>
        <div class="project-platform mt60 pl25">
          <div class="project-platform-used -shadow"><a href="https://keaz.co"
              target="blank"><img src="assets/images/icons/www.webp" alt="visit"></a>
          </div>
          <div><strong> ⤺ </strong><span style="font-size: smaller;" i18n>Click here to visit the site</span></div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Case Study About-->
<section class="case-study pad-tb bg-gradient6 deep-dark">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <div class="common-heading">
          <span i18n>Service Features</span>
          <h2 class="mb30" i18n>Some of the key features of the platform include:
          </h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <h4 class="mt20 mb10" i18n>Asset Scheduling</h4>
        <ul class="list-style-">
          <li i18n>System has a section to manage all of your vehicles at once. You could easily add more or remove some of
            them.</li>
          <li i18n>As a customer, they could book a vehicle in advance and just start their booking via the web app without
            needing to interact with anyone.</li>
        </ul>
      </div>
      <div class="col-lg-6">
        <h4 class="mt20 mb10" i18n>Solutions For All Clients</h4>
        <ul class="list-style-">
          <li i18n>This service is a suitable mobility solutions for all range of clients such as Not For Profit, Local
            Government, Bus Company, Insurance Companies, Car Dealerships, Taxis... </li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <h4 class="mt20 mb10" i18n>Keyless Vehicle Access</h4>
        <ul class="list-style-">
          <li i18n>As a premium function, an iOT device would be embedded into vehicles in order to offer keyless access
            feature. Drivers who register with an RFID could use that card to access their booked vehicle and start the
            booking right away.
          </li>
        </ul>
      </div>
      <div class="col-lg-6">
        <h4 class="mt20 mb10" i18n>Vehicle Rental Operations</h4>
        <ul class="list-style-">
          <li i18n>Manager could not only manage bookings but also manage users in terms of checking their profile, driver’s
            license, or any legal matter.</li>
          <li i18n>We offer iPad operator app where managers could start an approved booking or stop any in use one.</li>
        </ul>
      </div>
    </div>
  </div>
</section>
<!--Start Project Challenges-->
<section class="case-study pad-tb">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-sm-6">
        <h2 class="mt20 mb20" i18n>Challenges</h2>
        <p i18n>Developing a global SaaS fleet management system for an IoT platform that manages internal vehicle lending
          can be a complex undertaking.<br><br></p>
        <p style="text-indent: 15px;" i18n>➟ Scalability: Developing a global system means that it needs to be able to handle
          a large volume of data and transactions from different locations around the world. The system must be designed
          to scale horizontally and vertically, ensuring that it can handle an increasing number of users and
          devices.<br><br></p>
        <p style="text-indent: 15px;" i18n>➟ Integration with existing systems: The SaaS fleet management system must
          integrate seamlessly with the existing systems of the organization, including HR systems, finance systems, and
          others. Integration can be a challenge, as different systems may have different data structures and
          APIs.<br><br></p>
        <p style="text-indent: 15px;" i18n>➟ Security: The system must be secure to ensure that sensitive data is not
          compromised. The system must have a robust security architecture that can protect against unauthorized access,
          data breaches, and cyber-attacks.<br><br></p>
        <p style="text-indent: 15px;" i18n>➟ Data privacy: Developing a global system means that it needs to comply with
          different data privacy regulations in different countries. It must be designed to protect the privacy of user
          data, and meet the requirements of different data protection laws.<br><br></p>
        <p style="text-indent: 15px;" i18n>➟ Maintenance and Support: Developing a SaaS fleet management system requires
          ongoing maintenance and support to ensure it operates smoothly. This includes updating software,
          troubleshooting issues, and providing customer support to users.</p>
      </div>
      <div class="col-lg-6 col-sm-6">
        <div class="project-view m-mt30">
          <img src="assets/images/case-study/goen-view.webp" alt="case study" class="img-fluid" />
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Project Challenges-->
<!--Start Solutions-->
<section class="case-study pad-tb bg-gradient5 deep-dark">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 block-1 v-center">
        <div class="common-heading inner-heading text-l pr25">
          <span i18n>Solutions</span>
          <h2 i18n>How We Work</h2>
          <h4 i18n>Here are a few important measures that we may have implemented during the course of development.<br><br>
          </h4>
          <p i18n><strong>Define the requirements</strong>: We started by working closely with stakeholders to define the
            requirements of the system, taking into account input from IT, HR, and other relevant departments. Once we
            had a clear understanding of the system's requirements, we chose the appropriate technology stack, including
            cloud infrastructure, databases, programming languages, and third-party libraries.
          </p>
          <p i18n><strong>Develop and test the system</strong>: Next, we developed and tested the system in iterative
            sprints, using agile development methodologies. We regularly tested the system using both automated testing
            tools and manual testing techniques, ensuring that it met the requirements and was easy to use for our
            users.
          </p>
          <p i18n><strong>Ensure scalability and performance</strong>: We also made sure that the system was scalable and
            performed well by using modern cloud infrastructure and load balancing techniques. We regularly monitored
            the system for performance issues and optimized it as needed to ensure smooth operation.</p>
          <p i18n><strong>Establish ongoing maintenance and support</strong>: Finally, we established an ongoing maintenance
            and support plan for the system, including software updates, bug fixes, and user support. This included
            having a dedicated support team, regular software updates, and monitoring the system's performance to
            quickly address any issues that arose. Through our collaborative efforts, we successfully built a robust and
            user-friendly fleet management system that met the needs of our organization and users.</p>
        </div>
        <div class="card-nx row">
          <div class="col-lg-3 col-md-6 col-sm-6 col-6 mt30">
            <div class="icon-card- hfull">
              <img src="assets/images/icons/logo-and-branding.svg" alt="case study" class="img-fluid">
              <h4 i18n>UI/UX Graphic Design</h4>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-6 mt30">
            <div class="icon-card- hfull">
              <img src="assets/images/icons/branding.svg" alt="case study" class="img-fluid">
              <h4 i18n>Front-end Development</h4>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-6 mt30">
            <div class="icon-card- hfull">
              <img src="assets/images/icons/back.svg" alt="case study" class="img-fluid">
              <h4 i18n>Back-end/API Development</h4>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-6 mt30">
            <div class="icon-card- hfull">
              <img src="assets/images/icons/price.svg" alt="case study" class="img-fluid">
              <h4 i18n>Stripe Payment Gateway Integration</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Solutions-->

<div class="case-study pad-tb">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="porto-slide owl-carousel">
          <div class="screen-splits"> <img src="assets/images/case-study/keazaccess-web-1.webp" alt="case study"
              class="img-fluid" style="border-style: solid; border-width: thin;" /></div>
        </div>
      </div>
    </div>
  </div>


  <!--Start Navigate-->
  <section class="testinomial-section pad-tb">
    <div class="container">
      <div class="row">
        <div class="col-6 v-center">
          <a [routerLink]="['/', 'portfolio', previousLink]" class="nav_link" i18n>&laquo; Previous</a>
        </div>
        <div class="col-6 v-center">
          <a [routerLink]="['/', 'portfolio', nextLink]" class="nav_link next_nav_link" i18n>Next &raquo;</a>
        </div>
      </div>
    </div>
  </section>
  <!--End Navigate-->

  <!--Start Testinomial-->
  <app-testinomial-component></app-testinomial-component>
  <!--End Testinomial-->
