// language.service.ts

import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { BlogService } from './blog.service'
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor(private blogService: BlogService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  changeLanguage(language: string, event: MouseEvent, Url: string = '') {
    // if (typeof window == 'undefined') {
    //   console.log("window not found!")
    //   return;
    // }
    // let href = window.location.href;
    let href = this.document.location.href;
    if (/\/blog\/.+/.test(href)) {
      const match = href.match(/\/blog\/([^/]+)/);
      const blogSlug = match ? match[1] : null; // Returns the slug or null if not found
      if (blogSlug) {
        // get another local of blog from localstorage
        // const blogDiffLocal = JSON.parse(localStorage.getItem(blogSlug) || '');
        if (language == 'vn') {
          this.blogService.getBlogBySlug(blogSlug, 'en').subscribe(response => {
            let blog = response.data[0];
            let anotherSlug = blog?.localizations[0]?.slug;
            // change to destination language
            // window.location.href = `/${language}/blog/${anotherSlug}`;
            this.document.location.href = `/${language}/blog/${anotherSlug}`;
          }, error => { });
        }
        if (language == 'en') {
          this.blogService.getBlogBySlug(blogSlug, 'vi').subscribe(response => {
            let blog = response.data[0];
            let anotherSlug = blog?.localizations[0]?.slug;
            // change to destination language
            // window.location.href = `/${language}/blog/${anotherSlug}`;
            this.document.location.href = `/${language}/blog/${anotherSlug}`;
          }, error => { });
        }
        // window.location.href = href;
      }
    } else {
      event.preventDefault();
      if (language == 'en') {
        href = href.replace('/vn/', `/${language}/`);
      } else {
        href = href.replace('/en/', `/${language}/`);
      }
      this.document.location.href = href;
    }
  }

  // changeLanguage(language: string, event: MouseEvent, Url: string = '') {
  //   if (!window) {
  //     console.log("window not found!")
  //     return;
  //   }
  //   let href = window.location.href;
  //   if (/\/blog\/.+/.test(href)) {
  //     const match = href.match(/\/blog\/([^/]+)/);
  //     const blogSlug = match ? match[1] : null; // Returns the slug or null if not found
  //     if (blogSlug) {
  //       // get another local of blog from localstorage
  //       const blogDiffLocal = JSON.parse(localStorage.getItem(blogSlug) || '');
  //       if (blogDiffLocal) {
  //         href = href
  //         .replace('/vn/', `/${language}/`)
  //         .replace('/en/', `/${language}/`)
  //         .replace(blogSlug, blogDiffLocal.slug)

  //         window.location.href = href;
  //       }
  //     }
  //   } else {
  //     event.preventDefault();
  //     if (language == 'en') {
  //       href = href.replace('/vn/', `/${language}/`);
  //     } else {
  //       href = href.replace('/en/', `/${language}/`);
  //     }
  //     window.location.href = href;
  //   }
  // }
}
