// https://stackoverflow.com/questions/57720654/after-change-route-jquery-not-working-when-refresh-again-then-work-correctly
// core
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';

// self
import { ScriptService, LangService, previousEvent, nextEvent, MetaDataService } from '../services';


@Component({
  selector: 'app-carsnow',
  templateUrl: './carsnow.component.html',
  styleUrls: ['./project.component.css']
})
export class CarNowsComponent extends MetaDataService implements OnInit {
  // for load init script
  isLoad = false;
  public current_lang: any = {};
  previousLink = "";
  nextLink = "";
  currentLink = "carsnow-mobile-app";

  constructor(
    private scriptService: ScriptService,
    private langService: LangService,
    private location: Location,
    private router: Router,
    title: Title,
    meta: Meta,
  ) {
    super(title, meta);
    // this.title.setTitle("Portfolio CarsNow Mobile Application | Dicom Interactive");
    // this.meta.updateTag({ name: "description", content: "CarsNow is a convenient mobile application that allows approved users in participating properties to easily reserve, unlock, drive and pay for short-term carshare rentals." });
    this.updateTags(
      'Portfolio CarsNow Mobile Application',
      'CarsNow is a convenient mobile application that allows approved users in participating properties to easily reserve, unlock, drive and pay for short-term carshare rentals.',
      this.location.prepareExternalUrl(this.router.url),
      this.location.prepareExternalUrl('/assets/images/portfolio/image-12.webp')
    );

    // this.scriptService.load("assets/js/main.js");
    // this._loadScript("assets/js/bootstrap.js");
    // this._loadScript("assets/vendor/owl-carousel/js/owl.carousel.min.js");
    // this._loadScript("assets/vendor/revslider/js/jquery.themepunch.tools.min.js");
    // this._loadScript("assets/vendor/revslider/js/jquery.themepunch.revolution.min.js");
    // this._loadScript("assets/js/init.js");
    // this._loadScript("assets/js/slide.js");
    this.scriptService.load(this.scriptService.INIT).then(() => {
      this.isLoad = true;
    });
    // setTimeout(() => { this.isLoad = true }, 1000);
    this.previousLink = previousEvent(this.currentLink);
    this.nextLink = nextEvent(this.currentLink);
  }

  async ngOnInit() {
    this.current_lang = await this.langService.loadLang();
  }


}
