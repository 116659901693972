export const RICH_BANNERS = [
  {
    title: '360° Product',
    slug: '360-product-banner',
    show: false,
    class: '',
    src: 'https://dicom-interactive.com/banners/demo/360InteractiveProduct/banner.html',
    description: `<div class="instructions ck-content">
            <h2>Banner Hosting Instructions</h2>
            <p>Showcase a 3D product with 360° rotation, allowing users to view every angle interactively. This banner can be hosted on Google Ad platforms or non-Google platforms.</p>
            <h3>For non-Google platforms:</h3>
            <h4>Add the Banner</h4>
            <ul>
                <li>
                    Embed the 360° Interactive Product banner using the following &lt;iframe&gt; code:
                    <pre>&lt;iframe
 src="https://yourdomain.com/path-to-banner/360InteractiveProduct.html"
 style="border: none;"
 width="300"
 height="600"&gt;
&lt;/iframe&gt;
                    </pre>
                </li>
            </ul>

            <h4>Key Features</h4>
            <ul>
                <li>Interactive 360° drag-to-rotate functionality.</li>
                <li>Supports .glb file format exported from 3D modeling tools.</li>
                <li>Mobile and desktop responsive.</li>
            </ul>

            <h4>Notes</h4>
            <ul>
                <li>Test the 3D rendering on various devices to ensure smooth performance.</li>
                <li>Optimize .glb files for faster loading times.</li>
            </ul>

            <h4>Why Choose?</h4>
            <p>Perfect for showcasing products with intricate details, the 360° Interactive Product banner provides a unique and engaging experience for potential buyers.</p>
        </div>`,
    width: 300,
    height: 600,
  },
  {
    title: 'Scratch',
    slug: 'scratch-banner',
    show: false,
    class: '',
    src: 'https://dicom-interactive.com/banners/demo/ScratchToReveal/banner.html',
    description: `<div class="instructions ck-content">
            <h2>Banner Hosting Instructions</h2>
            <p>A fun and engaging banner where users can swipe to reveal hidden content beneath a top layer. This banner can be hosted on Google Ad platforms or non-Google platforms.</p>
            <h3>For non-Google platforms:</h3>
            <h4>Add the Banner</h4>
            <ul>
                <li>
                    Embed the Scratch to Reveal banner using the following &lt;iframe&gt; code:
                    <pre>&lt;iframe
 src="https://yourdomain.com/path-to-banner/ScratchToReveal.html"
 style="border: none;"
 width="300"
 height="600"&gt;
&lt;/iframe&gt;
                    </pre>
                </li>
            </ul>

            <h4>Key Features</h4>
            <ul>
                <li>Interactive scratch-to-reveal effect.</li>
                <li>Perfect for promotional campaigns or mystery content.</li>
                <li>Responsive design ensures smooth functionality across devices.</li>
            </ul>

            <h4>Notes</h4>
            <ul>
                <li>Ensure the top and bottom images align correctly for a clean reveal.</li>
                <li>Test swipe functionality across devices to confirm compatibility.</li>
            </ul>

            <h4>Why Choose?</h4>
            <p>
            The Scratch to Reveal banner is an exciting way to engage users, making it perfect for promotional events or interactive campaigns.
            </p>
        </div>`,
    width: 300,
    height: 600,
  },
  {
    title: 'Before/After',
    slug: 'before-after-banner',
    show: false,
    class: '',
    src: 'https://dicom-interactive.com/banners/demo/BeforeAfter/banner.html',
    description: `<div class="instructions ck-content">
            <h2>Banner Hosting Instructions</h2>
            <p>This banner showcases a dynamic before-and-after effect. Users can drag their mouse or swipe on mobile devices to compare two images seamlessly. This banner can be hosted on Google Ad platforms or non-Google platforms.</p>
            <h3>For non-Google platforms:</h3>
            <h4>Add the Banner</h4>
            <ul>
                <li>
                    Embed the Before &amp; After banner using the following &lt;iframe&gt; code:
                    <pre>&lt;iframe
 src="https://yourdomain.com/path-to-banner/BeforeAfter.html"
 style="border: none;"
 width="300"
 height="250"&gt;
&lt;/iframe&gt;
                    </pre>
                </li>
            </ul>

            <h4>Key Features</h4>
            <ul>
                <li>Drag-and-swipe functionality for a smooth before-and-after effect.</li>
                <li>Perfect for visual comparisons, such as product transformations.</li>
                <li>Fully responsive for mobile and desktop use.</li>
            </ul>

            <h4>Notes</h4>
            <ul>
                <li>Image quality is crucial to maximize the impact of the effect.</li>
                <li>Test swipe functionality on mobile to ensure seamless interaction.</li>
            </ul>

            <h4>Why Choose?</h4>
            <p> The Before &amp; After banner offers a highly engaging way to demonstrate transformations, ideal for product showcases or campaigns requiring a comparison.</p>
        </div>`,
    width: 300,
    height: 250,
  },
  {
    title: 'Embed Video',
    slug: 'embed-video-banner',
    show: false,
    class: '',
    src: 'https://dicom-interactive.com/banners/demo/InPageVideo/banner.html',
    description: `<div class="instructions ck-content">
            <h2>Banner Hosting Instructions</h2>
            <p>An integrated video banner that supports both hosted MP4 videos and YouTube URLs, offering flexibility in media presentation. This banner can be hosted on Google Ad platforms or non-Google platforms.</p>
            <h3>For non-Google platforms:</h3>
            <h4>Add the Banner</h4>
            <ul>
                <li>
                    Embed the In-Page Video banner using the following &lt;iframe&gt; code:
                    <pre>&lt;iframe
 src="https://yourdomain.com/path-to-banner/InPageVideo.html"
 style="border: none;"
 width="300"
 height="600"&gt;
&lt;/iframe&gt;
                    </pre>
                </li>
            </ul>

            <h4>Key Features</h4>
            <ul>
                <li>Supports autoplay, play/pause controls, and mobile-friendly designs.</li>
                <li>Works seamlessly with both hosted videos and YouTube URLs.</li>
                <li>Lightweight implementation with no additional scripts required.</li>
            </ul>

            <h4>Notes</h4>
            <ul>
                <li>Ensure video formats comply with hosting requirements (e.g., MP4).</li>
                <li>YouTube URLs must be valid and properly embedded.</li>
            </ul>

            <h4>Why Choose?</h4>
           <p>The In-Page Video banner provides a dynamic way to communicate through multimedia, enhancing user engagement with visual storytelling.</p>
        </div>
  `,
    width: 300,
    height: 600,
  },
  {
    title: 'Gallery',
    slug: 'gallery-banner',
    show: false,
    src: 'https://dicom-interactive.com/banners/demo/GalleryWTransition/banner.html',
    description: `<div class="instructions ck-content">
            <h2>Banner Hosting Instructions</h2>
            <p>An elegant banner designed to display a gallery of images with smooth transitions and pagination controls. This banner can be hosted on Google Ad platforms or non-Google platforms.</p>
            <h3>For non-Google platforms:</h3>
            <h4>Add the Banner</h4>
            <ul>
                <li>
                    Embed the Gallery banner using the following &lt;iframe&gt; code:
                    <pre>&lt;iframe
 src="https://yourdomain.com/path-to-banner/GalleryWTransition.html"
 style="border: none;"
 width="300"
 height="250"&gt;
&lt;/iframe&gt;
                    </pre>
                </li>
            </ul>

            <h4>Key Features</h4>
            <ul>
                <li>Automatic transitions between images.</li>
                <li>Pagination dots for manual navigation.</li>
                <li>Fully customizable transition effects.</li>
            </ul>

            <h4>Notes</h4>
            <ul>
                <li>Use high-resolution images to ensure the gallery looks professional.</li>
                <li>Test auto-play functionality to confirm a seamless experience.</li>
            </ul>

            <h4>Why Choose?</h4>
           <p>The Gallery with Transition banner is ideal for displaying multiple visuals in a clean and modern layout, creating an appealing user experience.</p>
        </div>`,
    width: 300,
    height: 250,
  },
  {
    title: 'Parallax',
    slug: 'parallax-banner',
    class: 'dic dic-parallax',
    show: false,
    src: 'https://dicom-interactive.com/banners/demo/Parallax/banner.html',
    description: `<div class="instructions ck-content">
            <h2>Parallax Banner Hosting Instructions</h2>
            <p>Our <strong>Parallax banners</strong> are designed to provide a rich, interactive scrolling experience. They dynamically react to user scrolling, offering a visually engaging element to any webpage. These banners can be hosted on non-Google platforms with ease, using a custom JavaScript file (dic.js) to handle the parallax effect and iframe integration.</p>

            <h3>Integration Steps for Non-Google Platforms</h3>
            To integrate the Parallax banner into your website, follow these steps:
            <h4>1. Include the JavaScript File</h4>
            <ul>
                <li>Add the following &lt;script&gt; tag to the &lt;head&gt; of the webpage where the banner will be embedded:
                    <pre>&lt;script src="https://dicom-interactive.com/banners/dic.js"&gt;&lt;/script&gt;</pre>
                </li>
                <li>Alternatively, you can host dic.js alongside the Parallax banner files and update the src path accordingly.</li>
            </ul>

            <h4>2. Embed the Parallax Banner</h4>
            <ul>
                <li>Add a scrollable container (&lt;div&gt;) and an iframe for the banner into your webpage:
                    <pre>    &lt;div id="scrollableElement"&gt;
        &lt;iframe
            id="myParallaxIframe"
            class="dic dic-parallax"
            scroller="#scrollableElement"
            scrolling="no"
            src="https://yourdomain.com/path-to-banner/banner.html"
            style="border: none;"
            width="300"
            height="250"&gt;
        &lt;/iframe&gt;
    &lt;/div&gt;
                    </pre>
                </li>
                <li>Key Attributes:<br>
                    <ul>
                        <li>scroller=“#scrollableElement”: Links the iframe to the specified scrollable container for proper parallax synchronization.</li>
                        <li>scrolling=“no”: Ensures the iframe doesn’t introduce its own scrollbars.</li>
                    </ul>
                </li>
            </ul>

            <h4>Key Features</h4>
            <ul>
                <li><strong>Interactive Scrolling:</strong> The Parallax banner reacts dynamically to user scrolling, creating a smooth, visually engaging experience.</li>
                <li><strong>Customizable Scroller:</strong> You can specify any scrollable element (e.g., #scrollableElement) to control the parallax effect.</li>
                <li><strong>Easy Integration:</strong> Add the dic.js file and a simple iframe structure to your site—no additional configurations required.</li>
            </ul>

            <h4>Notes</h4>
            <ol>
                <li>The width and height of the iframe should match the banner’s dimensions.</li>
                <li>Ensure the src in the &lt;iframe&gt; points to the correct banner HTML file location on your hosting platform.</li>
                <li>The scrollable container (#scrollableElement) should have the appropriate dimensions and styles to allow scrolling:
                    <pre>    #scrollableElement {
        height: 100vh; /* Full viewport height */
        overflow-y: scroll; /* Enable vertical scrolling */
    }
                    </pre></li>
            </ol>

            <h4>Why Choose Parallax Banners?</h4>
            <ul>
                <li><strong>Enhanced Engagement:</strong> Parallax banners are perfect for capturing user attention and boosting interaction.</li>
                <li><strong>Flexible Hosting:</strong> Our banners can be hosted on non-Google platforms while retaining the same functionality as Google-hosted banners.</li>
                <li><strong>Lightweight Integration:</strong> With our dic.js file, implementation is seamless and efficient.</li>
            </ul>
        </div>`,
    width: 300,
    height: 250,
  },
  {
    title: 'Expandable',
    class: 'dic dic-expandable expandable-banner',
    coverStyle: 'cover-expandable-banner',
    slug: 'expandable-banner',
    styleId: 'myExpandableIframe',
    show: true,
    src: 'https://dicom-interactive.com/banners/demo/Expandable/banner.html',
    description: `<div class="instructions ck-content">
            <h2>Expandable Banner Hosting Options</h2>
            <p>Our expandable banners are highly versatile and can be hosted on both <strong>Google Ad platforms</strong> (e.g., Google Ads, DV360) and <strong>non-Google hosting platforms</strong>. Below, we detail the requirements for hosting banners on non-Google platforms, including how to set up our expandable banners with a custom JavaScript handler.</p>

            <h3>Hosting on Non-Google Platforms</h3>
            <p>
            To host our expandable banners on non-Google platforms, we provide a lightweight JavaScript file (dic.js) that ensures seamless expand/collapse functionality by dynamically resizing the iframe. This allows the expandable banner to function exactly as intended, while the embedded content remains user-friendly and responsive.
            </p>
            <h3>Integration Steps for Non-Google Platforms</h3>
            <h4>1. Include the JavaScript File</h4>
            <ul>
                <li>Add the following &lt;script&gt; tag to the &lt;head&gt; of the website where the banner will be embedded:
                    <pre>&lt;script src="https://dicom-interactive.com/banners/dic.js"&gt;&lt;/script&gt;</pre></li>
                <li>Alternatively, you can host dic.js alongside the expandable banner files and update the src path accordingly.</li>
            </ul>
            <h4>2. Embed the Expandable Banner</h4>
            <p>Add the iframe block to your webpage where you want the banner to appear. The example below demonstrates embedding an expandable banner:</p>
            <pre>&lt;iframe
    id="myExpandableIframe"
    class="dic dic-expandable"
    src="https://yourdomain.com/path-to-banner/banner.html"
    style="border: none;"
    width="320"
    height="50"&gt;
&lt;/iframe&gt;
            </pre>
            <h4>Key Features</h4>
            <ul>
                <li><strong>Dynamic Resizing:</strong> dic.js handles the iframe resizing automatically. When users interact with the banner, the iframe resizes dynamically to display the expanded state, then collapses when required.</li>
                <li><strong>Flexibility</strong>: Our banners can integrate into any hosting platform while maintaining the same expand/collapse functionality as Google-hosted banners.</li>
                <li><strong>Easy Setup</strong>: Simply include the JavaScript file and iframe code in your website—no complex configurations needed.</li>
            </ul>
            <h4>Notes</h4>
            <ul>
                <li>Ensure the iframe src points to the correct banner HTML file location on your hosting platform.</li>
                <li>The initial width and height values in the &lt;iframe&gt; tag should match the dimensions of the collapsed state of your banner (e.g., 320x50).</li>
                <li>The dic.js file should either be hosted at the default URL <a href="https://dicom-interactive.com/banners/dic.js" target="_blank">https://dicom-interactive.com/banners/dic.js</a> or alongside your banner files.</li>
            </ul>
        </div>`,
    width: 320,
    height: 50,
  },
];

export const RICH_BANNERS_VN = [
  {
    title: '360° Product°',
    slug: '360-product-banner',
    show: false,
    class: '',
    src: 'https://dicom-interactive.com/banners/demo/360InteractiveProduct/banner.html',
    description: `<div class="instructions ck-content">
            <h2>Hướng Dẫn Triển Khai Banner</h2>
            <p>Trình diễn sản phẩm 3D với tính năng xoay 360°, cho phép người dùng xem từng góc độ một cách tương tác. Banner này có thể được lưu trữ trên nền tảng quảng cáo của Google hoặc các nền tảng khác.</p>
            <h3>Triển khai trên nền tảng không phải Google:</h3>
            <h4>Tích hợp Banner</h4>
            <ul>
                <li>
                    Nhúng banner sản phẩm 360° tương tác bằng đoạn mã &lt;iframe&gt; sau:
                    <pre>&lt;iframe
 src="https://yourdomain.com/path-to-banner/360InteractiveProduct.html"
 style="border: none;"
 width="300"
 height="600"&gt;
&lt;/iframe&gt;
                    </pre>
                </li>
            </ul>

            <h4>Tính Năng Nổi Bật</h4>
            <ul>
                <li><strong>Tương tác xoay 360°</strong> - Người dùng có thể kéo để xoay sản phẩm từ mọi góc độ.</li>
                <li><strong>Hỗ trợ định dạng .glb</strong> - Được xuất từ các công cụ mô hình 3D chuyên nghiệp.</li>
                <li><strong>Tương thích trên cả mobile và desktop</strong> - Hiển thị mượt mà trên mọi thiết bị.</li>
            </ul>

            <h4>Lưu Ý</h4>
            <ul>
                <li>Kiểm tra khả năng hiển thị 3D trên nhiều thiết bị để đảm bảo hiệu suất tối ưu.</li>
                <li>Tối ưu hóa tệp .glb để giảm thời gian tải trang.</li>
            </ul>

            <h4>Vì Sao Chọn Banner 360°?</h4>
            <p>Dành cho những sản phẩm có nhiều chi tiết phức tạp, banner tương tác 360° mang đến trải nghiệm trực quan và hấp dẫn, giúp thu hút sự chú ý và tăng tỷ lệ chuyển đổi từ khách hàng tiềm năng</p>
        </div>`,
    width: 300,
    height: 600,
  },
  {
    title: 'Scratch',
    slug: 'scratch-banner',
    show: false,
    class: '',
    src: 'https://dicom-interactive.com/banners/demo/ScratchToReveal/banner.html',
    description: `<div class="instructions ck-content">
            <h2>Hướng Dẫn Triển Khai Banner</h2>
            <p>Một banner thú vị và tương tác, cho phép người dùng vuốt để lộ nội dung ẩn bên dưới lớp che phủ. Banner này có thể được lưu trữ trên nền tảng quảng cáo của Google hoặc các nền tảng khác.</p>
            <h3>Triển khai trên nền tảng không phải Google:</h3>
            <h4>Tích hợp Banner</h4>
            <ul>
                <li>
                   Nhúng banner Scratch to Reveal bằng đoạn mã &lt;iframe&gt; sau:
                    <pre>&lt;iframe
 src="https://yourdomain.com/path-to-banner/ScratchToReveal.html"
 style="border: none;"
 width="300"
 height="600"&gt;
&lt;/iframe&gt;
                    </pre>
                </li>
            </ul>

            <h4>Tính Năng Nổi Bật</h4>
            <ul>
                <li><strong>Hiệu ứng vuốt để lộ nội dung</strong> - Tạo sự tò mò và kích thích tương tác từ người dùng.</li>
                <li><strong>Phù hợp cho chiến dịch khuyến mãi hoặc nội dung bí ẩn</strong> - Hoàn hảo cho các chương trình giảm giá hoặc quà tặng bất ngờ.</li>
                <li><strong>Thiết kế responsive</strong> - Hoạt động mượt mà trên cả mobile và desktop.</li>
            </ul>

            <h4>Lưu Ý</h4>
            <ul>
                <li>Đảm bảo hình ảnh lớp trên và lớp dưới được căn chỉnh chính xác để hiển thị mượt mà.</li>
                <li>Kiểm tra chức năng vuốt trên nhiều thiết bị để đảm bảo khả năng tương thích.</li>
            </ul>

            <h4>Vì Sao Chọn Banner Scratch to Reveal?</h4>
            <p>
            Banner Scratch to Reveal mang lại trải nghiệm tương tác độc đáo, giúp tăng sự hứng thú và thu hút người dùng, lý tưởng cho các sự kiện khuyến mãi hoặc chiến dịch marketing sáng tạo.
            </p>
        </div>`,
    width: 300,
    height: 600,
  },
  {
    title: 'Before/After',
    slug: 'before-after-banner',
    show: false,
    class: '',
    src: 'https://dicom-interactive.com/banners/demo/BeforeAfter/banner.html',
    description: `<div class="instructions ck-content">
            <h2>Hướng Dẫn Triển Khai Banner</h2>
            <p>Banner này hiển thị hiệu ứng so sánh Before & After (Trước & Sau) động. Người dùng có thể kéo chuột hoặc vuốt trên thiết bị di động để so sánh hai hình ảnh một cách mượt mà. Banner này có thể được lưu trữ trên nền tảng quảng cáo của Google hoặc các nền tảng khác.</p>
            <h3>Triển khai trên nền tảng không phải Google:</h3>
            <h4>Tích hợp Banner</h4>
            <ul>
                <li>
                    Nhúng banner Before & After bằng đoạn mã &lt;iframe&gt; sau:
                    <pre>&lt;iframe
 src="https://yourdomain.com/path-to-banner/BeforeAfter.html"
 style="border: none;"
 width="300"
 height="250"&gt;
&lt;/iframe&gt;
                    </pre>
                </li>
            </ul>

            <h4>Tính Năng Nổi Bật</h4>
            <ul>
                <li><strong>Hiệu ứng kéo và vuốt để so sánh hai hình ảnh</strong> - Cung cấp trải nghiệm trực quan và hấp dẫn.</li>
                <li><strong>Lý tưởng cho việc so sánh hình ảnh</strong> - Hoàn hảo để hiển thị quá trình thay đổi sản phẩm, cải tiến dịch vụ, hoặc trước và sau khi sử dụng sản phẩm.</li>
                <li><strong>Thiết kế responsive</strong> - Hoạt động tốt trên cả thiết bị di động và máy tính.</li>
            </ul>

            <h4>Lưu Ý</h4>
            <ul>
                <li>Đảm bảo chất lượng hình ảnh tốt để hiệu ứng so sánh đạt hiệu quả cao nhất.</li>
                <li>Kiểm tra chức năng vuốt trên thiết bị di động để đảm bảo trải nghiệm mượt mà.</li>
            </ul>

            <h4>Vì Sao Chọn Banner Before & After?</h4>
            <p>Banner Before & After mang lại cách tiếp cận trực quan và tương tác cao, giúp minh họa sự thay đổi một cách rõ ràng, phù hợp cho các chiến dịch giới thiệu sản phẩm hoặc dịch vụ yêu cầu so sánh hình ảnh.</p>
        </div>`,
    width: 300,
    height: 250,
  },
  {
    title: 'Embed Video',
    slug: 'embed-video-banner',
    show: false,
    class: '',
    src: 'https://dicom-interactive.com/banners/demo/InPageVideo/banner.html',
    description: `<div class="instructions ck-content">
            <h2>Hướng Dẫn Triển Khai Banner</h2>
            <p>Banner video tích hợp hỗ trợ cả video MP4 được lưu trữ trên máy chủ và URL từ YouTube, mang lại sự linh hoạt trong cách trình bày nội dung. Banner này có thể được triển khai trên nền tảng quảng cáo của Google hoặc các nền tảng khác.</p>
            <h3>Triển khai trên nền tảng không phải Google:</h3>
            <h4>Tích hợp Banner</h4>
            <ul>
                <li>
                    Nhúng banner In-Page Video bằng đoạn mã &lt;iframe&gt; sau:
                    <pre>&lt;iframe
 src="https://yourdomain.com/path-to-banner/InPageVideo.html"
 style="border: none;"
 width="300"
 height="600"&gt;
&lt;/iframe&gt;
                    </pre>
                </li>
            </ul>

            <h4>Tính Năng Nổi Bật</h4>
            <ul>
                <li>Hỗ trợ tự động phát (autoplay), điều khiển phát/tạm dừng (play/pause) và thiết kế thân thiện với thiết bị di động.</li>
                <li>Tương thích với cả video được lưu trữ trên máy chủ và URL từ YouTube.</li>
                <li>Triển khai gọn nhẹ, không yêu cầu thêm bất kỳ tập lệnh (script) bổ sung nào.</li>
            </ul>

            <h4>Lưu Ý</h4>
            <ul>
                <li>Đảm bảo định dạng video tuân thủ yêu cầu của nền tảng lưu trữ (ví dụ: MP4).</li>
                <li>URL YouTube phải hợp lệ và được nhúng đúng cách.</li>
            </ul>

            <h4>Vì Sao Chọn Banner In-Page Video?</h4>
           <p>Banner In-Page Video mang đến cách truyền tải nội dung sinh động qua phương tiện đa phương tiện, giúp tăng mức độ tương tác và thu hút người dùng thông qua hình thức kể chuyện bằng hình ảnh.</p>
        </div>
  `,
    width: 300,
    height: 600,
  },
  {
    title: 'Gallery',
    slug: 'gallery-banner',
    show: false,
    src: 'https://dicom-interactive.com/banners/demo/GalleryWTransition/banner.html',
    description: `<div class="instructions ck-content">
            <h2>Hướng Dẫn Triển Khai Banner</h2>
            <p>Banner hiển thị bộ sưu tập hình ảnh với hiệu ứng chuyển đổi mượt mà và điều khiển phân trang. Banner này có thể được triển khai trên nền tảng quảng cáo của Google hoặc các nền tảng khác.</p>
            <h3>Triển khai trên nền tảng không phải Google:</h3>
            <h4>Tích hợp Banner</h4>
            <ul>
                <li>
                    Nhúng banner Gallery với Hiệu Ứng Chuyển Đổi bằng đoạn mã &lt;iframe&gt; sau:
                    <pre>&lt;iframe
 src="https://yourdomain.com/path-to-banner/GalleryWTransition.html"
 style="border: none;"
 width="300"
 height="250"&gt;
&lt;/iframe&gt;
                    </pre>
                </li>
            </ul>

            <h4>Tính Năng Nổi Bật</h4>
            <ul>
                <li>Chuyển đổi hình ảnh tự động giữa các khung ảnh.</li>
                <li>Dấu phân trang giúp người dùng dễ dàng điều hướng thủ công.</li>
                <li>Hiệu ứng chuyển đổi tùy chỉnh, linh hoạt theo thiết kế mong muốn.</li>
            </ul>

            <h4>Lưu Ý</h4>
            <ul>
                <li>Sử dụng hình ảnh chất lượng cao để đảm bảo hiển thị chuyên nghiệp.</li>
                <li>Kiểm tra chế độ tự động phát (auto-play) để đảm bảo trải nghiệm mượt mà.</li>
            </ul>

            <h4>Vì Sao Chọn Banner Gallery với Hiệu Ứng Chuyển Đổi?</h4>
           <p>Banner Gallery với Hiệu Ứng Chuyển Đổi lý tưởng để hiển thị nhiều hình ảnh trong bố cục hiện đại và tinh tế, tạo trải nghiệm thu hút và chuyên nghiệp cho người dùng.</p>
        </div>`,
    width: 300,
    height: 250,
  },
  {
    title: 'Parallax',
    slug: 'parallax-banner',
    class: 'dic dic-parallax',
    show: false,
    src: 'https://dicom-interactive.com/banners/demo/Parallax/banner.html',
    description: `<div class="instructions ck-content">
            <h2>Hướng Dẫn Triển Khai Banner Parallax</h2>
            <p><strong>Banner Parallax</strong> mang lại trải nghiệm cuộn trang mượt mà, tạo hiệu ứng hình ảnh sinh động khi người dùng cuộn trang. Đây là một giải pháp tuyệt vời để nâng cao tính tương tác và thu hút sự chú ý. Banner này có thể được triển khai trên các nền tảng không phải Google bằng cách sử dụng tệp JavaScript tùy chỉnh (dic.js) để xử lý hiệu ứng parallax và nhúng iframe.</p>

            <h3>Các Bước Triển Khai trên Nền Tảng Không Phải Google</h3>
            Để tích hợp banner Parallax vào trang web của bạn, hãy làm theo các bước sau:
            <h4>1. Thêm Tệp JavaScript</h4>
            <ul>
                <li>Chèn đoạn &lt;script&gt; sau vào &lt;head&gt; của trang web nơi banner sẽ được nhúng:
                    <pre>&lt;script src="https://dicom-interactive.com/banners/dic.js"&gt;&lt;/script&gt;</pre>
                </li>
                <li>Hoặc bạn có thể lưu trữ dic.js cùng thư mục với các tệp banner Parallax và cập nhật đường dẫn src tương ứng.</li>
            </ul>

            <h4>2. Nhúng Banner Parallax</h4>
            <ul>
                <li>Thêm container cuộn (&lt;div&gt;) và iframe vào trang web của bạn:
                    <pre>    &lt;div id="scrollableElement"&gt;
        &lt;iframe
            id="myParallaxIframe"
            class="dic dic-parallax"
            scroller="#scrollableElement"
            scrolling="no"
            src="https://yourdomain.com/path-to-banner/banner.html"
            style="border: none;"
            width="300"
            height="250"&gt;
        &lt;/iframe&gt;
    &lt;/div&gt;
                    </pre>
                </li>
                <li>Giải Thích Các Thuộc Tính Quan Trọng:<br>
                    <ul>
                        <li>scroller=“#scrollableElement”: Liên kết iframe với phần tử cuộn được chỉ định để đảm bảo hiệu ứng parallax đồng bộ.</li>
                        <li>scrolling=“no”: Ngăn chặn iframe hiển thị thanh cuộn riêng.</li>
                    </ul>
                </li>
            </ul>

            <h4>Tính Năng Nổi Bật</h4>
            <ul>
                <li><strong>Cuộn Tương Tác:</strong> Banner Parallax phản ứng linh hoạt theo thao tác cuộn trang của người dùng, tạo hiệu ứng cuốn hút.</li>
                <li><strong>Tùy Chỉnh Scroll Container:</strong> Bạn có thể chỉ định bất kỳ phần tử cuộn nào để kiểm soát hiệu ứng parallax.</li>
                <li><strong>Tích Hợp Dễ Dàng:</strong> Chỉ cần thêm tệp dic.js và một cấu trúc iframe đơn giản, không cần cấu hình phức tạp.</li>
            </ul>

            <h4>Lưu Ý</h4>
            <ol>
                <li>Chiều rộng và chiều cao của iframe phải khớp với kích thước banner.</li>
                <li>Đường dẫn src trong &lt;iframe&gt; phải trỏ đến đúng tệp HTML của banner trên nền tảng lưu trữ của bạn.</li>
                <li>Container cuộn (#scrollableElement) cần có kích thước và kiểu phù hợp để cho phép cuộn trang:
                    <pre>    #scrollableElement {
        height: 100vh; /* Chiều cao toàn màn hình */
        overflow-y: scroll; /* Cho phép cuộn dọc */
    }
                    </pre></li>
            </ol>

            <h4>Vì Sao Nên Chọn Banner Parallax?</h4>
            <ul>
                <li><strong>Tăng Tương Tác:</strong> Banner Parallax giúp thu hút sự chú ý của người dùng và nâng cao trải nghiệm duyệt web.</li>
                <li><strong>Linh Hoạt Trong Triển Khai:</strong> Có thể triển khai trên các nền tảng không phải Google nhưng vẫn giữ nguyên tính năng như các banner Google-hosted</li>
                <li><strong>Tích Hợp Nhẹ Nhàng:</strong> Sử dụng tệp dic.js giúp triển khai nhanh chóng và hiệu quả.</li>
            </ul>
        </div>`,
    width: 300,
    height: 250,
  },
  {
    title: 'Expandable',
    class: 'dic dic-expandable expandable-banner',
    coverStyle: 'cover-expandable-banner',
    slug: 'expandable-banner',
    styleId: 'myExpandableIframe',
    show: true,
    src: 'https://dicom-interactive.com/banners/demo/Expandable/banner.html',
    description: `<div class="instructions ck-content">
            <h2>Hướng Dẫn Tích Hợp Banner Mở Rộng</h2>
            <p>Các banner mở rộng của chúng tôi có tính linh hoạt cao và có thể được lưu trữ trên cả <strong>nền tảng quảng cáo của Google</strong> (Google Ads, DV360) cũng như <strong>các nền tảng lưu trữ khác</strong>. Dưới đây là hướng dẫn chi tiết để tích hợp banner mở rộng trên các nền tảng không thuộc Google, bao gồm cách thiết lập với tệp JavaScript tùy chỉnh.</p>

            <h3>Lưu Trữ Trên Các Nền Tảng Không Thuộc Google</h3>
            <p>
            Để lưu trữ banner mở rộng trên nền tảng không thuộc Google, chúng tôi cung cấp một tệp JavaScript nhẹ (dic.js) giúp xử lý mở rộng/thu gọn một cách linh hoạt. Tệp này cho phép banner mở rộng hoạt động đúng theo thiết kế mà vẫn đảm bảo trải nghiệm mượt mà và tương thích trên nhiều thiết bị.
            </p>
            <h3>Các Bước Tích Hợp Trên Nền Tảng Không Thuộc Google</h3>
            <h4>1. Thêm Tệp JavaScript</h4>
            <ul>
                <li>Thêm đoạn mã sau vào thẻ &lt;head&gt; trên trang web nơi bạn muốn hiển thị banner:
                    <pre>&lt;script src="https://dicom-interactive.com/banners/dic.js"&gt;&lt;/script&gt;</pre></li>
                <li>Hoặc bạn có thể lưu trữ dic.js cùng thư mục với các tệp banner mở rộng và cập nhật đường dẫn src cho phù hợp.</li>
            </ul>
            <h4>2. Nhúng Banner Mở Rộng</h4>
            <p>Chèn đoạn mã iframe dưới đây vào trang web để hiển thị banner mở rộng:</p>
            <pre>&lt;iframe
    id="myExpandableIframe"
    class="dic dic-expandable"
    src="https://yourdomain.com/path-to-banner/banner.html"
    style="border: none;"
    width="320"
    height="50"&gt;
&lt;/iframe&gt;
            </pre>
            <h4>Tính Năng Nổi Bật</h4>
            <ul>
                <li><strong>Tự động thay đổi kích thước:</strong> dic.js tự động điều chỉnh kích thước iframe khi người dùng tương tác với banner, đảm bảo banner mở rộng hiển thị đúng cách và thu gọn khi cần.</li>
                <li><strong>Linh Hoạt</strong>: Banner có thể được tích hợp vào bất kỳ nền tảng nào mà vẫn duy trì chức năng mở rộng/thu gọn như trên các nền tảng của Google.</li>
                <li><strong>Dễ dàng triển khai:</strong>: Chỉ cần thêm tệp JavaScript và nhúng mã iframe mà không cần cấu hình phức tạp.</li>
            </ul>
            <h4>Lưu Ý</h4>
            <ul>
                <li>Đảm bảo đường dẫn src của iframe trỏ đúng đến tệp HTML của banner trên nền tảng lưu trữ của bạn.</li>
                <li>Kích thước ban đầu của banner trong thẻ &lt;iframe&gt; phải khớp với kích thước của trạng thái thu gọn (ví dụ: 320x50).</li>
                <li>Tệp dic.js có thể được lưu trữ tại đường dẫn mặc định <a href="https://dicom-interactive.com/banners/dic.js" target="_blank">https://dicom-interactive.com/banners/dic.js</a> hoặc cùng thư mục với các tệp banner mở rộng.</li>
            </ul>
        </div>`,
    width: 320,
    height: 50,
  },
];

export const SERVICES_BANNER = [
  {
    title: 'Standard Banner',
    description:
      'Our standard banners are crafted to grab attention and drive engagement while meeting industry standards for optimized loading and seamless performance across platforms.',
    img: 'assets/images/icons/Standard.webp',
  },
  {
    title: 'Rich Media Banner',
    description:
      'With advanced features like video integration, animations, and interactive elements, our rich media banners elevate user engagement and make your campaigns more impactful.',
    img: 'assets/images/icons/Rich.webp',
  },
  {
    title: 'Design Layout Creation',
    description:
      'From concept to execution, we create bespoke banner designs tailored to your marketing goals, helping your business stand out in a competitive landscape.',
    img: 'assets/images/icons/Design.webp',
  },
  {
    title: 'Design Layout Adaption',
    description:
      'We expertly adapt your existing designs to fit specific banner dimensions and formats while preserving brand identity and ensuring visual consistency.',
    img: 'assets/images/icons/Adapt.webp',
  },
];

export const SERVICES_BANNER_VN = [
  {
    title: 'Banner Tiêu Chuẩn',
    description:
      'Banner tiêu chuẩn của chúng tôi được thiết kế để thu hút sự chú ý và tăng cường tương tác, đồng thời tuân thủ các tiêu chuẩn ngành nhằm tối ưu hóa tốc độ tải và đảm bảo hiệu suất mượt mà trên mọi nền tảng.',
    img: 'assets/images/icons/Standard.webp',
  },
  {
    title: 'Rich Media Banner',
    description:
      'Với các tính năng tiên tiến như tích hợp video, hiệu ứng động và yếu tố tương tác, Rich Media Banners của chúng tôi giúp nâng cao trải nghiệm người dùng và tạo ra những chiến dịch quảng cáo đầy ấn tượng.',
    img: 'assets/images/icons/Rich.webp',
  },
  {
    title: 'Thiết Kế Bố Cục Banner',
    description:
      'Từ ý tưởng đến thực thi, chúng tôi tạo ra các thiết kế banner tùy chỉnh phù hợp với mục tiêu tiếp thị của bạn, giúp doanh nghiệp nổi bật trong môi trường cạnh tranh.',
    img: 'assets/images/icons/Design.webp',
  },
  {
    title: 'Điều Chỉnh Bố Cục Thiết Kế',
    description:
      'Chúng tôi tinh chỉnh và tối ưu các thiết kế hiện có để phù hợp với kích thước và định dạng banner cụ thể, đảm bảo nhận diện thương hiệu và tính thẩm mỹ nhất quán.',
    img: 'assets/images/icons/Adapt.webp',
  },
];

export const TECH_STACK_BANNER = [
  { src: 'assets/images/icons/Photoshop.webp', title: 'Photoshop' },
  { src: 'assets/images/icons/Illustrator.webp', title: 'Illustrator' },
  { src: 'assets/images/icons/GWD.webp', title: 'GWD' },
  { src: 'assets/images/icons/Blender.webp', title: 'Blender' },
  { src: 'assets/images/icons/Animate.webp', title: 'Animate' },
];

const standardDescription = `
  <div class="instructions ck-content">
            <h2>Banner Hosting Instructions</h2>
            <p>This banner is a visually captivating HTML canvas banner designed with animation to convey your brand message effectively. Created using tools like Google Web Designer or Adobe Animate, it can be seamlessly hosted on Google Ad platforms or other non-Google platforms.</p>
            <h3>For non-Google platforms:</h3>
            <h4>Add the Banner</h4>
            <ul>
                <li>
                    Embed the standard banner using the following &lt;iframe&gt; code:
                    <pre>&lt;iframe
 src="https://yourdomain.com/path-to-banner/StandardBanner.html"
 style="border: none;"
 width="300"
 height="250"&gt;
&lt;/iframe&gt;
                    </pre>
                </li>
            </ul>

            <h4>Key Features</h4>
            <ul>
                <li>Eye-catching animations designed to maximize user engagement.</li>
                <li>Lightweight and optimized for fast loading across platforms.</li>
                <li>Fully responsive and adaptable for both desktop and mobile environments.</li>
            </ul>

            <h4>Notes</h4>
            <ul>
                <li>Ensure the hosting platform supports HTML5 banners for optimal performance.</li>
                <li>Use high-quality assets and images to maintain visual clarity on various devices.</li>
            </ul>

            <h4>Why Choose?</h4>
            <p>The standard banner is an essential tool for digital campaigns, offering compatibility with all major ad platforms and providing a visually appealing and professional representation of your brand. It is ideal for businesses aiming to deliver impactful messaging through high-quality animations.</p>
        </div>
`

const standardDescriptionVN = `
  <div class="instructions ck-content">
            <h2>Hướng dẫn Lưu trữ Banner</h2>
            <p>Banner này là một banner canvas HTML được thiết kế bắt mắt với hiệu ứng động nhằm truyền tải thông điệp thương hiệu của quý vị một cách hiệu quả. Được tạo bằng các công cụ như Google Web Designer hoặc Adobe Animate, banner có thể được lưu trữ liền mạch trên các nền tảng quảng cáo của Google hoặc các nền tảng khác.</p>
            <h3>Đối với các nền tảng không phải của Google:</h3>
            <h4>Thêm Banner</h4>
            <ul>
                <li>
                    Nhúng banner tiêu chuẩn bằng cách sử dụng đoạn mã &lt;iframe&gt; sau:
                    <pre>&lt;iframe
 src="https://yourdomain.com/path-to-banner/StandardBanner.html"
 style="border: none;"
 width="300"
 height="250"&gt;
&lt;/iframe&gt;
                    </pre>
                </li>
            </ul>

            <h4>Các Tính năng Chính</h4>
            <ul>
                <li>Hiệu ứng động thu hút, được thiết kế để tối đa hóa tương tác người dùng.</li>
                <li>Nhẹ và được tối ưu hóa để tải nhanh trên các nền tảng.</li>
                <li>Hoàn toàn đáp ứng và thích ứng cho cả môi trường máy tính để bàn và thiết bị di động.</li>
            </ul>

            <h4>Lưu ý</h4>
            <ul>
                <li>Đảm bảo nền tảng lưu trữ hỗ trợ banner HTML5 để có hiệu suất tối ưu.</li>
                <li>Sử dụng các tài sản và hình ảnh chất lượng cao để duy trì độ rõ nét hình ảnh trên các thiết bị khác nhau.</li>
            </ul>

            <h4>Tại sao nên chọn?</h4>
            <p>Banner tiêu chuẩn là một công cụ thiết yếu cho các chiến dịch kỹ thuật số, cung cấp khả năng tương thích với tất cả các nền tảng quảng cáo chính và mang đến một hình ảnh trực quan hấp dẫn và chuyên nghiệp cho thương hiệu của quý vị. Đây là lựa chọn lý tưởng cho các doanh nghiệp mong muốn truyền tải thông điệp mạnh mẽ thông qua các hiệu ứng động chất lượng cao.</p>
        </div>
`

export const STANDARD_BANNERS = [
  {
    src: 'assets/images/media-banners/liquors.webp',
    title: 'Liquors Banner',
    slug: 'liquors-banner',
    srcBanner: 'https://dicom-interactive.com/banners/demo/Publish-Liquor-Loung/index.html',
    description: standardDescription,
    width: 300,
    height: 600,
  },
  {
    src: 'assets/images/media-banners/parched_panther.webp',
    title: 'Parched Panther',
    slug: 'parched-panther-banner',
    srcBanner: 'https://dicom-interactive.com/banners/demo/Publish-Parched-Panther/index.html',
    description: standardDescription,
    width: 300,
    height: 600,
  },
  {
    src: 'assets/images/media-banners/toast_co.webp',
    title: 'Toast & Co',
    slug: 'toast-co-banner',
    srcBanner: 'https://dicom-interactive.com/banners/demo/Publish-Toast&Co/banner.html',
    description: standardDescription,
    width: 300,
    height: 600,
  },
  {
    src: 'assets/images/media-banners/vine_brations.webp',
    title: 'Vine Brations',
    slug: 'vine-brations-banner',
    srcBanner: 'https://dicom-interactive.com/banners/demo/Publish-Vinebrations/index.html',
    description: standardDescription,
    width: 300,
    height: 600,
  },
];

export const STANDARD_BANNERS_VN = [
  {
    src: 'assets/images/media-banners/liquors.webp',
    title: 'Liquors Banner',
    slug: 'liquors-banner',
    srcBanner: 'https://dicom-interactive.com/banners/demo/Publish-Liquor-Loung/index.html',
    description: standardDescriptionVN,
    width: 300,
    height: 600,
  },
  {
    src: 'assets/images/media-banners/parched_panther.webp',
    title: 'Parched Panther',
    slug: 'parched-panther-banner',
    srcBanner: 'https://dicom-interactive.com/banners/demo/Publish-Parched-Panther/index.html',
    description: standardDescriptionVN,
    width: 300,
    height: 600,
  },
  {
    src: 'assets/images/media-banners/toast_co.webp',
    title: 'Toast & Co',
    slug: 'toast-co-banner',
    srcBanner: 'https://dicom-interactive.com/banners/demo/Publish-Toast&Co/banner.html',
    description: standardDescriptionVN,
    width: 300,
    height: 600,
  },
  {
    src: 'assets/images/media-banners/vine_brations.webp',
    title: 'Vine Brations',
    slug: 'vine-brations-banner',
    srcBanner: 'https://dicom-interactive.com/banners/demo/Publish-Vinebrations/index.html',
    description: standardDescriptionVN,
    width: 300,
    height: 600,
  },
];
