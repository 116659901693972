<!-- // Fresy Homepage Design -->
<!--Breadcrumb Area-->
<section class="breadcrumb-area banner-5">
	<div class="text-block">
		<div class="container">
			<div class="row">
				<div class="col-lg-12 v-center">
					<div class="bread-inner">
						<div class="bread-menu wow fadeInUp" data-wow-delay=".2s">
              <ul>
                <li><a [routerLink]="['/']" i18n>Home</a></li>
                <li><a [routerLink]="['/portfolio' , '']" i18n>Portfolio</a></li>
                <li><span i18n>Project Details</span></li>
              </ul>
						</div>
						<div class="bread-title wow fadeInUp" data-wow-delay=".5s">
							<h1 i18n>Fresy Homepage Design</h1>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!--End Breadcrumb Area-->

<!--Start Case Study About-->
<section class="case-study pad-tb">
	<div class="container">
		<div class="row">
			<div class="col-lg-6">
				<div class="image-block upset">
					<img src="assets/images/case-study/fresy-intro.webp" alt="case study" class="img-fluid no-shadow" />
				</div>
			</div>
			<div class="col-lg-6 block-1 v-center">
				<div class="common-heading inner-heading text-l pl25">
					<span i18n>About The Project</span>
					<h2 i18n>Fresy Homepage Web Design</h2>
					<p i18n>No one wants to be left behind, our client - Fresy - use to have good old days with the
						traditional way of doing business. Then, they have come to realize it's time for <i>digital
							transformation</i> and so they decide to start doing their business online.</p>
					<p i18n>They want to have modern & fresh design but still looks elegant. The keywords they
						give us are <i>Contemporary, Modern, Fresh and Elegance</i>.</p>
					<p><strong> Illustrator | Figma | Photoshop | MockFlow </strong></p>
				</div>
			</div>
		</div>
	</div>
</section>
<!--End Case Study About-->
<!--Start Solutions-->
<section class="case-study pad-tb bg-gradient5 deep-dark">
	<div class="container">
		<div class="row">
			<div class="col-lg-12 block-1 v-center">
				<div class="common-heading inner-heading text-l pr25">
					<span i18n>Solutions</span>
					<h2 i18n>How We Work</h2>
					<p i18n>We talk to our client to understand their expectation on building their first-ever website.
						We consult with them
						about what their
						branding style should be and at the same time, we
						analyze their business and sketch out their ideas and we work together
						until that sketch becomes our final interactive prototype.
					</p>
					<p i18n>Instantaneously, our designers are involved in and
						get together on the vision then accomplish the design with the given keywords. Our client
						is pretty satisfied with the final outcome.<br><br>
					</p>
				</div>
				<div class="card-nx row" style="justify-content: center; display:flex;">
					<div class="col-lg-3 col-md-6 col-sm-6 col-6 mt30">
						<div class="icon-card- hfull">
							<img src="assets/images/icons/branding.svg" alt="case study" class="img-fluid">
							<h4>UX Prototyping Design</h4>
						</div>
					</div>
					<div class="col-lg-3 col-md-6 col-sm-6 col-6 mt30">
						<div class="icon-card- hfull">
							<img src="assets/images/icons/logo-and-branding.svg" alt="case study" class="img-fluid">
							<h4>UI/UX Graphic Design</h4>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!--End Solutions-->
<div class="case-study pad-tb">
	<div class="container">
		<div class="row">
			<div class="col-lg-12">
				<div class="porto-slide owl-carousel">
					<div class="screen-splits"> <img src="assets/images/case-study/fresy-web-1.webp" alt="case study"
							class="img-fluid" /></div>
					<div class="screen-splits"> <img src="assets/images/case-study/fresy-web-2.webp" alt="case study"
							class="img-fluid" /></div>
					<div class="screen-splits"> <img src="assets/images/case-study/fresy-web-3.webp" alt="case study"
							class="img-fluid" /></div>
					<div class="screen-splits"> <img src="assets/images/case-study/fresy-web-4.webp" alt="case study"
							class="img-fluid" /></div>
				</div>
			</div>
		</div>
	</div>
</div>

<!--Start Navigate-->
<section class="testinomial-section pad-tb">
	<div class="container">
		<div class="row">
			<div class="col-6 v-center">
				<a [routerLink]="['/', 'portfolio', previousLink]" class="nav_link" i18n>&laquo; Previous</a>
			</div>
			<div class="col-6 v-center">
				<a [routerLink]="['/', 'portfolio', nextLink]" class="nav_link next_nav_link" i18n>Next &raquo;</a>
			</div>
		</div>
	</div>
</section>
<!--End Navigate-->

<!--Start Testinomial-->
<app-testinomial-component></app-testinomial-component>
<!--End Testinomial-->
