import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT, isPlatformBrowser, Location } from '@angular/common';
import { ScriptService, MetaDataService } from '../services';
import { Meta, Title } from '@angular/platform-browser';
import { GeolocationService } from '../geolocation.service';
import { BlogService } from '../services';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css'],
})
export class BlogComponent extends MetaDataService implements OnInit {
  isServer = !isPlatformBrowser(this.platformId);
  loading: boolean = false;
  blogs: any = [];
  categories: any = [];
  tags: any = [];
  posts: any[] = [];
  total: number = 0;
  isLoad = false;
  public userLanguage: string;
  public userCountry: string;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    private scriptService: ScriptService,
    private blogService: BlogService,
    private geolocation: GeolocationService,
    private location: Location,
    private router: Router,
    title: Title,
    meta: Meta
  ) {
    super(title, meta);
    this.updateTags(
      'Blog',
      'We have been providing software products to startups and Software development companies in various ranges of scale. We are offering custom software development, web app development, mobile app development & dedicated software teams to software companies and startups.',
      this.location.prepareExternalUrl(this.router.url)
    );

  }

  ngOnInit(): void {
    if(!this.isServer){
      this.scriptService.load(this.scriptService.INIT).then(() => {
        this.isLoad = true;
      });
      this.geolocation.getClientLocale().subscribe((data) => {
        this.userLanguage = data.language == 'vn' ? 'vi' : data.language;
        this.userCountry = data.country;
      });
    }

    this.userLanguage = this.location.prepareExternalUrl('/').replace("/", "").replace("/", "") == 'en' ? 'en' : 'vi';

    this.fetchInitialData();
  }

  private fetchInitialData(): void {
    this.loading = true;
    this.blogService.getBlogs(this.userLanguage).subscribe(
      (response) => {
        // default public data
        this.blogs = response.data.map((b) =>
          this.blogService.formatBlogDate(b)
        );
        this.total = response.meta.pagination.total;
        this.loading = false;
      },
      (error) => {
        console.error('Error fetching data:', error);
        this.loading = false;
      },
      () => {
        this.loading = false;
      }
    );
  }
}
