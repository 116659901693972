<!--Breadcrumb Area-->
<section class="breadcrumb-area banner-6">
  <div class="text-block">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 v-center">
          <div class="bread-inner">
            <div class="bread-menu wow fadeInUp" data-wow-delay=".2s">
              <ul>
                <li><a [routerLink]="['/']" i18n>Home</a></li>
                <li><span i18n>Career</span></li>
              </ul>
            </div>
            <div class="bread-title wow fadeInUp" data-wow-delay=".5s">
              <h1 i18n>Job Openings</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Breadcrumb Area-->
<!--Start About-->
<section class="about-agencys pad-tb block-1">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="common-heading text-l">
          <h2 class="mb20" i18n>Career and Culture at Dicom</h2>
          <p i18n>Dicom Interactive is one of the top boutique software development companies with offices located in
            Melbourne (Australia) and Ho Chi Minh City (Vietnam).</p>
          <p i18n>We value diversity. We believe that cultural diversity would unleash full potential of teamwork. With a
            formulated combination of a workforce grouping senior and junior levels in software development team,
            creative team and product team from various industries, we are able to bring value added services to our
            clients.</p>
          <p i18n>We have been working on a variety of projects across the continents, with focus on education,
            transportation, entertainment, game, healthcare, insurance, food & beverage, automobile, travel industry,
            and more to come.</p>
          <a (click)="scroll(job)" class="btn-main bg-btn2 lnk mt30" i18n> View Opening <i
              class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></a>
        </div>
      </div>
      <div class="col-lg-6 v-center">
        <div class="image-block mb0 m-mt30">
          <img src="assets/images/about/office-4.webp" alt="about" class="img-fluid" />
        </div>
      </div>
    </div>
  </div>
</section>
<!--End About-->
<!--Start Why Choose-->
<section class="service pad-tb bg-gradient5">
  <div class="container">
    <div class="row">
      <div class="col-lg-4">
        <div class="image-block upset bg-shape">
          <img src="assets/images/about/you.webp" alt="image" class="img-fluid" />
        </div>
      </div>
      <div class="col-lg-8 block-1">
        <div class="common-heading text-l pl25">
          <h2 i18n>About You</h2>
          <ul class="list-style-">
            <li i18n>You are ready to excel, innovate, and truly enjoy your work. You are passionate and honest.</li>
            <li i18n>You are a fast learner with can-do attitude and pay attention to details.</li>
            <li i18n>You are a good team player and can work independently under deadline. You are curious to learn and have
              good analytical capability.</li>
          </ul>
          <h5 class="mt20 mb10" i18n>THE OPPORTUNITIES</h5>
          <p i18n>Dicom Interactive has been undertaking successful internship programs for students. You are offered
            opportunities to grow your technical and non-technical skills. You will be working with senior colleagues
            who are enthusiastic about their works and willing to share their passion.
            Interns will be based in our Ho Chi Minh office.</p>
          <p i18n>The internship will last from 2 to 6 months. In this position, you will work with Design team, Web
            Development team, Mobile Development team, and QC team. </p>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Why Choose-->
<!--Start Enquire Form-->
<section #job class="contact-page pad-tb" id="jobs">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div class="common-heading ptag">
          <span i18n>Jobs</span>
          <h2 i18n>Current Openings</h2>
          <p class="mb60" i18n>The openings are for Design team, Development team, and QC team.</p>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div class="career-card-div">
          <div id="accordion" class="accordion">
            <div class="card-2" style="display: none;">
              <div class="card-header" id="acc1">
                <button class="btn btn-link btn-block text-left acc-icon" type="button" data-toggle="collapse"
                  data-target="#collapse-a" aria-expanded="true" aria-controls="collapse-a">
                  Junior QC Engineer (Manual Tester)
                </button>
              </div>
              <div id="collapse-a" class="card-body collapse show p0" aria-labelledby="acc1" data-parent="#accordion">
                <div class="data-reqs">
                  <h4 class="pt20 pb10">Reports To</h4>
                  <p class="">Junior QC sẽ báo cáo cho Project Manager.</p>
                  <!-- <h4 class="pt20 pb10">Trách nhiệm</h4> -->
                  <h4 class="pt20 pb10">Job Overview</h4>
                  <ul class="list-ul ul-check">
                    <li>Tạo và kiểm thử theo các yêu cầu được giao.</li>
                    <li>Hỗ trợ QA Manager trong việc chuẩn bị và thực hiện regression tests.</li>
                    <li>Thực thi quá trình kiểm thử với các trường hợp nâng cấp hệ thống, sửa lỗi, các tính năng
                      mới.</li>
                    <li>Thực thi quá trình kiểm thử bao gồm kiểm thử hồi quy (regression testing) với tất cả các
                      bản build mới.</li>
                    <li>Tạo và lưu các lỗi tìm ra được, cũng như nhận và quản lý bug reports từ team.</li>
                    <li>Hỗ trợ đội kỹ thuật để giải quyết các vấn đề xảy ra ở môi trường production khi cần
                      thiết.</li>
                    <li>Có kinh nghiệm kiểm tra với các ứng dụng web cũng như các nên tảng chéo (cross-
                      platform).</li>
                  </ul>

                  <h4 class="pt20 pb10">Responsibilities and Duties</h4>
                  <ul class="list-ul ul-check">
                    <li>
                      <span>Có kiến thức về phát triển phần mềm và các quy trình đảm bảo chất lượng như là:</span>
                      <ul class="pt10">
                        <li>Thực hiện kiểm thử</li>
                        <li>Theo dõi và báo cáo lỗi</li>
                      </ul>
                    </li>
                    <li>
                      <span>Có khả năng thiết lập và làm tài liệu:</span>
                      <ul class="pt10">
                        <li>Test cases</li>
                        <li>Regression tests</li>
                        <li>Usability tests</li>
                        <li>Test scenarios</li>
                      </ul>
                    </li>
                    <li>Có khả năng làm việc với QA manager và hiểu được chiến lược kiểm tra (Test Strategies).</li>
                    <li>Có khả năng làm việc với lập trình viên (developer) và quản lý dự án (project manager).</li>
                    <li>Cập nhật và thiết lập độ ưu tiên với QA Manager dựa trên các yêu cầu mới, các thay đổi;
                      Có khả năng đưa ra thông báo rõ ràng về các lỗi tìm được cho các lập trình viên để giải
                      quyết chúng nhanh nhất có thể.</li>
                    <li>Kiểm tra, xác nhận, và theo dõi bằng Jira / ClickUp.</li>
                    <li>Có khả năng làm việc dưới áp lực tốt.</li>
                  </ul>

                  <h4 class="pt20 pb10">Qualifications</h4>
                  <ul class="list-ul ul-check">
                    <li>Có 1-2 năm kinh nghiệm.</li>
                    <li>Đọc hiểu tiếng Anh là bắt buộc. Giao tiếng bằng tiếng Anh là một điểm cộng.</li>
                    <li>Có kinh nghiệm kiểm thử mobile (iOS, Android) và / hoặc với web.</li>
                    <li>Có kinh nghiệm làm việc với Jira/ ClickUp, cũng như có kinh nghiệm quản lý test-case, là một điểm cộng.</li>
                    <li>Có kiến thức về UI/UX là một điểm cộng lớn.</li>
                    <li>Có kiến thức với Agile methodology là một điểm cộng lớn.</li>
                  </ul>
                  <h4 class="pt20 pb10">Benefits</h4>
                  <ul class="list-ul ul-check">
                    <li>Làm việc giờ hành chính từ thứ hai đến thứ sáu.</li>
                    <li>Đánh giá năng lực hàng năm.</li>
                    <li>BHXH, BHYT, BHTN theo quy định của nhà nước và lương tháng 13 theo tình hình kinh
                      doanh của công ty</li>
                    <li>Môi trường làm việc không gian mở.</li>
                    <li>Tham gia chơi game Xbox với đồng nghiệp và Đồ ăn nhẹ miễn phí.</li>
                    <li>Tiệc và company trip hàng năm.</li>
                  </ul>
                  <a href="#" class="btn-main bg-btn3 lnk mt20" data-toggle="modal" data-target="#modalform"
                    (click)="selectPosition('junior_qc_engineer', careerForm)" i18n>Apply Now<i
                      class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></a>
                </div>
              </div>
            </div>

            <div class="card-2 mt30">
              <div class="card-header" id="acc1">
                <button class="btn btn-link btn-block text-left acc-icon" type="button" data-toggle="collapse"
                  data-target="#collapse-a" aria-expanded="true" aria-controls="collapse-a">
                  Business Development Executive
                </button>
              </div>
              <div id="collapse-a" class="card-body collapse show p0" aria-labelledby="acc1" data-parent="#accordion">
                <div class="data-reqs">
                  <p class="pt20 pb20" i18n>Dicom Interactive is seeking a Business Development Executive to help us build relationships with customers, both domestically and internationally, who require our products and service</p>
                  <p i18n>To excel in this role, you will need to possess excellent customer listening skills, prioritize all customer needs, have the ability to understand business requirements, and oversee the entire lifecycle of subscription-based services. Excellent communication and problem-solving skills are essential for collaborating with our product development team to assist customers in operating their businesses on the Dicom platform.</p>
                  <h4 class="pt20 pb10" i18n>Responsibilities</h4>
                  <h5 class="pt10 pb10" i18n>Sales (60% of time):</h5>
                  <ul class="list-ul ul-check">
                    <li i18n>Prospect for new clients and identify potential customer segments.</li>
                    <li i18n>Serve as the primary point of contact for potential and existing customers.</li>
                    <li i18n>Develop effective sales messages and convert leads into customers.</li>
                    <li i18n>Regularly update the customer database and maintain ongoing customer relationships.</li>
                    <li i18n>Proactively schedule customer meetings.</li>
                    <li i18n>Create and manage all sales materials.</li>
                    <li i18n>Achieve weekly, monthly, and annual sales targets.</li>
                  </ul>
                  <h5 class="pt20 pb10" i18n>After-sales service (20% of time):</h5>
                  <ul class="list-ul ul-check">
                    <li i18n>Assist customers with resolving simple operational issues on the provided platform.</li>
                    <li i18n>Relay complex customer requirements to the Dicom product development team.</li>
                  </ul>
                  <h5 class="pt20 pb10" i18n>Marketing (20% of time):</h5>
                  <ul class="list-ul ul-check">
                    <li i18n>Assist customers with resolving simple operational issues on the provided platform.</li>
                    <li i18n>Relay complex customer requirements to the Dicom product development team.</li>
                  </ul>
                  <h5 class="pt20 pb10" i18n>Requirements:</h5>
                  <ul class="list-ul ul-check">
                    <li i18n>Dynamic and customer-centric personality.</li>
                    <li i18n>Excellent verbal and written communication skills.</li>
                    <li i18n>Knowledge of IT and a holistic mindset are advantages.</li>
                    <li i18n>Extensive connections within the education industry are an advantage.</li>
                    <li i18n>Understanding of various business models is an advantage.</li>
                    <li i18n>Proficient in English.</li>
                    <li i18n>Bachelor's degree.</li>
                  </ul>
                  <a href="#" class="btn-main bg-btn3 lnk mt20" data-toggle="modal" data-target="#modalform"
                    (click)="selectPosition('business_development_executive', careerForm)" i18n>Apply Now<i
                      class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></a>
                </div>
              </div>
            </div>

            <div class="card-2 mt30" style="display: none;">
              <div class="card-header" id="acc2">
                <button class="btn btn-link btn-block text-left acc-icon collapsed" type="button" data-toggle="collapse"
                  data-target="#collapse-b" aria-expanded="true" aria-controls="collapse-b" i18n>
                  Junior Front-End Developer
                </button>
              </div>
              <div id="collapse-b" class="card-body collapse p0" aria-labelledby="acc2" data-parent="#accordion">
                <div class="data-reqs">
                  <h4 class="pt20 pb20" i18n>Job description</h4>
                  <p i18n>Are you passionate about front-end development and eager to kick-start your career in the tech
                    industry? We are seeking a talented and motivated Junior Front-End Developer to join our dynamic
                    team at Dicom Interactive. As a Junior Front-End Developer, you will have the opportunity to work on
                    exciting projects, collaborate with experienced developers, and contribute to the development of
                    cutting-edge web applications.</p>
                  <h4 class="pt20 pb10" i18n>Responsibilities:</h4>
                  <ul class="list-ul ul-check">
                    <li i18n>Collaborate with the development team to design and develop user-friendly and responsive web
                      interfaces using HTML, CSS, and JavaScript.</li>
                    <li i18n>Implement new features and enhancements, and troubleshoot issues across various web browsers and
                      platforms.</li>
                    <li i18n>Participate in code reviews to ensure best practices, code quality, and adherence to project
                      guidelines.</li>
                    <li i18n>Assist in the optimization of web applications for maximum speed and scalability.</li>
                    <li i18n>Collaborate with designers and back-end developers to bridge the gap between graphical design
                      and technical implementation.</li>
                    <li i18n>Stay up-to-date with the latest front-end development trends and technologies, and proactively
                      contribute innovative ideas to improve our development processes.
                    </li>
                  </ul>
                  <h4 class="pb20 pt20" i18n>Candidate requirements:</h4>
                  <ul class="list-ul ul-check">
                    <li i18n>Bachelor’s degree in Computer Science, Web Development, or a related field (or equivalent
                      practical experience).</li>
                    <li i18n>Solid understanding of front-end technologies such as HTML5, CSS3, JavaScript, and responsive
                      web design principles.</li>
                    <li i18n>Experience with ReactJs is mandatory, in addition, familiarity with NextJs, ReactNative,
                      Angular, Vue.js, NuxtJs is a significant advantage.</li>
                    <li i18n>Knowledge of version control systems (e.g., Git) and familiarity with agile development
                      methodologies.</li>
                    <li i18n>Basic understanding of back-end technologies and how they integrate with front-end applications.
                    </li>
                    <li i18n>Strong problem-solving skills and attention to detail, with the ability to debug and
                      troubleshoot code efficiently.</li>
                    <li i18n>Excellent communication and teamwork skills, with the ability to effectively collaborate with
                      cross-functional teams.</li>
                    <li i18n>A strong desire to learn and grow in a fast-paced, dynamic environment.</li>
                  </ul>
                  <!-- <hr color="black" size="2">
                  <h4 class="pt20 pb20">Mô tả công việc</h4>
                  <p>Bạn có đam mê với lập trình front-end và muốn bắt đầu sự nghiệp của mình trong ngành công nghệ
                    thông tin? Dicom Interactive đang tìm kiếm một Junior Front-End Developer tài năng và đầy nhiệt
                    huyết để tham gia vào các dự án website/ software hấp dẫn. Là một Junior Front-End Developer, bạn sẽ
                    có cơ hội cộng tác với những lập trình viên nhiều kinh nghiệm và đóng góp vào việc phát triển các
                    ứng dụng web/ software với công nghệ tiên tiến nhất.</p>
                  <h4 class="pt20 pb10">Trách nhiệm:</h4>
                  <ul class="list-ul ul-check">
                    <li>Làm việc nhóm để thiết kế và phát triển giao diện web thân thiện với người dùng và tương thích
                      trên nhiều thiết bị bằng HTML, CSS và JavaScript.</li>
                    <li>Triển khai các tính năng và cải tiến mới, và khắc phục sự cố trên các trình duyệt web và nền
                      tảng khác nhau.</li>
                    <li>Tham gia vào duyệt code và đảm bảo tuân thủ các quy chuẩn, chất lượng mã nguồn và tuân thủ các
                      hướng dẫn dự án.</li>
                    <li>Hỗ trợ tối ưu hóa các ứng dụng web để đạt được tốc độ và khả năng mở rộng tối đa.</li>
                    <li>Cộng tác với lập trình viên back-end để hiện thực hóa ý tưởng từ thiết kế đến kỹ thuật.</li>
                    <li>Cập nhật công nghệ phát triển front-end mới nhất, đồng thời đóng góp ý tưởng sáng tạo để cải
                      tiến quy trình phát triển.</li>
                  </ul>
                  <h4 class="pb20 pt20">Yêu cầu ứng viên:</h4>
                  <ul class="list-ul ul-check">
                    <li>Bằng cử nhân về Khoa học Máy tính, Phát triển Web hoặc ngành liên quan (hoặc kinh nghiệm thực tế
                      tương đương).</li>
                    <li>Hiểu biết vững chắc về các công nghệ front-end như HTML5, CSS3, JavaScript và nguyên tắc thiết
                      kế web responsive.</li>
                    <li>Có kinh nghiệm với ReactJs, ngoài ra, quen thuộc với NextJs, ReactNative, Angular, Vue.js,
                      NuxtJs là một lợi thế lớn.</li>
                    <li>Hiểu biết về hệ thống quản lý mã nguồn (ví dụ: Github) và quen thuộc với phương pháp Agile.</li>
                    <li>Hiểu cơ bản về các công nghệ back-end và cách tích hợp chúng với các ứng dụng front-end.</li>
                    <li>Kỹ năng giải quyết vấn đề nhanh, có khả năng tìm lỗi và khắc phục một cách hiệu quả.</li>
                    <li>Kỹ năng giao tiếp và làm việc nhóm tốt, có khả năng làm việc hiệu quả với các nhóm khác.</li>
                    <li>Có tinh thần học hỏi và phát triển bản thân trong một môi trường thay đổi nhanh, và năng động.
                    </li>
                    </ul> -->
                    <a href="#" class="btn-main bg-btn3 lnk mt20" data-toggle="modal" data-target="#modalform"
                      (click)="selectPosition('front_end_developer', careerForm)" i18n>Apply Now<i
                        class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></a>
                </div>
              </div>
            </div>

            <div class="card-2 mt30">
              <div class="card-header" id="acc2">
                <button class="btn btn-link btn-block text-left acc-icon collapsed" type="button" data-toggle="collapse"
                  data-target="#collapse-c" aria-expanded="true" aria-controls="collapse-c">
                  Developer Intern
                </button>
              </div>
              <div id="collapse-c" class="card-body collapse p0" aria-labelledby="acc2" data-parent="#accordion">
                <div class="data-reqs">
                  <h5 class="pt20 pb20" i18n>Overview</h5>
                  <p i18n>You will support Development team in maintaining and optimizing web or mobile applications, and to
                    work on some technical documentations.</p>
                  <ul class="list-ul ul-check">
                    <li i18n>Final year students in related fields with IT relevant backgrounds.</li>
                    <li i18n>Intermediate knowledge of backend such as NodeJS, Python (optional) or Java (optional) and
                      frontend development such as ExpressJS, ReactJs, NextJs...</li>
                    <li i18n>Familiar with OOP, Source control (git) and AWS as a plus point.</li>
                    <li i18n>Advanced level in English is a plus point.</li>
                    <li i18n>Strong in communication & collaboration, and good problem solving skills.</li>
                    <li i18n>Be proactive and effective self-learning</li>
                  </ul>
                  <h4 class="pb20 pt20" i18n>Key benefits</h4>
                  <p i18n>This allows you to put your learning into practice and to gain experience which are beneficial for
                    your future careers. You will be equipped with necessary tools to succeed in this role.
                    You will have good exposure to our dynamic and supportive work environment.
                    You will receive on the job training and mentorship frequently from your manager or leader.</p>
                  <a href="#" class="btn-main bg-btn3 lnk mt20" data-toggle="modal" data-target="#modalform"
                    (click)="selectPosition('developer_intern', careerForm)" i18n>Apply Now<i
                      class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></a>
                </div>
              </div>
            </div>
            <div class="card-2 mt30">
              <div class="card-header" id="acc2">
                <button class="btn btn-link btn-block text-left acc-icon collapsed" type="button" data-toggle="collapse"
                  data-target="#collapse-d" aria-expanded="true" aria-controls="collapse-d">
                  Graphic Designer Intern
                </button>
              </div>
              <div id="collapse-d" class="card-body collapse p0" aria-labelledby="acc2" data-parent="#accordion">
                <div class="data-reqs">
                  <h5 class="pt20 pb20" i18n>Overview</h5>
                  <p i18n>You will support Design team in web application design related and other communication materials.
                  </p>
                  <h5 class="pt20 pb20" i18n>Required experience</h5>
                  <ul class="list-ul ul-check">
                    <li i18n>Final year students in related fields with IT relevant backgrounds.</li>
                    <li i18n>Ability to work with details in fast paced environment</li>
                    <li i18n>Sound business acumen and strong interest in UI/UX.</li>
                    <li i18n>Actively involved in building product design</li>
                    <li i18n>A portfolio demonstrating relevant design capabilities is required</li>
                    <li i18n>Competencies in Adobe softwares & a variety of product design tools such as Sketch, Figma,... or
                      tools with similar purposes</li>
                    <li i18n>Advanced level in English is a plus point.</li>
                    <li i18n>Strong in communication & collaboration, and good problem solving skills.</li>
                  </ul>
                  <h4 class="pb20 pt20" i18n>Key benefits</h4>
                  <p i18n>This allows you to put your learning into practice and to gain experience which are beneficial for
                    your future careers. You will be equipped with necessary tools to succeed in this role.
                    You will have good exposure to our dynamic and supportive work environment.
                    You will receive on the job training and mentorship frequently from your manager or leader.</p>
                  <a href="#" class="btn-main bg-btn3 lnk mt20" data-toggle="modal" data-target="#modalform"
                    (click)="selectPosition('graphic_designer_intern', careerForm)" i18n>Apply Now<i
                      class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></a>
                </div>
              </div>
            </div>
            <div class="card-2 mt30">
              <div class="card-header" id="acc2">
                <button class="btn btn-link btn-block text-left acc-icon collapsed" type="button" data-toggle="collapse"
                  data-target="#collapse-e" aria-expanded="true" aria-controls="collapse-e">
                  QC Intern
                </button>
              </div>
              <div id="collapse-e" class="card-body collapse p0" aria-labelledby="acc2" data-parent="#accordion">
                <div class="data-reqs">
                  <h5 class="pt20 pb20" i18n>Overview</h5>
                  <p i18n>You will support QC and BA team in defining test cases and debugging procedures.</p>
                  <ul class="list-ul ul-check">
                    <li i18n>Final year students in related fields with IT relevant backgrounds.</li>
                    <li i18n>Basic knowledge of backend and frontend development.</li>
                    <li i18n>Sound business acumen and strong interest in UI/UX.</li>
                    <li i18n>Advanced level in English is a plus point.</li>
                    <li i18n>Strong in communication & collaboration, and good problem solving skills.</li>
                  </ul>
                  <h4 class="pb20 pt20" i18n>Key benefits</h4>
                  <p i18n>This allows you to put your learning into practice and to gain experience which are beneficial for
                    your future careers. You will be equipped with necessary tools to succeed in this role.
                    You will have good exposure to our dynamic and supportive work environment.
                    You will receive on the job training and mentorship frequently from your manager or leader.</p>
                  <a href="#" class="btn-main bg-btn3 lnk mt20" data-toggle="modal" data-target="#modalform"
                    (click)="selectPosition('qc_intern', careerForm)" i18n>Apply Now<i class="fas fa-chevron-right fa-icon"></i><span
                      class="circle"></span></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Enquire Form-->
<div class="popup-modals">
  <div class="modal" id="modalform">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <div class="common-heading">
            <h4 class="mt0 mb0" i18n>Apply Now</h4>
          </div>
          <button type="button" class="closes" data-dismiss="modal">&times;</button>
        </div>
        <!-- Modal body -->
        <div class="modal-body pt40 pb60">
          <div class="form-block fdgn2">
            <form #careerForm="ngForm" name="carerr-form">
              <div class="fieldsets row">
                <div class="col-md-12 form-group">
                  <input type="text" id="name" name="name" [ngModel]="career.name" #name="ngModel"
                    placeholder="Enter your name *" required data-error="Please fill Out" i18n-placeholder
                    required oninput="this.value = this.value.replace(/[\s]+/g, ' ',)" minlength="6" data-error="Please fill Out">
                  <div *ngIf="submitted || (name.invalid && (name.dirty || name.touched))">
                    <div *ngIf="name.errors?.required" class="help-block with-errors" i18n>Name is required</div>
                    <div *ngIf="name.errors?.minlength" class="help-block with-errors" i18n>Name must be at least 6 characters</div>
                  </div>
                </div>
              </div>
              <div class="fieldsets row">
                <div class="col-md-6 form-group phone">
                  <input type="text" id="mobile" name="phone" id="phone" [ngModel]="career.phone" ng2TelInput
                    [ng2TelInputOptions]="{'preferredCountries': ['vn', 'au']}" required minlength="6" maxlength="12"
                    [minlength]="phone.errors?.pattern ? null : 6" [maxlength]="phone.errors?.pattern ? null : 12"
                    pattern="\d+" (countryChange)="onCountryChange($event)" #phone="ngModel" placeholder="Enter mobile" i18n-placeholder required data-error="Please fill Out">
                  <div *ngIf="submitted || (phone.invalid && (phone.dirty || phone.touched))">
                    <div *ngIf="phone.errors?.required" class="help-block with-phone" i18n>Mobile is required</div>
                    <div *ngIf="phone.errors?.minlength || phone.errors?.maxlength" class="help-block with-phone" i18n>Mobile number must be between 6 and 12 digits</div>
                    <div *ngIf="phone.errors?.pattern" class="help-block with-phone" i18n>Invalid Mobile Number</div>
                  </div>
                </div>
                <div class="col-md-6 form-group">
                  <select id="position" name="position" [(ngModel)]="career.position" #position="ngModel" required
                    data-error="Please fill Out">
                    <!-- <option value="">Select Position</option> -->
                    <option value="junior_qc_engineer">Junior QC Engineer</option>
                    <option value="business_development_executive">Business Development Executive</option>
                    <option value="front_end_developer">Junior Front-End Developer</option>
                    <option value="developer_intern">Developer Intern</option>
                    <option value="graphic_designer_intern">Graphic Designer Intern</option>
                    <option value="qc_intern">QC Intern</option>
                    <option value="other" i18n>Other</option>
                  </select>
                  <div *ngIf="submitted || (position.invalid && (position.dirty || position.touched))">
                    <div *ngIf="position.errors?.required" class="help-block with-errors" i18n>Position is required</div>
                  </div>
                </div>
              </div>
              <div class="fieldsets form-group">
                <textarea type="text" id="message" name="message" [ngModel]="career.message" #message="ngModel" i18n-placeholder
                  placeholder="Enter your Message *" required data-error="Please fill Out" rows="4"></textarea>
                <div *ngIf="submitted || (message.invalid && (message.dirty || message.touched))">
                  <div *ngIf="message.errors?.required" class="help-block with-errors" i18n>Message is required</div>
                </div>
              </div>
              <div class="fieldsets- row">
                <div class="col-md-12 form-group">
                  <div class="custom-file">
                    <input type="file" id="files" name="files" #fileInput [ngModel]="career.files" #files="ngModel"
                      accept=".pdf, .doc, .docx" (click)="fileInput.value = null" (change)="handleFileInput($event.target.files)" multiple required
                      class="custom-file-input mb0 cursor-pointer">
                    <label class="custom-file-label" for="files" i18n>Choose your CV, Portfolio..</label>
                  </div>
                  <!-- validate file input check -->
                  <div *ngIf="(submitted || (files.invalid && (files.dirty || files.touched)) && (!fileChose1))">
                    <div *ngIf="files.errors?.required" class="help-block with-errors" style="margin-top: 16px;" i18n>File is required</div>
                  </div>
                  <!-- maximum file check -->
                  <div *ngIf="fileInputError" class="help-block with-errors" style="margin-top: 16px;">
                    {{fileInputError}}</div>
                  <!-- file name -->
                  <div *ngIf="fileChose1 && !fileInputError" style="margin-top: 10px;"><small
                      class="help-block with-messages">1. {{fileChose1}}</small></div>
                  <div *ngIf="fileChose2 && !fileInputError"><small class="help-block with-messages">2.
                      {{fileChose2}}</small></div>
                  <p><small i18n>Please upload maximum 2 files Only pdf, docx and doc files.</small></p>
                </div>
              </div>
              <div class="fieldsets mt20">
                <button type="submit" class="lnk btn-main bg-btn" (click)="onSubmit(careerForm)" i18n>Submit Application<span
                    class="circle"></span></button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
