import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { CollectionService } from '../services/collection.service';
import {
  Category,
  Collection,
  CollectionPage,
  SortCollectionType,
  Tag,
} from '../types/collection';
import { forkJoin, of, Subscription } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Subject } from 'rxjs';
import { catchError, debounceTime, takeUntil } from 'rxjs/operators';
import { CollectionCacheService } from '../services/collection-cache.service';
import { Title, Meta } from '@angular/platform-browser';
import { MetaDataService, ScriptService } from '../services';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-collection',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.css'],
})
export class CollectionComponent
  extends MetaDataService
  implements OnInit, OnDestroy
{
  isServer = !isPlatformBrowser(this.platformId)
  innerWidth: number = typeof window !== 'undefined' ? window.innerWidth : 0;
  throttle = 200;
  distance = 1.5;
  isLoad = false;
  total = 0;
  limit = 12;
  start = 0;
  postionScroll = 0;
  loading = false;
  isRouteChange = false;
  filtering = false;
  page: CollectionPage = {
    text: '',
    id: 0,
    documentId: '',
    group_options: [],
    heading: '',
    seo: {
      id: null,
      metaDescription: '',
      metaImage: null,
      metaTitle: ''
    }
  };
  collections: Collection[] = [];
  categories: Category[] = [];
  tags: Tag[] = [];
  selectedCategories = new Set<string>();
  selectedTags = new Set<string>();
  selectedSort: SortCollectionType = 'createdAt:desc';
  firstLoaded = true;
  searchQuery = '';
  timerScrollWhenBack: any = null;
  timerScrollWhenRefetch: any = null;
  defaultParams = {
    'fields[0]': 'title',
    'fields[1]': 'basic_price',
    'fields[2]': 'slug',
    populate: 'thumbnail',
    'sort[0]': 'createdAt:desc',
    'populate[0]': 'layouts',
    'pagination[start]': this.start.toString(),
    'pagination[limit]': this.limit.toString(),
  };
  collectionParams = new HttpParams({
    fromObject: this.defaultParams,
  });
  private queryParamsSubscription: Subscription;
  private destroy$ = new Subject<void>();
  private filterSubject = new Subject<void>();
  @ViewChild('filterCol') filterCol!: ElementRef;
  @ViewChild('rowCardList') rowCardList!: ElementRef;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    private collectionService: CollectionService,
    private router: Router,
    private http: HttpClient,
    private collectionCacheService: CollectionCacheService,
    private activatedRoute: ActivatedRoute,
    private scriptService: ScriptService,
    title: Title,
    meta: Meta
  ) {
    super(title, meta);
  }

  ngOnInit(): void {
    this.postionScroll =  this.collectionCacheService.getCache()['postionScroll']

    //lấy dữ liệu từ query params và maping
    this.queryParamsSubscription = this.activatedRoute.queryParams.subscribe(
      (params) => {
        this.collectDatafromQueryParams(params);
        this.updateCollectionParams();
        this.filterSubject.next();
      }
    );

    const queryParams = this.activatedRoute.snapshot.queryParams;
    const isRefetch = queryParams?.refetch === 'true';

    // load cache
    this.loadCollectionPageInCache();
    this.loadFilterDataInCache();

    this.fetchInitialData(isRefetch);

    if(!this.isServer){
      this.scriptService.load(this.scriptService.INIT).then(() => {
        this.isLoad = true;
      });
      this.removeModalIfExist();
    }

    this.filterSubject
      .pipe(debounceTime(300), takeUntil(this.destroy$))
      .subscribe(() => {
        this.filterOperation();
      });
  }

  private filterOperation() {
    this.updateURLQueryParams();
    this.updateCollectionParams();
    this.getCollectionsByFilter();
  }

  private removeModalIfExist(): void {
    const modal = document.querySelector('.modal.show');
    if (modal) {
      modal.classList.remove('show');
    }
    const modalBackdrop = document.querySelector('.modal-backdrop.show');
    if (modalBackdrop) {
      document.body.removeChild(modalBackdrop);
    }
    const body = document.querySelector('body');
    if (body) {
      body.classList.remove('modal-open');
    }
  }

  private scrollToTopRowList(): void {
    if (this.rowCardList) {
      this.rowCardList.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }

  private loadLazyCache(): void {
    // lazy load params from cache
    const { start, limit } = this.collectionCacheService.getCache();
    // when first load
    if (!start && !limit) return;
    // when lazy loaded
    if (start > 0) {
      this.start = 0;
      this.limit = start + limit;

      this.collectionParams = this.collectionParams
        .set('pagination[start]', String(0))
        .set('pagination[limit]', String(start + limit));
    }
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event: Event): void {
    const windowScroll =
      window.scrollY !== undefined
        ? window.scrollY
        : document.documentElement.scrollTop || document.body.scrollTop;

    const curPosition = Math.floor(windowScroll);
    this.postionScroll = curPosition;
    if (typeof window !== 'undefined') {
      sessionStorage.setItem('collections-scroll', curPosition.toString());
    }
    this.collectionCacheService.setCache({
      postionScroll: curPosition,
      start: this.start,
      limit: this.limit,
      total: this.total,
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    const curWidth = (event.target as Window).innerWidth;
    this.innerWidth = curWidth;

    const modalFilter = document.getElementById('modalfilter-full');
    if (curWidth >= 768 && modalFilter?.classList.contains('show')) {
      modalFilter.classList.remove('show');
      document.body.classList.remove('modal-open');
      document.body.attributes.removeNamedItem('style');
      const modalBackdrop = document.querySelector('.modal-backdrop.show');
      if (modalBackdrop) {
        document.body.removeChild(modalBackdrop);
      }
    }
  }

  onScrollLazyLoad(): void {
    if (this.loading || this.collections.length === this.total) return;

    this.loading = true;
    //when params from lazy cache loaded
    if (this.limit > 12) {
      this.start = this.limit;
      this.limit = 12;
      this.collectionParams = this.collectionParams
        .set('pagination[start]', String(this.start))
        .set('pagination[limit]', String(this.limit));
    } else {
      this.limit = 12;
      this.start += this.limit;
      this.collectionParams = this.collectionParams.set(
        'pagination[start]',
        this.start.toString()
      );
      this.collectionParams = this.collectionParams.set(
        'pagination[limit]',
        '12'
      );
    }

    this.collectionService
      .getCollections(this.collectionParams)
      .pipe(
        catchError((error) => {
          return of({
            data: [],
            meta: {
              pagination: {
                page: 0,
                pageSize: 0,
                pageCount: 0,
                total: 0,
              },
            },
          });
        })
      )
      .subscribe(
        (result) => {
          this.collections = [...this.collections, ...result.data];
          this.collectionCacheService.setCache({
            ...this.collectionCacheService.getCache(),
            collections: this.collections,
            start: this.start,
            limit: this.limit,
            total: result.meta.pagination.total,
            postionScroll: this.postionScroll,
          });
        },
        (error) => {
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
  }

  trackById(index: number, item: Collection | Tag | Category): number {
    return item.id;
  }

  private collectDatafromQueryParams(params: Params) {
    const listKeys = ['categories', 'tags', 'search', 'sort'] as const;
    const cloneParams = { ...params };
    const resolvedParams = listKeys.reduce((acc, cur) => {
      acc[cur] = cloneParams[cur] || (cur === 'sort' ? 'createdAt:desc' : '');
      return acc;
    }, {});

    const actions: Record<(typeof listKeys)[number], (param: string) => void> =
      {
        categories: (param: string) => {
          if (!param) {
            this.selectedCategories = new Set<string>();
            return;
          }
          const selection = new Set<string>(
            decodeURIComponent(param).split(',')
          );
          if (selection.size) {
            this.selectedCategories = selection;
            Array.from(selection).forEach((slug, index) => {
              this.setCollectionArrayParams('categories', slug, index);
            });
          }
        },
        tags: (param: string) => {
          if (!param) {
            this.selectedTags = new Set<string>();
            return;
          }
          const selection = new Set<string>(
            decodeURIComponent(param).split(',')
          );
          if (selection.size) {
            this.selectedTags = selection;
            Array.from(selection).forEach((slug, index) => {
              this.setCollectionArrayParams('tags', slug, index);
            });
          }
        },
        search: (param: string) => {
          if (!param) {
            this.searchQuery = '';
            return;
          }
          const text = decodeURIComponent(param);
          this.setCollectionSearchParam(text);
        },
        sort: (param: string) => {
          if (!param) {
            this.selectedSort = 'createdAt:desc';
            return;
          }
          this.selectedSort = decodeURIComponent(param) as SortCollectionType;
        },
      };

    Object.keys(resolvedParams).forEach((key) => {
      const param = resolvedParams[key];
      const action = actions[key];
      if (action) {
        action(param);
      }
    });
  }

  private loadFilterDataInCache(): void {
    this.categories = this.collectionCacheService.getCache()['categories'];
    this.tags = this.collectionCacheService.getCache()['tags'];
  }

  private loadCollectionPageInCache(): void {
    this.page = this.collectionCacheService.getCache()['page'];
  }

  private fetchInitialData(isRefetch: boolean): void {
    this.loadLazyCache();
    this.loading = true;
    forkJoin({
      page: this.collectionService.getCollectionPage(),
      collections: this.collectionService.getCollections(this.collectionParams),
      categories: this.collectionService.getCategories(),
      tags: this.collectionService.getTags(),
    })
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        ({ collections, categories, tags, page }) => {
          this.page = page.data;
          this.collections = collections.data;
          this.categories = categories.data;
          this.tags = tags.data;
          this.total = collections.meta.pagination.total;
          this.setMetaTags({
            title: this?.page?.seo?.metaTitle || 'Collection | Dicom Interactive',
            description: this?.page?.seo?.metaDescription || 'Khám phá bộ sưu tập các template trang web mẫu hiện đại, chuẩn SEO, và dễ dàng tùy chỉnh. Phù hợp cho mọi ngành nghề, giúp bạn thiết kế website nhanh chóng và hiệu quả.',
            image: this?.page?.seo?.metaImage?.url || 'https://dicom-interactive.com/en/assets/images/logo_small.webp',
            url: 'https://dicom-interactive.com/vn/collection/',
            width: this?.page?.seo?.metaImage?.width || 1200,
            height: this?.page?.seo?.metaImage?.height || 628,
            alt: this?.page?.seo?.metaImage?.alternativeText || this?.page?.seo?.metaDescription,
            type:  this?.page?.seo?.metaImage?.mime || 'image/webp'
          })
          this.collectionCacheService.setCache({
            page: page.data,
            categories: categories.data,
            collections: collections.data,
            tags: tags.data,
            total: collections.meta.pagination.total,
            limit: this.limit,
            start: this.start,
            postionScroll: this.postionScroll,
          });
          this.loading = false;
          if (!this.loading) {
            //load scroll postion from cache
            if (!isRefetch) {
              if (this.postionScroll > 0) {
                this.timerScrollWhenBack = setTimeout(() => {
                  window.scrollTo({
                    top: this.postionScroll,
                    behavior: 'smooth',
                  });
                }, 300);
              }
            } else {
              this.timerScrollWhenRefetch = setTimeout(() => {
                this.scrollToTopRowList();
              }, 300);
            }
          }
        },
        (error) => {
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
  }

  onCategoryChange(event: Event): void {
    const category = (event.target as HTMLInputElement).value;
    this.updateListSelection(event, this.selectedCategories, category);
  }

  onTagChange(event: Event): void {
    const tag = (event.target as HTMLInputElement).value;
    this.updateListSelection(event, this.selectedTags, tag);
  }

  onSearchChange(event: Event): void {
    this.searchQuery = (event.target as HTMLInputElement).value;

    this.filterSubject.next();
  }

  onClearSearch() {
    this.searchQuery = '';
    this.filterSubject.next();
  }

  onSortChange(event: Event): void {
    this.selectedSort = (event.target as HTMLInputElement)
      .value as SortCollectionType;
    this.filterSubject.next();
  }

  private updateListSelection(
    event: Event,
    selectionSet: Set<string>,
    item: string
  ): void {
    const isChecked = (event.target as HTMLInputElement)?.checked ?? false;

    if (isChecked) {
      selectionSet.add(item);
    } else {
      selectionSet.delete(item);
    }

    this.filterSubject.next();
  }

  private updateCollectionParams(): void {
    this.resetPaginationOffset();
    this.collectionParams = this.collectionParams
      .keys()
      .reduce((params, key) => {
        return Object.keys(this.defaultParams).includes(key)
          ? params
          : params.delete(key);
      }, this.collectionParams);

    ['search', 'categories', 'tags', 'sort'].forEach((key) => {
      if (key === 'search') {
        this.setCollectionSearchParam(this.searchQuery);
      }

      if (key === 'sort') {
        this.setCollectionSortParam(this.selectedSort);
      }

      if (['categories', 'tags'].includes(key)) {
        const selectionSet =
          key === 'categories' ? this.selectedCategories : this.selectedTags;

        Array.from(selectionSet).forEach((slug, index) => {
          this.setCollectionArrayParams(key, slug, index);
        });
      }
    });
  }

  private resetPaginationOffset(): void {
    this.start = 0;
    this.limit = 12;
    this.collectionParams = this.collectionParams
      .set('pagination[start]', this.start.toString())
      .set('pagination[limit]', this.limit.toString());
  }

  private setCollectionSortParam(sort: SortCollectionType) {
    this.collectionParams = this.collectionParams.set('sort[0]', sort);
  }

  private setCollectionSearchParam(search: string) {
    const param = 'filters[title][$containsi]';
    if (!search) {
      this.collectionParams = this.collectionParams.delete(param);
    } else {
      this.collectionParams = this.collectionParams.set(param, search.trim());
    }
    this.searchQuery = search
  }

  private setCollectionArrayParams(key: string, value: string, index: number) {
    this.collectionParams = this.collectionParams.set(
      `filters[${key}][slug][$in][${index}]`,
      value
    );
  }

  clearURLQueryParams(trigger = true): void {
    this.selectedCategories = new Set<string>();
    this.selectedTags = new Set<string>();
    this.searchQuery = '';
    this.selectedSort = 'createdAt:desc';
    if (trigger) {
      this.filterSubject.next();
    }
  }

  private updateURLQueryParams(): void {
    const queryParams = {
      categories: this.buildQuery(this.selectedCategories),
      tags: this.buildQuery(this.selectedTags),
      sort: this.buildQuery(this.selectedSort),
      search: this.buildQuery(this.searchQuery),
      refetch: null,
    };

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams,
      queryParamsHandling: 'merge',
    });
  }

  private buildQuery(params: Set<string> | string): string | null {
    if (params instanceof Set && params.size > 0) {
      return encodeURIComponent(Array.from(params).join(','));
    } else if (typeof params === 'string' && params !== '') {
      return encodeURIComponent(params);
    } else {
      return null;
    }
  }

  isFiltered(): Boolean {
    return Boolean(
      this.selectedCategories.size > 0 ||
        this.selectedTags.size > 0 ||
        this.searchQuery.trim() !== '' ||
        this.selectedSort !== 'createdAt:desc'
    );
  }

  getCollectionsByFilter(): void {
    this.filtering = true;
    this.collectionService.getCollections(this.collectionParams).subscribe(
      (result) => {
        this.collections = result.data;
        this.total = result.meta.pagination.total;

        this.collectionCacheService.setCache({
          ...this.collectionCacheService.getCache(),
          collections: this.collections,
          start: this.start,
          limit: this.limit,
          total: this.total,
        });

        this.scrollToTopRowList();
        this.filtering = false;
      },
      (error) => {
        this.filtering = false;
      },
      () => {
        this.filtering = false;
      }
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.filterSubject.complete();

    if (this.queryParamsSubscription) {
      this.queryParamsSubscription.unsubscribe();
    }
    if (this.timerScrollWhenBack) {
      clearTimeout(this.timerScrollWhenBack);
    }
    if (this.timerScrollWhenRefetch) {
      clearTimeout(this.timerScrollWhenRefetch);
    }
  }
}
