<!--Breadcrumb Area-->
<section class="breadcrumb-area banner-3">
  <div class="text-block">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 v-center">
          <div class="bread-inner">
            <div class="bread-menu wow fadeInUp" data-wow-delay=".2s">
              <ul>
                <li><a [routerLink]="['/']"> Trang Chủ</a></li>
                <li>
                  <a [routerLink]="['/services/web-development', '']"
                    >Phát Triển Web
                  </a>
                </li>
                <li>
                  <span>Web Mẫu </span>
                </li>
              </ul>
            </div>
            <div class="bread-title wow fadeInUp" data-wow-delay=".5s">
              <h2>Các Mẫu Website</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Breadcrumb Area-->
<!--Start collection-->
<section class="collection-page pad-tb">
  <div class="container collection-container">
    <div class="row collection-row">
      <div
        *ngIf="innerWidth >= 768"
        class="col-md-3 collection-col-left"
        #filterCol
      >
        <div class="collection-filter">
          <div
            class="input-group input-group-sm mb-3 position-relative search-group"
            *ngIf="categories.length > 0 || tags.length > 0"
          >
            <svg
              style="
                z-index: 4;
                position: absolute;
                top: 50%;
                left: 12px;
                transform: translateY(-50%);
              "
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              fill="currentColor"
              class="bi bi-search"
              viewBox="0 0 16 16"
            >
              <path
                d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"
              />
            </svg>
            <input
              style="border-radius: 100px"
              placeholder="Tìm kiếm"
              type="text"
              class="form-control"
              name="search"
              id="search"
              [(ngModel)]="searchQuery"
              (input)="onSearchChange($event)"
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
            />
            <span
              class="clear-search-icon"
              [ngClass]="{ show: searchQuery }"
              (click)="onClearSearch()"
              style="
                z-index: 4;
                position: absolute;
                top: 50%;
                right: 12px;
                transform: translateY(-50%);
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                fill="currentColor"
                class="bi bi-x-lg"
                viewBox="0 0 16 16"
              >
                <path
                  d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"
                />
              </svg>
            </span>
          </div>
          <div class="filter-list">
            <div class="filter-zone mt-3 type" *ngIf="categories.length > 0">
              <h4 class="filter-title underline underline-pink">Phân Loại</h4>

              <ul class="filter-control mt-3">
                <li *ngFor="let category of categories; trackBy: trackById">
                  <div class="custom-control custom-checkbox overwrite">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="{{ category?.slug }}"
                      name="{{ category?.slug }}"
                      value="{{ category?.slug }}"
                      (change)="onCategoryChange($event)"
                      [checked]="selectedCategories.has(category?.slug)"
                    />
                    <label
                      class="custom-control-label"
                      for="{{ category?.slug }}"
                      >{{ category?.title }}
                      <span>({{ category?.collections?.length }})</span></label
                    >
                  </div>
                </li>
              </ul>
            </div>
            <div class="filter-zone mt-3 tag" *ngIf="tags.length > 0">
              <h4 class="filter-title underline underline-pink">Tag</h4>
              <ul class="filter-control mt-3">
                <li *ngFor="let tag of tags; trackBy: trackById">
                  <div class="custom-control custom-checkbox overwrite">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="{{ tag?.slug }}"
                      name="{{ tag?.slug }}"
                      value="{{ tag?.slug }}"
                      (change)="onTagChange($event)"
                      [checked]="selectedTags.has(tag?.slug)"
                    />
                    <label class="custom-control-label" for="{{ tag?.slug }}"
                      >{{ tag?.title }}
                      <span>({{ tag?.collections?.length }})</span></label
                    >
                  </div>
                </li>
              </ul>
            </div>
            <div
              class="filter-zone mt-3 sort"
              *ngIf="categories.length > 0 || tags.length > 0"
            >
              <h4 class="filter-title underline underline-pink">Sắp xếp</h4>
              <ul class="filter-control mt-3">
                <li>
                  <div
                    class="custom-control custom-radio custom-control-inline overwrite"
                  >
                    <input
                      type="radio"
                      class="custom-control-input"
                      name="sort"
                      value="title:asc"
                      id="title:asc"
                      [(ngModel)]="selectedSort"
                      (change)="onSortChange($event)"
                    />
                    <label class="custom-control-label" for="title:asc"
                      >Tiêu đề từ A-Z</label
                    >
                  </div>
                </li>
                <li>
                  <div
                    class="custom-control custom-radio custom-control-inline overwrite"
                  >
                    <input
                      type="radio"
                      class="custom-control-input"
                      name="sort"
                      value="title:desc"
                      id="title:desc"
                      [(ngModel)]="selectedSort"
                      (change)="onSortChange($event)"
                    />
                    <label class="custom-control-label" for="title:desc"
                      >Tiêu đề từ Z-A</label
                    >
                  </div>
                </li>
                <li>
                  <div
                    class="custom-control custom-radio custom-control-inline overwrite"
                  >
                    <input
                      type="radio"
                      class="custom-control-input"
                      id="createdAt:desc"
                      name="sort"
                      value="createdAt:desc"
                      [(ngModel)]="selectedSort"
                      (change)="onSortChange($event)"
                    />
                    <label class="custom-control-label" for="createdAt:desc"
                      >Mới nhất - Cũ nhất</label
                    >
                  </div>
                </li>
                <li>
                  <div
                    class="custom-control custom-radio custom-control-inline overwrite"
                  >
                    <input
                      type="radio"
                      class="custom-control-input"
                      id="createdAt:asc"
                      name="sort"
                      value="createdAt:asc"
                      [(ngModel)]="selectedSort"
                      (change)="onSortChange($event)"
                    />
                    <label class="custom-control-label" for="createdAt:asc"
                      >Cũ nhất - Mới nhất</label
                    >
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="mt-3 d-none d-md-block">
            <h4 style="color: #ff1f8e">Không tìm thấy site bạn cần?</h4>
            <a
              href="/contact"
              type="button"
              class="btn-main bg-btn font-weight-bold lnk w-100 mt-2 shadow-0"
            >
              Liên hệ<span class="circle"></span>
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-9 collection-col-right" #rowCardList>
        <div class="common-heading text-left reduce-margin">
          <span style="text-align: left">MẪU WEBSITE ({{ total }})</span>
        </div>
        <h1 class="mt-2 heading-main reduce-margin">{{ page?.heading }}</h1>
        <p class="mt-2 reduce-margin">
          {{ page?.text }}
        </p>
        <div
          *ngIf="innerWidth < 768"
          class="align-items-center mt-3 filter-mobile w-100 reduce-margin d-flex"
        >
          <a
            href="#"
            class="btn-main btn-small btn-outline btn-filter-m d-inline-block"
            [ngClass]="{ active: isFiltered() }"
            data-toggle="modal"
            data-target="#modalfilter-full"
            ><i class="fas fa-sliders-h"></i> Bộ Lọc</a
          >
          <button
            *ngIf="isFiltered()"
            class="btn-clear-query ml-2"
            type="button"
            (click)="clearURLQueryParams()"
          >
            Đặt lại
          </button>
          <div class="popup-modalfull">
            <div class="modal fade" id="modalfilter-full">
              <div class="modal-dialog modal-dialog-scrollable">
                <div class="modal-content">
                  <button type="button" class="closes abt" data-dismiss="modal">
                    &times;
                  </button>
                  <div class="modal-body">
                    <div class="collection-filter">
                      <div
                        class="input-group input-group-sm mb-3 position-relative search-group"
                        *ngIf="categories.length > 0 || tags.length > 0"
                      >
                        <svg
                          style="
                            z-index: 4;
                            position: absolute;
                            top: 50%;
                            left: 12px;
                            transform: translateY(-50%);
                          "
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          fill="currentColor"
                          class="bi bi-search"
                          viewBox="0 0 16 16"
                        >
                          <path
                            d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"
                          />
                        </svg>
                        <input
                          style="border-radius: 100px"
                          placeholder="Tìm kiếm"
                          type="text"
                          class="form-control"
                          name="search"
                          id="search"
                          [(ngModel)]="searchQuery"
                          (input)="onSearchChange($event)"
                          aria-label="Small"
                          aria-describedby="inputGroup-sizing-sm"
                        />
                        <span
                          class="clear-search-icon"
                          [ngClass]="{ show: searchQuery }"
                          (click)="onClearSearch()"
                          style="
                            z-index: 4;
                            position: absolute;
                            top: 50%;
                            right: 12px;
                            transform: translateY(-50%);
                          "
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            fill="currentColor"
                            class="bi bi-x-lg"
                            viewBox="0 0 16 16"
                          >
                            <path
                              d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"
                            />
                          </svg>
                        </span>
                      </div>
                      <div class="filter-list">
                        <div
                          class="filter-zone mt-3 type"
                          *ngIf="categories.length > 0"
                        >
                          <h4 class="filter-title underline underline-pink">
                            Phân Loại
                          </h4>

                          <ul class="filter-control mt-3">
                            <li
                              *ngFor="
                                let category of categories;
                                trackBy: trackById
                              "
                            >
                              <div
                                class="custom-control custom-checkbox overwrite"
                              >
                                <input
                                  type="checkbox"
                                  class="custom-control-input"
                                  id="{{ category?.slug }}"
                                  name="{{ category?.slug }}"
                                  value="{{ category?.slug }}"
                                  (change)="onCategoryChange($event)"
                                  [checked]="
                                    selectedCategories.has(category?.slug)
                                  "
                                />
                                <label
                                  class="custom-control-label"
                                  for="{{ category?.slug }}"
                                  >{{ category?.title }}
                                  <span
                                    >({{ category?.collections?.length }})</span
                                  ></label
                                >
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div
                          class="filter-zone mt-3 tag"
                          *ngIf="tags.length > 0"
                        >
                          <h4 class="filter-title underline underline-pink">
                            Tag
                          </h4>
                          <ul class="filter-control mt-3">
                            <li *ngFor="let tag of tags; trackBy: trackById">
                              <div
                                class="custom-control custom-checkbox overwrite"
                              >
                                <input
                                  type="checkbox"
                                  class="custom-control-input"
                                  id="{{ tag?.slug }}"
                                  name="{{ tag?.slug }}"
                                  value="{{ tag?.slug }}"
                                  (change)="onTagChange($event)"
                                  [checked]="selectedTags.has(tag?.slug)"
                                />
                                <label
                                  class="custom-control-label"
                                  for="{{ tag?.slug }}"
                                  >{{ tag?.title }}
                                  <span
                                    >({{ tag?.collections?.length }})</span
                                  ></label
                                >
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div
                          class="filter-zone mt-3 sort"
                          *ngIf="categories.length > 0 || tags.length > 0"
                        >
                          <h4 class="filter-title underline underline-pink">
                            Sắp xếp
                          </h4>
                          <ul class="filter-control mt-3">
                            <li>
                              <div
                                class="custom-control custom-radio custom-control-inline overwrite"
                              >
                                <input
                                  type="radio"
                                  class="custom-control-input"
                                  name="sort"
                                  value="title:asc"
                                  id="title:asc"
                                  [(ngModel)]="selectedSort"
                                  (change)="onSortChange($event)"
                                />
                                <label
                                  class="custom-control-label"
                                  for="title:asc"
                                  >Tiêu đề từ A-Z</label
                                >
                              </div>
                            </li>
                            <li>
                              <div
                                class="custom-control custom-radio custom-control-inline overwrite"
                              >
                                <input
                                  type="radio"
                                  class="custom-control-input"
                                  name="sort"
                                  value="title:desc"
                                  id="title:desc"
                                  [(ngModel)]="selectedSort"
                                  (change)="onSortChange($event)"
                                />
                                <label
                                  class="custom-control-label"
                                  for="title:desc"
                                  >Tiêu đề từ Z-A</label
                                >
                              </div>
                            </li>
                            <li>
                              <div
                                class="custom-control custom-radio custom-control-inline overwrite"
                              >
                                <input
                                  type="radio"
                                  class="custom-control-input"
                                  id="createdAt:desc"
                                  name="sort"
                                  value="createdAt:desc"
                                  [(ngModel)]="selectedSort"
                                  (change)="onSortChange($event)"
                                />
                                <label
                                  class="custom-control-label"
                                  for="createdAt:desc"
                                  >Mới nhất - Cũ nhất</label
                                >
                              </div>
                            </li>
                            <li>
                              <div
                                class="custom-control custom-radio custom-control-inline overwrite"
                              >
                                <input
                                  type="radio"
                                  class="custom-control-input"
                                  id="createdAt:asc"
                                  name="sort"
                                  value="createdAt:asc"
                                  [(ngModel)]="selectedSort"
                                  (change)="onSortChange($event)"
                                />
                                <label
                                  class="custom-control-label"
                                  for="createdAt:asc"
                                  >Cũ nhất - Mới nhất</label
                                >
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="mt-3 d-none d-md-block">
                        <h4 style="color: #ff1f8e">
                          Không tìm thấy site bạn cần?
                        </h4>
                        <a
                          href="/contact"
                          type="button"
                          class="btn-main bg-btn font-weight-bold lnk w-100 mt-2 shadow-0"
                        >
                          Liên hệ<span class="circle"></span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      (click)="clearURLQueryParams()"
                    >
                      Đặt lại
                    </button>

                    <button
                      type="button"
                      [disabled]="filtering"
                      class="btn-main bg-btn2 btn-filter-results"
                      data-dismiss="modal"
                    >
                      <span
                        class="spinner-border spinner-border-sm ml-1"
                        style="transition: all 0.3s ease-out"
                        [style]="{
                          display: filtering ? 'inline-block' : 'none'
                        }"
                      ></span>
                      Xem ({{ total }}) kết quả
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="row infinite-scroll-row"
          infinite-scroll
          [infiniteScrollDistance]="distance"
          [infiniteScrollThrottle]="throttle"
          (scrolled)="onScrollLazyLoad()"
        >
          <div
            class="col-6 col-lg-4 website single-card-item"
            *ngFor="let template of collections; trackBy: trackById"
          >
            <div class="card-item isotope_item hover-scale">
              <div class="item-image">
                <a
                  [routerLink]="
                    template?.layouts?.length > 0
                      ? [
                          '/collection',
                          template?.slug,
                          template?.layouts[0]?.slug
                        ]
                      : ['/collection', template?.slug]
                  "
                >
                  <picture>
                    <source
                    media="(max-width: 400px)"
                    srcset="{{template?.thumbnail?.url}}"
                  />
                    <source
                      media="(max-width: 1024px)"
                      srcset="{{template?.thumbnail?.url}}"
                    />
                    <img
                      alt="{{ template?.thumbnail?.alternativeText }}"
                      width="{{
                          template?.thumbnail?.width
                      }}"
                      height="{{
                          template?.thumbnail?.height
                      }}"
                      class="img-fluid"
                      loading="lazy"
                      src="{{
                          template?.thumbnail?.url
                      }}"
                    />
                  </picture>
                </a>
              </div>
              <div class="item-info">
                <h4 class="line-clamp line-clamp-2">
                  <a
                    [routerLink]="
                      template?.layouts?.length > 0
                        ? [
                            '/collection',
                            template?.slug,
                            template?.layouts[0]?.slug
                          ]
                        : ['/collection', template?.slug]
                    "
                  >
                    {{ template.title }}
                  </a>
                </h4>
                <p style="color: #ff1f8e">
                  Từ {{ template.basic_price | vndCurrency }}
                </p>
              </div>
            </div>
          </div>
          <div
            class="text-center col-12 mt-3 mb-3"
            *ngIf="!loading && !this.collections.length"
          >
            Không tìm thấy kết quả !
          </div>
          <a
            *ngIf="!loading && !this.collections.length"
            href="/collection/?sort=createdAt:desc"
            class="d-inline-block mx-auto"
            >Làm Mới</a>
          <!-- Loader -->
          <div
            class="col-12 d-flex justify-content-center align-items-center mt-5 loading-container"
            [ngClass]="{ active: loading }"
          >
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
        <div class="mt-5 d-block d-md-none text-center">
          <h4 style="color: #ff1f8e">Không tìm thấy site bạn cần?</h4>
          <button
            type="button"
            class="btn-main bg-btn font-weight-bold lnk w-100 mt-2 shadow-0"
          >
            Liên hệ<span class="circle"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
