import {
  Component,
  OnInit,
  OnDestroy,
  HostListener,
  ViewChild,
  ElementRef,
  PLATFORM_ID,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Collection, GroupOption, GroupOptionItem } from '../types/collection';
import { CollectionService } from '../services/collection.service';
import { DomSanitizer } from '@angular/platform-browser';
import { CollectionCacheService } from '../services/collection-cache.service';
import { of, Subject } from 'rxjs';
import { catchError, finalize, takeUntil } from 'rxjs/operators';
import { DecimalPipe, isPlatformBrowser } from '@angular/common';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Title, Meta } from '@angular/platform-browser';
import { HttpParams } from '@angular/common/http';
import { MetaDataService, ScriptService } from '../services';
import { PreviousUrlService } from '../services/previous-url.service';
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-collection-single',
  templateUrl: './collection-single.component.html',
  styleUrls: ['./collection-single.component.css'],
  providers: [DecimalPipe],
})
export class CollectionSingleComponent
  extends MetaDataService
  implements OnInit, OnDestroy
{
  isServer = !isPlatformBrowser(this.platformId);
  isLoad = false;
  previousUrl: string | null = null;
  previousQueryParams: { [key: string]: string } | null = null;
  finalForm: FormGroup;
  submitted: boolean = false;
  submmitting: boolean = false;
  submittedSuccess: boolean = false;
  submittedError: boolean = false;
  loading: boolean = false;
  showBottomBar: boolean = false;
  collection: Collection = this.getDefaultCollection();
  relatedCollections: Collection[] = [];
  currentLayoutShow: Collection['layouts'][0] = this.getDefaultLayout();
  groupOptions: GroupOption[] = this.groupOptionsClientData(
    this.collectionCacheService.page.group_options
  );
  private destroy$ = new Subject<void>();

  @ViewChild('modalBody') modalBody: ElementRef | undefined;
  @ViewChild('formBody') formBody: ElementRef | undefined;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private collectionService: CollectionService,
    private sanitizer: DomSanitizer,
    private collectionCacheService: CollectionCacheService,
    private previousUrlService: PreviousUrlService,
    private scriptService: ScriptService,
    title: Title,
    meta: Meta
  ) {
    super(title, meta);
    this.previousUrl = this.previousUrlService.getPreviousUrl();
    this.previousQueryParams = this.previousUrlService.getPreviousQueryParams();
    this.finalForm = this.fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phone: [
        '',
        [Validators.required, Validators.pattern(/^(0[1-9]{1})\d{8,9}$/)],
      ],
      file: [null, [this.fileValidator]],
      // file: [null, [Validators.required, this.fileValidator]],
      message: [''],
    });
  }

  onBackdropClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    if (target && target.classList.contains('modal')) {
      this.resetFormSubmitState();
    }
  }

  fileValidator(control: FormControl) {
    const file = control.value;
    if (!file) return null;
    const allowedMimeTypes = ['application/pdf'];
    const maxSize = 10 * 1000 * 1000;

    if (file && allowedMimeTypes.indexOf(file.type) === -1) {
      return { invalidFileType: true };
    }

    if (file.size > maxSize) {
      return { invalidFileSize: true };
    }

    return null;
  }

  onFileChange(event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.finalForm.patchValue({ file });
    this.finalForm.get('file').updateValueAndValidity();
  }

   ngOnInit(): void {
    this.route.paramMap.subscribe( (params) => {
      const slug = params.get('slug');
      const layoutSlug = params.get('layout');
      this.loadCollectionData(slug, layoutSlug);
    });

    if (!this.isServer) {
      this.scriptService.load(this.scriptService.INIT).then(() => {
        this.isLoad = true;
      });
      this.initializeRouteListener();
      this.togglePaddingCopyRight('add');
    }
  }

  get getFormState() {
    return this.finalForm.controls;
  }

  convertPriceToVND(price: number) {
    const decimalPipe = new DecimalPipe('vi-VN');
    return decimalPipe.transform(price, '1.0-0') + ' VND';
  }

  onPhoneInput(event: Event) {
    const input = event.target as HTMLInputElement;
    let value = input.value;

    value = value.replace(/\D/g, '');

    this.finalForm.patchValue({ phone: value });
  }

  async onSubmit() {
    this.submitted = true;
    if (this.finalForm.invalid) {
      if (this.formBody) {
        this.formBody.nativeElement.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
      return;
    }
    const file = this.finalForm.value.file;
    const payload = {
      idCollection: this.collection.uuid,
      titleCollection: this.collection.title,
      basicPriceCollection: this.convertPriceToVND(this.collection.basic_price),
      endPriceCollection: this.convertPriceToVND(this.collection.price_end),
      quantityCollection: 1,
      contactInformation: {
        ...this.finalForm.value,
        file: file ? await this.fileToBase64(file) : null,
        filename: file?.name,
      },
      extraOptions: this.getSelectedOptions().map((option) => ({
        ...option,
        basic_price: this.convertPriceToVND(option.basic_price),
        price_end: this.convertPriceToVND(option.price_end),
      })),
    };

    this.submmitting = true;
    this.submittedSuccess = false;
    this.submittedError = false;

    this.collectionService
      .sendEmailToSalesTeam(payload)
      .pipe(
        catchError((error) => {
          console.error('Error occurred:', error);
          this.submittedError = true;
          this.submittedSuccess = false;
          return of(null);
        }),

        finalize(() => {
          this.submmitting = false;
          this.submitted = false;
          if (this.modalBody) {
            this.modalBody.nativeElement.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
          }
        })
      )
      .subscribe((res) => {
        if (res) {
          this.resetFormValues();
          this.submittedSuccess = true;
        } else {
        }
      });
  }

  resetFormValues() {
    this.finalForm.reset();
    Object.keys(this.finalForm.controls).forEach((field) => {
      const control = this.finalForm.get(field);
      control?.markAsPristine();
      control?.markAsUntouched();
    });
    const inputFilePDFContainer = document.querySelector('.input-file-pdf');
    const inputFile = inputFilePDFContainer.querySelector(
      'input#file'
    ) as HTMLInputElement;
    if (inputFile) {
      inputFile.value = '';
    }
    //reset option selected
    if (this.groupOptions.some((g) => g.options.some((o) => o.selected))) {
      this.groupOptions = JSON.parse(JSON.stringify(this.groupOptions)).map(
        (g) => ({
          ...g,
          options: g.options.map((o) => ({ ...o, selected: false })),
        })
      );
    }
    //reset price end to original
    if (this.collection.price_end !== this.collection.basic_price) {
      this.collection.price_end = this.collection.basic_price;
    }
  }

  resetFormSubmitState() {
    this.submitted = false;
    this.submittedError = false;
    this.submittedSuccess = false;
    this.submmitting = false;
  }

  async fileToBase64(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.toString()?.split(',')[1]);
      reader.onerror = (error) => reject(error);
    });
  }

  getSelectedOptions() {
    return this.groupOptions.reduce<GroupOptionItem[]>((prVal, cuVal) => {
      const selected = cuVal.options.filter((item) => item.selected);
      return [...prVal, ...selected];
    }, []);
  }

  onChangeOptionItemQuantity(idGroup, idOption, input: number) {
    const quantity = Math.max(1, Math.min(input, 999));

    const group = this.groupOptions.find((g) => g.id === idGroup);
    if (!group) return;
    const option = group.options.find((o) => o.id === idOption);
    if (!option) return;

    option.quantity = quantity;
    option.price_end = quantity * option.basic_price;
    this.groupOptions = [...this.groupOptions];

    const price_end = this.groupOptions.reduce((total, group) => {
      const groupPrice = group.options
        .filter((o) => o.selected)
        .reduce((sum, option) => sum + option.price_end, 0);

      return total + groupPrice;
    }, this.collection.basic_price);

    this.collection = { ...this.collection, price_end };
  }

  onInputQuantityChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    const value = Math.min(Math.max(parseInt(input.value, 10) || 1, 1), 999);
    input.value = value.toString();
  }

  // Helper to sanitize raw HTML
  private bypassSecurityTrustHtml(rawHtml: string) {
    return this.sanitizer.bypassSecurityTrustHtml(rawHtml);
  }

  controlQuantityForOption(
    type: 'increment' | 'decrement',
    idGroup: number,
    idOption: number
  ): void {
    if (!type) return;
    const group = this.groupOptions.find((g) => g.id === idGroup);
    if (!group) return;
    const option = group.options.find((o) => o.id === idOption);
    if (!option) return;
    const newQuantity =
      type === 'increment'
        ? Math.min(option.quantity + 1, 999)
        : Math.max(option.quantity - 1, 1);
    option.quantity = newQuantity;
    option.price_end = newQuantity * option.basic_price;
    this.groupOptions = [...this.groupOptions];

    const price_end = this.getTotalPrice();
    this.collection = { ...this.collection, price_end };
  }

  private getTotalPrice(): number {
    return this.groupOptions.reduce((total, group) => {
      const groupPrice = group.options
        .filter((o) => o.selected)
        .reduce((sum, option) => sum + option.price_end, 0);

      return total + groupPrice;
    }, this.collection.basic_price);
  }

  togglePaddingCopyRight(action: 'remove' | 'add') {
    if (!this.isServer) {
      const footerRow3 = this.document.querySelector<HTMLDivElement>(
        'footer .footer-row3'
      );
      if (footerRow3) {
        if (action === 'add') {
          footerRow3.style.paddingBottom = '80px';
        } else {
          footerRow3.removeAttribute('style');
        }
      }
    }
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(): void {
    if (!this.isServer) {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 1000) {
        this.showBottomBar = true;
      } else {
        this.showBottomBar = false;
      }
    }
  }

  groupOptionsClientData(group: GroupOption[]) {
    if (group.length === 0) return [];
    return group.map((g) => ({
      ...g,
      options: g.options.map((o) => ({
        ...o,
        quantity: 1,
        selected: false,
        price_end: o.basic_price,
        showDescription: Boolean(o.description?.trim()),
        description: this.bypassSecurityTrustHtml(o.description) as string,
      })),
    }));
  }

  onSelectOption(idGroup: number, idOption: number, event: Event) {
    const group = this.groupOptions.find((g) => g.id === idGroup);
    if (!group) return;
    const option = group.options.find((o) => o.id === idOption);
    if (!option) return;
    const checked = (event.target as HTMLInputElement).checked;
    option.selected = checked;
    this.groupOptions = [...this.groupOptions];
    const price_end = this.getTotalPrice();
    this.collection = { ...this.collection, price_end };
  }

  // Scroll to section on page
  scrollToSection(id: string): void {
    const targetDiv = document.getElementById(id);
    if (targetDiv) {
      window.scrollTo({
        behavior: 'smooth',
        top: targetDiv.offsetTop - 100,
      });
    }
  }

  private initializeRouteListener() {
    this.route.paramMap.subscribe((params) => {
      const layoutSlug = params.get('layout');
      this.onLayoutChange(layoutSlug);
    });
  }

  private loadCollectionData(slug, layoutSlug) {
      this.fetchCollectionDetail(slug, layoutSlug);
      this.fetchGroupOptions();
  }

  // Fetch collection details based on the slug and layout
  private fetchCollectionDetail(slug: string, layoutSlug: string): void {
    this.loading = true;

    this.collectionService
      .getCollectionBySlug(slug)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (result) => {
          if (result.data.length) {
            const {
              basic_price,
              categories,
              description,
              layouts,
              short_description,
              slug,
              tags,
              title,
              uuid,
              seo,
              thumbnail
            } = result.data[0];

            //initial Seo title, desc, meta ...
            this.collection = {
              ...result.data[0],
              price_end: basic_price,
              showDescription: Boolean(description?.trim()),
              showSortDescription: Boolean(short_description?.trim()),
              description: this.bypassSecurityTrustHtml(description) as string,
              short_description: this.bypassSecurityTrustHtml(
                short_description
              ) as string,
            };

            this.setMetaTags({
              description: seo?.metaDescription || short_description,
              alt: seo?.metaImage?.alternativeText || seo?.metaDescription || short_description,
              image: seo?.metaImage?.url || thumbnail?.url || layouts?.[0]?.thumbnail.url || '',
              width: seo?.metaImage?.width || thumbnail?.width || layouts?.[0]?.thumbnail.width || 1200,
              height: seo?.metaImage?.height || thumbnail?.height || layouts?.[0]?.thumbnail.height || 628,
              type: seo?.metaImage?.mime || thumbnail?.mime || layouts?.[0]?.thumbnail.mime || 'image/webp',
              publisher: 'Dicom Interactive',
              author: 'Dicom Interactive',
              title:
                seo?.metaTitle || `${title} | Collection | Dicom Interactive`,
              url: `https://dicom-interactive.com/vn/collection/${slug}/${layoutSlug}`,
              siteName: `${title} | Collection | Dicom Interactive`,
              twitterCreator: '@dicomInteractive',
              keywords: [title, slug, uuid]
                .concat(tags.map((item) => item.name))
                .concat(categories.map((item) => item.name)),
              canonical: `https://www.dicom-interactive.com/vn/collection/${slug}/${layoutSlug}`,
            });

            this.setLayoutBySlug(layoutSlug);

            const curCategories = result.data[0].categories.map(
              (item) => item.id
            );
            const curTags = result.data[0].tags.map((item) => item.id);
            const curUuid = result.data[0].uuid;
            if (curCategories.length > 0 || curTags.length > 0) {
              this.loadRelatedCollections(curCategories, curTags, curUuid);
            }
          }
        },
        (error) => console.error('Error fetching collection details', error),
        () => (this.loading = false)
      );
  }

  private loadRelatedCollections(
    categories: number[],
    tags: number[],
    uuid: string
  ) {
    let relatedCollectionsParams = new HttpParams()
      .set('populate', 'thumbnail')
      .set('sort', 'createdAt:desc')
      .set('filters[uuid][$ne]', uuid)
      .set('populate[0]', 'categories')
      .set('populate[1]', 'tags')
      .set('populate[2]', 'layouts')
      .set('pagination[page]', '1')
      .set('pagination[pageSize]', '6');

    categories.forEach(
      (id, index) =>
        (relatedCollectionsParams = relatedCollectionsParams.set(
          `filters[$or][${index}][categories][id][$in]`,
          id.toString()
        ))
    );

    tags.forEach(
      (id, index) =>
        (relatedCollectionsParams = relatedCollectionsParams.set(
          `filters[$or][${categories.length + index}][tags][id][$in]`,
          id.toString()
        ))
    );

    this.collectionService
      .getCollections(relatedCollectionsParams)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response) => {
          this.relatedCollections = response.data
        },
        (error) => console.error('Failed to load related collections:', error)
      );
  }

  // Set the current layout based on the layoutSlug
  private setLayoutBySlug(layoutSlug: string): void {
    const layout = this.collection.layouts.find((l) => l.slug === layoutSlug);
    this.currentLayoutShow = layout || this.collection.layouts[0];
  }

  // Fetch group options if not already available
  private fetchGroupOptions() {
    if (this.groupOptions.length > 0) return; // Prevent redundant API call
    this.loading = true;
    this.collectionService
      .getCollectionPage()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (result) => {
          this.groupOptions = this.groupOptionsClientData(
            result.data.group_options
          );
        },
        (error) => console.error('Error fetching group options', error),
        () => (this.loading = false)
      );
  }

  trackByFn(index: number, item: any): any {
    return item.id;
  }

  // Handle layout change on route param change
  private onLayoutChange(slugLayout: string): void {
    if (this.currentLayoutShow.slug === slugLayout) return; // Avoid unnecessary update
    this.setLayoutBySlug(slugLayout);
  }

  // Default collection structure
  private getDefaultCollection(): Collection {
    return {
      thumbnail: null,
      seo: {
        id: null,
        metaDescription: '',
        metaImage: null,
        metaTitle: '',
      },
      uuid: '',
      showDescription: false,
      showSortDescription: false,
      publishedAt: '',
      slug: '',
      short_description: '',
      basic_price: 0,
      documentId: '',
      id: 0,
      layouts: [],
      title: '',
      createdAt: '',
      description: '',
      updatedAt: '',
      categories: [],
      tags: [],
      price_end: 0,
    };
  }

  // Default layout structure
  private getDefaultLayout(): Collection['layouts'][0] {
    return {
      title: '',
      full_image: null,
      id: 0,
      slug: '',
      url_demo: '',
      thumbnail: null,
    };
  }

  ngOnDestroy(): void {
    if (!this.isServer) {
      this.togglePaddingCopyRight('remove');
    }
    this.destroy$.next();
    this.destroy$.complete();
  }
}
