
	<!--Breadcrumb Area-->
	<section class="breadcrumb-area banner-1" data-background="assets/images/banner/9.webp">
		<div class="text-block">
			<div class="container">
				<div class="row">
					<div class="col-lg-12 v-center">
						<div class="bread-inner">
							<div class="bread-menu wow fadeInUp" data-wow-delay=".2s">
								<ul>
									<li><a [routerLink]="['/']" i18n>Home</a></li>
									<li><span i18n>About Us</span></li>
								</ul>
							</div>
							<div class="bread-title wow fadeInUp" data-wow-delay=".5s">
								<h1 i18n>About Company</h1>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!--End Breadcrumb Area-->
	<!--Start About-->
	<section class="about-agency pad-tb block-1">
		<div class="container">
			<div class="row">
				<div class="col-lg-6 v-center">
					<div class="about-image">
						<img src="assets/images/about/company-about.webp" alt="About Us" class="img-fluid" />
					</div>
				</div>
				<div class="col-lg-6">
					<div class="common-heading text-l ">
						<span i18n>About Us</span>
						<h2>Dicom Interactive</h2>
						<p i18n>DICOM Interactive is a fast-paced growing Vietnamese and Melbourne (Australia) software
							company focused on hi-tech custom development services. We have been providing software
							products to startups and software production development companies in various ranges of
							scale. We are offering custom software development, web / mobile app development & dedicated
							software teams to software production companies and startups. </p>
						<p i18n>We help our clients to build strong digital base and to be ready for a full-speed growth &
							scalable development in the future. We are not looking at the current status of our clients’
							businesses but at their potential growth.</p>
					</div>
					<div class="row in-stats small about-statistics">
						<div class="col-lg-4 col-sm-4">
							<div class="statistics">
								<div class="statnumb counter-number">
									<span class="counter">40</span><span>+</span>
									<p i18n>Happy Clients</p>
								</div>
							</div>
						</div>
						<div class="col-lg-4 col-sm-4">
							<div class="statistics">
								<div class="statnumb">
									<span class="counter">50</span><span>k</span>
									<p i18n>Hours Worked</p>
								</div>
							</div>
						</div>
						<div class="col-lg-4 col-sm-4">
							<div class="statistics mb0">
								<div class="statnumb counter-number">
									<span class="counter">60</span><span>+</span>
									<p i18n>Projects Done</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!--End About-->
	<!--Start why-choose-->
<section class="why-choose pad-tb">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div class="common-heading">
          <span i18n>We Are Awesome</span>
          <h2 class="mb30" i18n>Why Choose Us</h2>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-4 col-sm-6 mt30 wow fadeIn" data-wow-delay="0.2s">
        <div class="s-block wide-sblock hfull">
          <div class="s-card-icon"><img src="assets/images/icons/ontime.svg" alt="service" class="img-fluid">
          </div>
          <div class="s-block-content">
            <h4 i18n>On Time Delivery</h4>
            <p i18n>We value your precious time, so we try our best to deliver the good on time and within
              budget.</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 mt30 wow fadeIn" data-wow-delay=".4s">
        <div class="s-block wide-sblock hfull">
          <div class="s-card-icon"><img src="assets/images/icons/quality.svg" alt="service" class="img-fluid">
          </div>
          <div class="s-block-content">
            <h4 i18n>Top Quality</h4>
            <p i18n>Our testing experts efficiently collaborate with you to achieve the best product quality.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 mt30 wow fadeIn" data-wow-delay=".6s">
        <div class="s-block wide-sblock hfull">
          <div class="s-card-icon"><img src="assets/images/icons/accountability.svg" alt="service" class="img-fluid">
          </div>
          <div class="s-block-content">
            <h4 i18n>Accountability</h4>
            <p i18n>We fully grasp the importance of listening and understanding your business and
              objectives.</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 mt30  wow fadeIn" data-wow-delay="0.8s">
        <div class="s-block wide-sblock hfull">
          <div class="s-card-icon"><img src="assets/images/icons/price.svg" alt="service" class="img-fluid">
          </div>
          <div class="s-block-content">
            <h4 i18n>Reasonable Price</h4>
            <p i18n>We optimize our operation cost so that we could provide you services with bargain price.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 mt30 wow fadeIn" data-wow-delay="1.0s">
        <div class="s-block wide-sblock hfull">
          <div class="s-card-icon"><img src="assets/images/icons/process.svg" alt="service" class="img-fluid">
          </div>
          <div class="s-block-content">
            <h4 i18n>Professional Process</h4>
            <p i18n>We standardize Agile process to improve both team productivity and project quality.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End why-choose-->
