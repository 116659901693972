<!--Breadcrumb Area-->
<section class="breadcrumb-area banner-5">
	<div class="text-block">
		<div class="container">
			<div class="row">
				<div class="col-lg-12 v-center">
					<div class="bread-inner">
						<div class="bread-menu wow fadeInUp" data-wow-delay=".2s">
              <ul>
                <li><a [routerLink]="['/']" i18n>Home</a></li>
                <li><a [routerLink]="['/portfolio' , '']" i18n>Portfolio</a></li>
                <li><span i18n>Project Details</span></li>
              </ul>
						</div>
						<div class="bread-title wow fadeInUp" data-wow-delay=".5s">
							<h1 i18n>GoEn E-Learning System</h1>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!--End Breadcrumb Area-->
<!--Start Case Study About-->
<section class="case-study pad-tb">
	<div class="container">
		<div class="row">
			<div class="col-lg-6">
				<div class="image-block upset">
					<img src="assets/images/case-study/goen-intro.webp" alt="case study" class="img-fluid no-shadow" />
				</div>
			</div>
			<div class="col-lg-6 block-1 v-center">
				<div class="common-heading inner-heading text-l pl25">
					<span i18n>About The Project</span>
					<h2 i18n>On-The-Go E-Learning Web Application</h2>
					<p i18n>An eLearning service with various user roles and complex structure similar to Udemy which
						allows users either to join the platform as individual or even organization.</p>
					<p i18n>Users who join the platform as learner will have chance to not only benefit from the
						instructive videos but also be able to interact with instructor via chatting system if they
						purchase the premium courses.</p>
					<p i18n>The system also has its own admin section which allows GoEn management staffs to manage
						courses, users, news, testimonials and everything.</p>
				</div>
				<div class="project-platform mt60 pl25">
					<div class="project-platform-used -shadow"><a href="https://goen.dicom-interactive.com/" target="blank">
							<img src="assets/images/icons/www.webp" alt="visit" /></a>
					</div>
					<div><strong> ⤺ </strong><span style="font-size: smaller;" i18n>Click here to visit the site</span></div>
				</div>
				<!--div class="project-platform mt60 pl25">
						<div class="project-platform-used -shadow"><a href="#"><img src="assets/images/icons/android.svg"
									alt="android"></a></div>
						<div class="project-platform-used -shadow"><a href="#"><img src="assets/images/icons/apple.svg"
									alt="apple"></a></div>
					</div-->
			</div>
		</div>
	</div>
</section>
<!--End Case Study About-->
<section class="case-study pad-tb bg-gradient6 deep-dark">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-lg-10">
				<div class="common-heading">
					<span i18n>Service Features</span>
					<h2 class="mb30" i18n>As per client's need, we have developed a mobile-friendly web service.
					</h2>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-6">
				<h4 class="mt20 mb10" i18n>Online Course</h4>
				<ul class="list-style-">
					<li i18n>A course contains videos streaming from AWS, collection of quiz and attachment as
						demonstration for some presentation.
					</li>
					<li i18n>There are 2 types of course: Standard and Premium. The only difference between them is that
						user who enrolls premium course will be able to interact with tutor of that course.</li>
				</ul>
			</div>
			<div class="col-lg-6">
				<h4 class="mt20 mb10" i18n>Online Payment</h4>
				<ul class="list-style-">
					<li i18n>User will have 3 options to buy a course: Visa | Mastercard, Local bank online transfer, and
						off-system payment.</li>
					<li i18n>Depends on location of user, we will determine which payment gateway to use: Alepay (Ngan
						Luong) or Stripe.
					</li>
				</ul>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-6">
				<h4 class="mt20 mb10" i18n>Complex Structure</h4>
				<ul class="list-style-">
					<li i18n>There are 6 different roles which own different permissions.</li>
					<li i18n>Super Admin have the ultimate permissions such as creating sub-company and managing user
						across sub-companies.
					</li>
					<li i18n>The other roles are Goen admin, sub-company admin, business user, individual user and tutor.
					</li>
				</ul>
			</div>
			<div class="col-lg-6">
				<h4 class="mt20 mb10">Other Features</h4>
				<ul class="list-style-">
					<li i18n>Besides videos, any course might content a set of quiz that has strong relevance to it. The
						quiz type could be multiple-choice or open question.
					</li>
					<li i18n>System also allows Goen admin to create course combo, which is a group of courses but with
						lower total price.</li>
				</ul>
			</div>
		</div>
	</div>
</section>
<!--Start Project Challenges-->
<section class="case-study pad-tb">
	<div class="container">
		<div class="row">
			<div class="col-lg-6 col-sm-6">
				<h2 class="mt20 mb20" i18n>Challenges</h2>
				<p i18n>Goen comes with incomplete structure of the service they expect on mind. We need to spend
					weeks to consult, analyze and prepare a contract (mockup) to make sure we are on the same
					boat. Before approaching Dicom Interactive, there are 2 other Japanese companies who failed to
					finish this
					web application.<br><br></p>
				<p i18n>Goen is a typical Japanese client. They are always looking for something called "pixel-perfect",
					very design-oriented and pay close attention to every detail.<br><br></p>
				<p i18n>Goen also wants to roll this out to global which requires us to research and
					determine
					suitable 3rd services in terms of server, payment gateway, supported languages, time zone... and
					also a programming framework that could be easily scaled up.</p>
			</div>
			<div class="col-lg-6 col-sm-6">
				<div class="project-view m-mt30">
					<img src="assets/images/case-study/goen-view.webp" alt="case study" class="img-fluid" />
				</div>
			</div>
		</div>
	</div>
</section>
<!--End Project Challenges-->
<!--Start Solutions-->
<section class="case-study pad-tb bg-gradient5 deep-dark">
	<div class="container">
		<div class="row">
			<div class="col-lg-12 block-1 v-center">
				<div class="common-heading inner-heading text-l pr25">
					<span i18n>Solutions</span>
					<h2 i18n>How We Work</h2>
					<h4 i18n>We decide to go with ReactJS for the Front-end, NodeJS for the Back-end, Redis and MongoDB
						as the database, Stripe and Alepay as Payment Gateway and AWS as our web service.</h4><br/>
					<p i18n>We spend 2 weeks to listen to Goen and try to understand what their desire and
						expectation are. Simultaneously, we analyze and sketch out their ideas and we work together
						until that sketch becomes our final interactive prototype.
					</p>
					<p i18n>Right after we finalize the wireframe and get the contract, our designers come in and
						collaborate on the vision then created 2 different look-and-feel homepages so that Goen could
						pick
						one. We then adapt that style into all other pages and on each page finish, we check and
						fine-tune it with the client.
					</p>
					<p i18n>During the development stage, we define milestones that we deliver shippable package to our
						client so we could make sure we are going on the right path.</p>
				</div>
				<div class="card-nx row">
					<div class="col-lg-3 col-md-6 col-sm-6 col-6 mt30">
						<div class="icon-card- hfull">
							<img src="assets/images/icons/branding.svg" alt="case study" class="img-fluid">
							<h3>Mobile App Development</h3>
						</div>
					</div>
					<div class="col-lg-3 col-md-6 col-sm-6 col-6 mt30">
						<div class="icon-card- hfull">
							<img src="assets/images/icons/back.svg" alt="case study" class="img-fluid">
							<h3>Back-end/API Development</h3>
						</div>
					</div>
					<div class="col-lg-3 col-md-6 col-sm-6 col-6 mt30">
						<div class="icon-card- hfull">
							<img src="assets/images/icons/seo.svg" alt="case study" class="img-fluid">
							<h3>Payment Gateway Integration</h3>
						</div>
					</div>
					<div class="col-lg-3 col-md-6 col-sm-6 col-6 mt30">
						<div class="icon-card- hfull">
							<img src="assets/images/icons/logo-and-branding.svg" alt="case study" class="img-fluid">
							<h3>UI/UX Graphic Design</h3>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!--End Solutions-->
<div class="case-study pad-tb">
	<div class="container">
		<div class="row">
			<div class="col-lg-12">
				<div class="porto-slide owl-carousel">
					<div class="screen-splits"> <img src="assets/images/case-study/goen-web-1.webp" alt="case study"
							class="img-fluid" /></div>
					<div class="screen-splits"> <img src="assets/images/case-study/goen-web-2.webp" alt="case study"
							class="img-fluid" /></div>
					<div class="screen-splits"> <img src="assets/images/case-study/goen-web-3.webp" alt="case study"
							class="img-fluid" /></div>
					<div class="screen-splits"> <img src="assets/images/case-study/goen-web-4.webp" alt="case study"
							class="img-fluid" /></div>
				</div>
			</div>
		</div>
	</div>
</div>
<!--Start Navigate-->
<section class="testinomial-section pad-tb">
	<div class="container">
		<div class="row">
			<div class="col-6 v-center">
				<a [routerLink]="['/', 'portfolio', previousLink]" class="nav_link" i18n>&laquo; Previous</a>
			</div>
			<div class="col-6 v-center">
				<a [routerLink]="['/', 'portfolio', nextLink]" class="nav_link next_nav_link" i18n>Next &raquo;</a>
			</div>
		</div>
	</div>
</section>
<!--End Navigate-->

<!--Start Testinomial-->
<app-testinomial-component></app-testinomial-component>
<!--End Testinomial-->
