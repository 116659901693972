<!--Breadcrumb Area-->
<section class="breadcrumb-area" data-background="assets/images/banner/4.webp">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 v-center">
        <div class="bread-inner">
          <div class="bread-menu wow fadeInUp" data-wow-delay=".2s">
            <ul>
              <li><a [routerLink]="['/']" i18n>Home</a></li>
              <li><span i18n>Graphic Design Services</span></li>
            </ul>
          </div>
          <div class="bread-title wow fadeInUp" data-wow-delay=".5s">
            <h1 i18n>Graphic Design Services</h1>
          </div>
        </div>
      </div>
      <div class="col-lg-7">
        <div class="bread-titlev2">
          <p class="mt20 wow fadeInUp" data-wow-delay=".4s" i18n>Your business - Your brand - Your identity</p>
          <a [routerLink]="['/contact']" class="btn-main bg-btn2 lnk mt20 wow zoomInDown" (click)="onContactGra()" data-wow-delay=".6s" i18n>Get Quote
            <i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></a>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- <div class="statistics-wrap bg-gradient5">
    <div class="container">
      <div class="row small t-ctr mt0">
        <div class="col-lg-3 col-sm-6">
          <div class="statistics">
            <div class="statistics-img">
              <img src="assets/images/icons/deal.svg" alt="happy" class="img-fluid">
            </div>
            <div class="statnumb">
              <span class="counter">40</span><span>+</span>
              <p>Happy Clients</p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6">
          <div class="statistics">
            <div class="statistics-img">
              <img src="assets/images/icons/computers.svg" alt="project" class="img-fluid">
            </div>
            <div class="statnumb counter-number">
              <span class="counter">60</span><span>+</span>
              <p>Projects Done</p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6">
          <div class="statistics">
            <div class="statistics-img">
              <img src="assets/images/icons/worker.svg" alt="work" class="img-fluid">
            </div>
            <div class="statnumb">
              <span class="counter">50</span><span>k</span>
              <p>Hours Worked</p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6">
          <div class="statistics mb0">
            <div class="statistics-img">
              <img src="assets/images/icons/customer-service.svg" alt="support" class="img-fluid">
            </div>
            <div class="statnumb">
              <span class="counter">24</span><span>/</span><span class="counter">7</span>
              <p>Support Available</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
<app-services-counter></app-services-counter>
<!--End Hero-->
<!--Start About-->
<section class="service pad-tb bg-gradient5">
  <div class="container">
    <div class="row">
      <div class="col-lg-4">
        <div class="single-image bg-shape-dez mt0 wow fadeIn">
          <img src="assets/images/service/graphic-design.webp" alt="image" class="img-fluid no-shadow" />
        </div>
      </div>
      <div class="col-lg-8 block-1 m-mt50">
        <div class="common-heading text-l pl25">
          <span i18n>Overview</span>
          <h2 i18n>Custom Graphic Design Services We Offer</h2>
          <p i18n>Like other businesses, you want more customers, plenty of them. How to make your potential customers
            cannot help but notice you amongst others? An effective design from a strong and consistent brand is
            definitely what you need. And that’s where Dicom Interactive can assist. We are expertise in creating
            artworks that help you form a comprehensive branding strategy across all media channels. We love doing
            graphic design and are good at it. But not to win any awards, we’re here to win over your audience. So let
            us help! </p>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End About-->
<!--Start Key points-->
<section class="service pad-tb light-dark">
  <div class="container">
    <div class="row">
      <div class="col-lg-7 single-image-app2">
        <div class="text-l service-desc- pr25">
          <h3 class="mb20 mt10" i18n>UI/UX Design</h3>
          <p i18n>Not sure why your mobile app doesn’t perform very well? Wondering why the conversion rate of your website
            is below expectation lately? Do you want to optimize your website to fulfill both complex business
            functions and artistic interface that make your customers fall in love with? Contact us if you need
            appealing and functional interfaces designed from the ground up. You will get custom made digital touch
            points that not only functional but speak to your customers, move them to action and acquire them.
            <br><br>
          </p>
          <p i18n>Our UX and UI specialists only offer the great combinations, which not only align with the industry
            standards but also bring up your very own business trademark, that help in customer conversion and
            retention. You can also book a free consultation with us on the UX/UI of the current digital platforms of
            your business.</p>
          <a [routerLink]="['/contact']" class="btn-main bg-btn2 lnk mt30" (click)="onContactGra()" i18n>Request A Quote <i
              class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></a>
        </div>
      </div>
      <div class="col-lg-5 single-image-app1">
        <div class="single-image wow fadeIn">
          <img src="assets/images/service/branding-.webp" alt="image" class="img-fluid no-shadow" />
        </div>
      </div>
    </div>
  </div>
</section>
<section class="service pad-tb light-dark">
  <div class="container">
    <div class="row">
      <div class="col-lg-5">
        <div class="single-image wow fadeIn">
          <img src="assets/images/service/branding-web.webp" alt="image" class="img-fluid no-shadow" />
        </div>
      </div>
      <div class="col-lg-7">
        <div class="text-l service-desc- pr25">
          <h3 class="mb20" i18n>Web and Mobile friendly design</h3>
          <p i18n>Mobile friendly website is no longer just an option to show how customer oriented your company is. It is
            vital and affect directly to your business. According to Google, there are 94% of people using smartphones
            search for information in the USA. Having a mobile friendly website has become critical to your online
            presence since Google robustly supports mobile friendly websites in their search results, which has
            started from July 2018. Your website needs to be mobile-first, not just responsive. Let get in touch with
            our mobile team to discuss about any of your concerns for your website design. </p>
          <a [routerLink]="['/contact']" class="btn-main bg-btn2 lnk mt30" (click)="onContactGra()" i18n>Request A Quote <i
              class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></a>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Key points-->
<!--Start Service-->
<section class="service-block bg-gradient6 pad-tb">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div class="common-heading ptag">
          <span i18n>Service</span>
          <h2 i18n>Graphic Design services we offer</h2>
          <p class="mb30" i18n>We think big and have hands in all leading technology platforms to provide you wide array of
            services.</p>
        </div>
      </div>
    </div>
    <div class="row upset link-hover">
      <div class="col-lg-6 col-md-12 mt30 wow fadeInUp" data-wow-delay=".2s">
        <div class="s-block wide-sblock hfull">
          <div class="s-card-icon-large"><img src="assets/images/service/logo-design.webp" alt="service"
              class="img-fluid" />
          </div>
          <div class="s-block-content-large">
            <h4 i18n>Logo Designing</h4>
            <p i18n>Let our experienced Creative team and Art director help your business standout from the crowd. A right
              logo design will elevate your whole brand identity. </p>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 mt30 wow fadeInUp" data-wow-delay="0.4s">
        <div class="s-block wide-sblock hfull">
          <div class="s-card-icon-large"><img src="assets/images/service/newsletter.webp" alt="service"
              class="img-fluid" />
          </div>
          <div class="s-block-content-large">
            <h4 i18n>Newsletter Designing</h4>
            <p i18n>Newsletters help you to connect with potential customers. We offer professional, printable newsletter
              design that everyone will love to read.</p>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 mt30 wow fadeInUp" data-wow-delay="0.6s">
        <div class="s-block wide-sblock hfull">
          <div class="s-card-icon-large"><img src="assets/images/service/visit-card.webp" alt="service"
              class="img-fluid" />
          </div>
          <div class="s-block-content-large">
            <h4 i18n>Business Card Designs</h4>
            <p i18n>Your business cards act as a visual extension of your brand to your clients and partners. In this
              digital era, these well-designed paper cards still remain their irreplaceable position in leaving a long-lasting
              impression in every business meet up or as part of a business information exchange.</p>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 mt30 wow fadeInUp" data-wow-delay="0.8s">
        <div class="s-block wide-sblock hfull">
          <div class="s-card-icon-large"><img src="assets/images/service/brand-kit.webp" alt="service"
              class="img-fluid" />
          </div>
          <div class="s-block-content-large">
            <h4 i18n>Brand Kit</h4>
            <p class="rp-mb33" i18n>A brief but powerful visual guideline will protect your brand from inconsistency across all
              communications touch points. We will put things structured for your colleagues, suppliers, and portray
              your brand entity to your client through a coherent brand kit and brand guidelines. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 mt30 wow fadeInUp" data-wow-delay="1s">
        <div class="s-block wide-sblock hfull">
          <div class="s-card-icon-large"><img src="assets/images/service/brochure-.webp" alt="service"
              class="img-fluid" />
          </div>
          <div class="s-block-content-large">
            <h4 i18n>Brochure Design</h4>
            <p i18n>As a digital interactive firm, we tend to make things interactive. Contact us if you need compelling
              yet functioning brochures, whether they are printed designs or electronic versions. </p>
          </div>
        </div>
      </div>
    </div>
    <div class="-cta-btn mt70">
      <div class="free-cta-title v-center wow zoomInDown" data-wow-delay="1.4s">
        <p i18n>Hire a <span>Graphic Designer</span></p>
      </div>
      <div class="mt20 free-cta-title v-center wow zoomInDown" data-wow-delay="1.4s"><a [routerLink]="['/contact']" class="btn-main bg-btn2 lnk" (click)="onContactGra()" i18n>Hire Now<i
          class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></a>
      </div>
    </div>
  </div>
</section>
<!--End Service-->
<!--Start Process-->
<section class="service-block pad-tb light-dark">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div class="common-heading ptag">
          <span i18n>Process</span>
          <h2 i18n>Our Graphic Design Process</h2>
          <p i18n>Our design process follows a proven approach. We begin with a deep understanding of your needs and create
            a planning template.</p>
        </div>
      </div>
    </div>
    <div class="row upset justify-content-center mt60">
      <div class="col-lg-4 v-center order1 order-app1">
        <div class="image-block1">
          <img src="assets/images/process/process-design-1.webp" alt="Process" class="img-fluid" />
        </div>
      </div>
      <div class="col-lg-7 v-center order2 order-app2">
        <div class="ps-block">
          <span>1</span>
          <h3 i18n>Definition Phase</h3>
          <p i18n>This is the first and critical step to gather as much information which helps our designer to understand
            your needs. We make sure to ask you the right questions for our brainstorming meeting which generates some
            great ideas for the product.</p>
        </div>
      </div>
    </div>
    <div class="row upset justify-content-center mt60">
      <div class="col-lg-7 v-center order2 order-app2">
        <div class="ps-block">
          <span>2</span>
          <h3 i18n>Creation Phase</h3>
          <p i18n>We start sketching at this second phase to retrieve your initial feedback. Then you could pick one of our
            different variations of designs. We also refine the design you select until reaching your expectation.</p>
        </div>
      </div>
      <div class="col-lg-4 v-center order1 order-app1">
        <div class="image-block1 image-mobile">
          <img src="assets/images/process/process-design-2.webp" alt="Process" class="img-fluid" />
        </div>
      </div>
    </div>
    <div class="row upset justify-content-center mt60">
      <div class="col-lg-4 v-center order1 order-app1">
        <div class="image-block1 image-mobile">
          <img src="assets/images/process/process-design-3.webp" alt="Process" class="img-fluid" />
        </div>
      </div>
      <div class="col-lg-7 v-center order2 order-app2">
        <div class="ps-block">
          <span>3</span>
          <h3 i18n>Feedback Phase</h3>
          <p i18n>Once you give us a go with the design style and everything, we will apply it to all other necessary
            pages. We then host another meeting on which we present our work and again gather your feedback. We also
            implement the needed changes to establish an approval process.</p>
        </div>
      </div>
    </div>
    <div class="row upset justify-content-center mt60">
      <div class="col-lg-7 v-center order2 order-app2">
        <div class="ps-block">
          <span>4</span>
          <h3 i18n>Delivery Phase</h3>
          <p i18n>Congrats to us! Now everything has been signed off, and we finally reach the destination where we can
            deliver the final design to you.</p>
        </div>
      </div>
      <div class="col-lg-4 v-center order1 order-app1">
        <div class="image-block1 image-mobile">
          <img src="assets/images/process/process-design-4.webp" alt="Process" class="img-fluid" />
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Process-->

<!-- why us, some works, let work together -->
<app-services-why-us></app-services-why-us>
