import { Component, HostListener, Inject, LOCALE_ID, OnInit, AfterViewInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
// Self
import { ScriptService, MetaDataService } from '../../services';
import {
  RICH_BANNERS,
  RICH_BANNERS_VN,
  SERVICES_BANNER,
  SERVICES_BANNER_VN,
  STANDARD_BANNERS,
  STANDARD_BANNERS_VN,
  TECH_STACK_BANNER,
} from './data';
import { NavigationService } from 'src/app/services/navigation.service';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
declare var jQuery: any;
// declare var dic_runAuto: any;
declare var dic_execute: any;

@Component({
  selector: 'app-services-media-banners',
  templateUrl: './media-banners.component.html',
  styleUrls: ['./media-banners.component.css'],
})
export class MediaBannersComponent
  extends MetaDataService
  implements OnInit, AfterViewInit {
  private paramSubscription: Subscription | undefined;
  private keydownSubscription!: Subscription;
  private popstateSubscription!: Subscription;
  isScriptLoaded = false;
  rich_banners = RICH_BANNERS;
  standard_banners = STANDARD_BANNERS;
  services = SERVICES_BANNER;
  techStack = TECH_STACK_BANNER;
  selectorBanner = null;

  mounted = false;

  constructor(
    private scriptService: ScriptService,
    private navigation: NavigationService,
    private route: ActivatedRoute,
    private router: Router,
    title: Title,
    meta: Meta,
    @Inject(LOCALE_ID) public locale: string
  ) {
    super(title, meta);

    if (locale === 'vn') {
      this.rich_banners = RICH_BANNERS_VN;
      this.standard_banners = STANDARD_BANNERS_VN;
      this.services = SERVICES_BANNER_VN;
    }
  }

  ngOnInit() {
    console.log('Init start');

    this.scriptService
      .load(
        this.scriptService.PRELOADER,
        this.scriptService.INIT,
        this.scriptService.BANNER_SCRIPT
      )
      .then(() => {
        this.isScriptLoaded = true;
      });

    this.setMetaTags({
      description:
        'Explore Dicom Interactive’s professional web design services, offering customizable, SEO-friendly solutions for all industries, helping you create websites quickly and effectively.',
      image: 'https://dicom-interactive.com/en/assets/images/logo_small.webp',
      publisher: 'Dicom Interactive',
      author: 'Dicom Interactive',
      title: 'Media Banners | Dicom Interactive',
      url: 'https://www.dicom-interactive.com/en/services/media-banners/',
      siteName: 'Media Banners | Dicom Interactive',
      twitterCreator: '@dicomInteractive',
      keywords: [
        'web design services',
        'SEO services',
        'website solutions',
        'professional web design',
        'SEO optimization',
        'web development services',
        'media banners',
      ],
      canonical: 'https://www.dicom-interactive.com/en/services/media-banners/',
    });

    this.paramSubscription = this.route.paramMap.subscribe((params) => {
      const slug = params.get('slug');
      if (slug) {
        const isValidSlug = this.rich_banners.some(
          (item) => item.slug === slug
        );
        const indexStandardBanner = this.standard_banners.findIndex(
          (item) => item.slug === slug
        );
        if (isValidSlug) {
          this.selectTab(slug);

          const indexPreviousBanner = this.rich_banners.findIndex(
            item => item.slug === this.navigation.getPreviousSlug());
          const indexCurrentBanner = this.standard_banners.findIndex(
            item => item.slug === this.navigation.getCurrentSlug());

          if (!(indexPreviousBanner > -1 && indexCurrentBanner > -1)) {
            this.scrollTo(event, slug);
          }
        } else if (indexStandardBanner > -1) {
          this.scrollToBanner(event, slug);
          this.showStandardBanner(indexStandardBanner)
          this.showBanner(0);
        } else {
          this.showBanner(0);
        }

        if (['expandable-banner', 'parallax-banner'].includes(slug)) {
          setTimeout(function () {
            dic_execute();
          }, 1000);
        }
      } else {
        const previousSlug = this.navigation.getPreviousSlug();
        if (previousSlug) {
          const indexStandardBanner = this.standard_banners.findIndex(
            (item) => item.slug === previousSlug
          );
          if (indexStandardBanner > -1) {
            this.scrollToBanner(
              event,
              previousSlug
            );
          }
        }

        this.showBanner(0);
      }
    });
    
    // Listen for 'keydown' events on the document
    this.keydownSubscription = fromEvent<KeyboardEvent>(document, 'keydown')
      .pipe(
        filter(event => event.key === 'Escape'), // Only listen to Escape key
        debounceTime(250) // Ignore repeated presses within 250ms
      )
      .subscribe(() => this.selectorBanner && this.closeBanner());
    
    // Listen for 'popstate' events on the window
    this.popstateSubscription = fromEvent<Event>(window, 'popstate')
      .pipe(debounceTime(250)) // Ignore repeated presses within 250ms)
      .subscribe(() => this.selectorBanner && this.closeBanner());
  }

  ngAfterViewInit() {
    const preloadpage = document.getElementById('page_loader');
    if (preloadpage) {
      preloadpage.style.display = 'none';
    }
  }

  ngOnDestroy() {
    this.paramSubscription?.unsubscribe();
    this.keydownSubscription?.unsubscribe();
    this.popstateSubscription?.unsubscribe();
  }

  scrollTo(event: Event, slug: string) {
    event?.preventDefault();

    setTimeout(() => {
      const target = document.querySelector<HTMLAnchorElement>(
        `#${slug}`
      );
      const header = document.querySelector('header');
      const headerHeight = header?.offsetHeight || 0;

      if (target) {
        const targetPosition = target.getBoundingClientRect().top +
          window.scrollY - (headerHeight + 10);
        window.scrollTo({ top: targetPosition, behavior: 'smooth' });
      }
    }, 0);
  }

  private setSelectorBanner(index: number) {
    if (index < 0 || index >= this.standard_banners.length) {
      this.selectorBanner = null;
      return;
    }
    const banner = this.standard_banners[index];
    this.selectorBanner = banner;
    document.body.onload = function () {
      const selector = document.querySelector<HTMLIFrameElement>(
        `.${banner.slug}`
      );
      if (selector && selector.contentWindow) {
        setTimeout(() => {
          selector.contentWindow.location.reload();
        }, 0);
      }
    };
  }

  scrollToBanner(
    event: Event,
    slug: string,
  ) {
    event?.preventDefault();

    setTimeout(() => {
      const target = document.querySelector<HTMLAnchorElement>(`#${slug}`);
      const header = document.querySelector('header');
      const headerHeight = header?.offsetHeight || 0;

      if (target) {
        const targetPosition =
          target.getBoundingClientRect().top +
          window.scrollY -
          (headerHeight + 10);
        window.scrollTo({ top: targetPosition, behavior: 'smooth' });
      }
    }, 0);
  }

  showStandardBanner(index: number) {
    this.setSelectorBanner(index);
    setTimeout(() => {
      jQuery('#modalform').modal('show');
    }, 0);
  }

  closeBanner() {
    const modal = jQuery('#modalform');
    
    modal.addClass('fade');
    modal.modal('hide');
    this.navigation.back('services/media-banners');

   // Wait until the modal is fully hidden before resetting the selector
    modal.on('hidden.bs.modal', () => {
      this.setSelectorBanner(-1);
      modal.off('hidden.bs.modal'); // Remove the event listener after execution
    });
  }

  private navigateToFirstBanner() {
    this.router.navigate([
      `/services/media-banners/${this.rich_banners[0].slug}`,
    ]);
    this.showBanner(0);
  }

  private showBanner(index: number) {
    this.rich_banners = this.rich_banners.map((banner, i) => ({
      ...banner,
      show: i === index,
    }));
  }

  selectTab(slug: string) {
    this.rich_banners = this.rich_banners.map((banner) => ({
      ...banner,
      show: banner.slug === slug,
    }));
    document.body.onload = function () {
      const selector = document.querySelector<HTMLIFrameElement>(`.${slug}`);
      if (selector && selector.contentWindow) {
        setTimeout(() => {
          selector.contentWindow.location.reload();
        }, 0);
      }
    };
  }
}
