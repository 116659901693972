<section class="breadcrumb-area banner-3">
  <div class="text-block">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 v-center">
          <div class="bread-inner">
            <div class="bread-menu wow fadeInUp" data-wow-delay=".2s">
              <ul>
                <li><a [routerLink]="['/']">Home</a></li>
                <li><a [routerLink]="['/showcases-web']">Showcases</a></li>
              </ul>
            </div>
            <div class="bread-title mt-3">
              <h1>My Project</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Breadcrumb Area-->
<!--Start Portfolio-->
<section class="portfolio-page pad-tb">
  <div class="container">
    <div class="row justify-content-left">
      <div class="col-lg-6">
        <div class="common-heading pp">
          <span>Demo</span>
          <h2>Outstanding Projects</h2>
        </div>
      </div>
      <div class="col-lg-6 v-center">
        <div class="filters">
          <ul class="filter-menu d-none">
            <li data-filter="*" class="is-checked">All</li>
            <li data-filter=".website">Website</li>
            <li data-filter=".app">Mobile App</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row card-list">
      <div class="col-lg-12 col-md-12 grid-sizer"></div>
      <div class="col-lg-12 col-sm-12 single-card-item app" *ngFor="let showcase of showcases">
        <a [routerLink]="['/showcases-web', showcase.attributes.slug]" class="portfolio-block link-block bg-gradient8">
          <div class="portfolio-item-info">
            <span class="text-uppercase font-weight-bold">{{showcase.attributes.type}}</span>
            <h3 class="mt30 mb30">{{showcase.attributes.title}}</h3>
            <div class="reviews-card pr-shadow">
              <div class="review-text">
                <p>{{showcase.attributes.sortDescription}}</p>
              </div>
              <div class="-client-details-">
                <div class="-reviewr">
                  <img src="assets/img/logo2023.webp" alt="{{showcase.attributes.title}}" class="img-fluid">
                </div>
                <div class="reviewer-text">
                  <h4>Admin</h4>
                  <p>Viet Nam</p>
                </div>
              </div>
            </div>
          </div>
          <div class="portfolio-item-image">
            <img src="{{imgEndpoint +  showcase.attributes.thumbnail.data.attributes.url}}"  alt="{{showcase.attributes.thumbnail.data.attributes.alternativeText}}" class="img-fluid"/>
          </div>
        </a>
      </div>

      <!-- <div class="col-lg-12 col-sm-12 single-card-item website">
        <div class="portfolio-block bg-gradient6">
          <div class="portfolio-item-info">
            <span>Nuxtjs, Nodejs</span>
            <h3 class="mt30 mb30"><a href="#">Smicolon - Take your projects to the next level</a></h3>
            <div class="reviews-card pr-shadow">
              <div class="review-text">
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
              </div>
              <div class="-client-details-">
                <div class="-reviewr">
                  <img src="images/client/reviewer-a.webp" alt="Good Review" class="img-fluid">
                </div>
                <div class="reviewer-text">
                  <h4>Mario Speedwagon</h4>
                  <p>Business Owner, <small>Jaipur</small></p>
                </div>
              </div>
            </div>
          </div>
          <div class="portfolio-item-image">
            <a href="#"><img src="images/portfolio/website-mockup2.webp" alt="portfolio" class="img-fluid"/> </a>
          </div>
        </div>
      </div>

      <div class="col-lg-12 col-sm-12 single-card-item graphic">
        <div class="portfolio-block bg-gradient4">
          <div class="portfolio-item-info">
            <span>ios, design</span>
            <h3 class="mt30 mb30"><a href="#">UX design for a startup focusing on measuring Team Performance</a></h3>
            <div class="reviews-card pr-shadow">
              <div class="review-text">
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
              </div>
              <div class="-client-details-">
                <div class="-reviewr">
                  <img src="images/client/reviewer-a.webp" alt="Good Review" class="img-fluid">
                </div>
                <div class="reviewer-text">
                  <h4>Mario Speedwagon</h4>
                  <p>Business Owner, <small>Jaipur</small></p>
                </div>
              </div>
            </div>
          </div>
          <div class="portfolio-item-image">
            <a href="#"><img src="images/portfolio/website-mockup3.webp" alt="portfolio" class="img-fluid"/> </a>
          </div>
        </div>
      </div>

      <div class="col-lg-12 col-sm-12 single-card-item website">
        <div class="portfolio-block bg-gradient9">
          <div class="portfolio-item-info">
            <span>ios, design</span>
            <h3 class="mt30 mb30"><a href="#">UX design for a startup focusing on measuring Team Performance</a></h3>
            <div class="reviews-card pr-shadow">
              <div class="review-text">
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
              </div>
              <div class="-client-details-">
                <div class="-reviewr">
                  <img src="images/client/reviewer-a.webp" alt="Good Review" class="img-fluid">
                </div>
                <div class="reviewer-text">
                  <h4>Mario Speedwagon</h4>
                  <p>Business Owner, <small>Jaipur</small></p>
                </div>
              </div>
            </div>
          </div>
          <div class="portfolio-item-image">
            <a href="#"><img src="images/portfolio/website-mockup4.webp" alt="portfolio" class="img-fluid"/> </a>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</section>
