import { Injectable } from '@angular/core';
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})

export class LinkService {

  constructor(@Inject(DOCUMENT) private doc: Document) { }

  setLinkCanonical() {
    const url = this.doc.location.href;
    if (url.includes("www.")) {
      const newUrl = url.replace("www.", "");
      const link: HTMLLinkElement = document.createElement('link');
      link.setAttribute('rel', 'canonical');
      document.head.appendChild(link);
      link.setAttribute('href', newUrl);
    }
  }
}

