<!--Breadcrumb Area-->
<section class="breadcrumb-area banner-5">
  <div class="text-block">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 v-center">
          <div class="bread-inner">
            <div class="bread-menu wow fadeInUp" data-wow-delay=".2s">
              <ul>
                <li><a [routerLink]="['/']" i18n>Home</a></li>
                <li><a [routerLink]="['/portfolio' , '']" i18n>Portfolio</a></li>
                <li><span i18n>Project Details</span></li>
              </ul>
            </div>
            <div class="bread-title wow fadeInUp" data-wow-delay=".5s">
              <h1 i18n>Wild Imports</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Breadcrumb Area-->

<!--Start Case Study About-->
<section class="case-study pad-tb">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="image-block upset">
          <img
            src="assets/images/portfolio/image-18.webp"
            alt="case study"
            class="img-fluid no-shadow"
          />
        </div>
      </div>
      <div class="col-lg-6 block-1 v-center">
        <div class="common-heading inner-heading text-l pl25">
          <span i18n>About The Project</span>
          <h2 i18n>Wild Imports is your Wild Plant and Machinery Importers!</h2>
          <p i18n>
            Wild Imports is your go-to destination for premium construction
            vehicles! Our website is designed for a seamless browsing
            experience, making it easy to explore our extensive inventory of
            construction machinery and vehicles.
          </p>
          <p i18n>
            Navigate effortlessly through a diverse selection of construction
            equipment, including excavators, bulldozers, cranes, and more. Each
            listing features detailed specifications, high-resolution images,
            and competitive pricing, ensuring you have all the information
            needed to make confident purchasing decisions.
          </p>
          <p i18n>
            Found the perfect match? Use our simple contact form to send
            inquiries or reach out via phone or email for personalized
            assistance.
          </p>

          <p>
            <strong>
              NextJs | Keystone CMS | Playwright | Docker | Amazon RDS | Amazon
              S3 | Amazon ELB | Amazon ECS
            </strong>
          </p>
        </div>
        <div class="project-platform mt60 pl25">
          <div class="project-platform-used -shadow">
            <a href="https://wildimports.com.au/" target="blank"
              ><img src="assets/images/icons/www.webp" alt="visit"
            /></a>
          </div>
          <div>
            <strong> ⤺ </strong
            ><span style="font-size: smaller" i18n
              >Click here to visit the site</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Case Study About-->
<section class="case-study pad-tb bg-gradient6 deep-dark">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <div class="common-heading">
          <span i18n>Service Features</span>
          <h2 class="mb30" i18n>
            As per client's need, we have developed a mobile-friendly corporate
            site.
          </h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <h4 class="mt20 mb10" i18n>Optimize SEO</h4>
        <ul class="list-style-">
          <li i18n>
            Our advanced SEO strategies enhance search engine rankings, drive
            organic traffic, and increase Wild Imports’ visibility online. This
            includes well-structured metadata, keyword-rich content, and
            properly formatted HTML tags to ensure higher search result
            placement.
          </li>
        </ul>
      </div>
      <div class="col-lg-6">
        <h4 class="mt20 mb10" i18n>Mobile-Friendly UI</h4>
        <ul class="list-style-">
          <li i18n>
            Our responsive website design adapts seamlessly to various screen
            sizes, delivering a consistent, user-friendly experience across all
            devices. The layout is clean and intuitive, with touch-friendly
            navigation, making browsing effortless on smartphones and tablets.
          </li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <h4 class="mt20 mb10" i18n>Easy Navigation</h4>
        <ul class="list-style-">
          <li i18n>
            The website features an intuitive interface with clear categories
            and filters. A prominently displayed navigation bar with dropdown
            menus ensures easy access to different. product categories.
            Additionally, a robust search function helps users quickly find what
            they need.
          </li>
        </ul>
      </div>
      <div class="col-lg-6">
        <h4 class="mt20 mb10" i18n>Easy Contact</h4>
        <ul class="list-style-">
          <li i18n>
            We make customer communication simple and convenient. Contact
            options are prominently displayed on every page, including a
            user-friendly contact form, email addresses, and phone numbers,
            ensuring that customers can reach us quickly with any
            product-related inquiries.
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>
<!--Start Project Challenges-->
<section class="case-study pad-tb">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-sm-6">
        <h2 class="mt20 mb20" i18n>Challenges</h2>
        <p i18n>
          The journey with Wild Imports, while fulfilling, presented unique
          challenges that required innovative solutions and strategic
          planning.<br /><br />
        </p>
        <p style="text-indent: 15px" i18n>
          ➟ <strong>Dynamic Content Management Requirements:</strong>
          Wild Imports aimed for unparalleled flexibility in managing their website
          content. Their goal was to have a CMS where <strong>every element</strong>—text, font
          size, font type, colors, images, and layout—was fully configurable.
          This dynamic requirement posed a significant challenge as it demanded
          careful planning and robust development to ensure a seamless user
          interface while preserving the system’s stability. Our team
          successfully crafted a CMS solution that allowed the client to manage
          their website with precision and ease, meeting their high expectations
          for content customization. <br /><br />
        </p>
        <p style="text-indent: 15px" i18n>
          ➟ <strong>Multi-Device Optimization:</strong>
          Wild Imports required their website to provide a consistent and smooth
          user experience across a wide range of devices, from desktops to
          tablets and mobile phones. This challenge extended beyond ensuring
          responsiveness; it required testing and optimizing every interactive
          element for various screen sizes and resolutions. Our development and
          quality assurance teams dedicated extensive effort to perfect the
          experience, delivering a website that looks and performs flawlessly
          across all platforms.
          <br /><br />
        </p>
        <p style="text-indent: 15px" i18n>
          ➟ <strong>Tight Deadlines for Product Onboarding:</strong>
          One of the most pressing challenges was the <strong>tight deadline</strong> to onboard
          a substantial volume of products onto the platform. This involved
          creating and importing accurate product data, ensuring that all
          imagery, descriptions, categories, and pricing details were consistent
          and properly structured within the CMS. The time-sensitive nature of
          this task required our team to work with precision and speed,
          leveraging advanced tools and streamlined processes to meet the
          deadline without compromising quality.
          <br />
          <br />
        </p>
        <p i18n>
          Through close collaboration with the client, proactive communication, and strategic problem-solving, we overcame these challenges to deliver a dynamic, user-friendly website that exceeded expectations. Our team’s adaptability and commitment were crucial in addressing the project’s complexities and achieving success.
        </p>
      </div>
      <div class="col-lg-6 col-sm-6">
        <div class="project-view m-mt30">
          <img
            src="assets/images//case-study/goen-view.webp"
            alt="case study"
            class="img-fluid"
          />
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Project Challenges-->

<!--Start Solutions-->
<section class="case-study pad-tb bg-gradient5 deep-dark">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 block-1 v-center">
        <div class="common-heading inner-heading text-l pr25">
          <span i18n>Solutions</span>
          <h2 i18n>How We Work</h2>
          <p i18n>
            To address the unique challenges faced during the development of
            Wild Imports’ website, we implemented the following tailored
            solutions:
          </p>
          <p style="text-indent: 15px" i18n>
            ➟ <strong>Dynamic Content Management Implementation:</strong>
            To meet the client’s demand for unparalleled flexibility, we developed a
            highly dynamic CMS. This system allows <strong>complete customization</strong> of
            text, font sizes, font types, colors, images, and even layout
            configurations. By leveraging Craft CMS, we ensured that Wild
            Imports could effortlessly manage their content while maintaining
            system stability and usability. This solution empowered the client
            to adapt the website quickly and effectively to meet their changing
            needs.
          </p>
          <p style="text-indent: 15px" i18n>
            ➟ <strong>Multi-Device Optimization:</strong>
            To provide a seamless browsing experience across all devices, we implemented <strong>responsive
            design</strong> techniques. This included rigorous testing to ensure optimal
            performance on desktops, tablets, and mobile devices. Our team used
            cutting-edge frameworks to ensure smooth transitions, pixel-perfect
            layouts, and consistently high performance, regardless of the screen
            size or resolution. This solution guarantees a flawless user
            experience for all visitors.
          </p>
          <p style="text-indent: 15px" i18n>
            ➟ <strong>Efficient Product Onboarding Workflow:</strong>
            To meet the tight deadline for onboarding a substantial volume of products, we
            streamlined the product import process by developing <strong>automated tools</strong>
            and <strong>bulk upload functionalities</strong> within the CMS. These tools allowed
            for efficient data handling, ensuring that product details such as
            images, descriptions, categories, and pricing were accurately and
            consistently imported. This approach significantly reduced manual
            effort and accelerated the onboarding timeline while maintaining
            high-quality standards.
          </p>
          <p style="text-indent: 15px" i18n>
            ➟ <strong>Enhanced Collaboration and Communication:</strong>
            We established a transparent and efficient communication process with
            the Wild Imports team, ensuring that all requirements were clearly
            understood and addressed. Regular progress updates, agile
            methodologies, and collaboration tools allowed for quick feedback
            loops and minimized miscommunication. This ensured that all
            stakeholders were aligned throughout the project lifecycle.
          </p>
          <p i18n>
            Through these solutions, we successfully delivered a dynamic,
            mobile-friendly, and user-focused website that meets Wild Imports’
            high expectations and industry standards. Our tailored approach,
            combined with advanced technical expertise, enabled us to overcome
            challenges and achieve a successful outcome.
          </p>
        </div>
        <div class="card-nx row">
          <div class="col-lg-3 col-md-3 col-sm-6 col-6 mt30">
            <div class="icon-card- hfull">
              <img
                src="assets/images/icons/logo-and-branding.svg"
                alt="case study"
                class="img-fluid"
              />
              <h4 i18n>UI/UX Graphic Design</h4>
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-6 col-6 mt30">
            <div class="icon-card- hfull">
              <img
                src="assets/images/icons/branding.svg"
                alt="case study"
                class="img-fluid"
              />
              <h4 i18n>Front-end Development</h4>
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-6 col-6 mt30">
            <div class="icon-card- hfull">
              <img
                src="assets/images/icons/back.svg"
                alt="case study"
                class="img-fluid"
              />
              <h4 i18n>Back-end/API Development</h4>
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-6 col-6 mt30">
            <div class="icon-card- hfull">
              <img
                src="assets/images/icons/teama.svg"
                alt="case study"
                class="img-fluid"
              />
              <h4 i18n>Customer Support</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!--End Solutions-->
<div class="case-study pad-tb">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="porto-slide owl-carousel">
          <div class="screen-splits">
            <img
              src="assets/images/case-study/wildimports-web-1.webp"
              alt="case study"
              class="img-fluid"
              style="border-style: solid; border-width: thin"
            />
          </div>
          <div class="screen-splits">
            <img
              src="assets/images/case-study/wildimports-web-2.webp"
              alt="case study"
              class="img-fluid"
              style="border-style: solid; border-width: thin"
            />
          </div>
          <div class="screen-splits">
            <img
              src="assets/images/case-study/wildimports-web-3.webp"
              alt="case study"
              class="img-fluid"
              style="border-style: solid; border-width: thin"
            />
          </div>
          <div class="screen-splits">
            <img
              src="assets/images/case-study/wildimports-web-4.webp"
              alt="case study"
              class="img-fluid"
              style="border-style: solid; border-width: thin"
            />
          </div>
          <div class="screen-splits">
            <img
              src="assets/images/case-study/wildimports-web-5.webp"
              alt="case study"
              class="img-fluid"
              style="border-style: solid; border-width: thin"
            />
          </div>
          <div class="screen-splits">
            <img
              src="assets/images/case-study/wildimports-web-6.webp"
              alt="case study"
              class="img-fluid"
              style="border-style: solid; border-width: thin"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Start Navigate-->
<section class="testinomial-section pad-tb">
  <div class="container">
    <div class="row">
      <div class="col-6 v-center">
        <a [routerLink]="['/', 'portfolio', previousLink]" class="nav_link" i18n
          >&laquo; Previous</a
        >
      </div>
      <div class="col-6 v-center">
        <a
          [routerLink]="['/', 'portfolio', nextLink]"
          class="nav_link next_nav_link"
          i18n
          >Next &raquo;</a
        >
      </div>
    </div>
  </div>
</section>
<!--End Navigate-->

<!--Start Testinomial-->
<app-testinomial-component></app-testinomial-component>
<!--End Testinomial-->
