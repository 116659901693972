<!--Breadcrumb Area-->
<section class="breadcrumb-area banner-5">
  <div class="text-block">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 v-center">
          <div class="bread-inner">
            <div class="bread-menu wow fadeInUp" data-wow-delay=".2s">
              <ul>
                <li><a [routerLink]="['/']" i18n>Home</a></li>
                <li><a [routerLink]="['/portfolio' , '']" i18n>Portfolio</a></li>
                <li><span i18n>Project Details</span></li>
              </ul>
            </div>
            <div class="bread-title wow fadeInUp" data-wow-delay=".5s">
              <h1 i18n>Envoca Mobile App</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Breadcrumb Area-->
<!--Start Case Study About-->
<section class="case-study pad-tb">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="image-block upset">
          <div class="video-container">
            <img src="assets/images/case-study/envoca-intro.webp" alt="case study" class="img-fluid no-shadow" />
            <div class="btuon">
              <div class="aninmattion" (click)="exexute()">
                <img src="assets/images/icons/play-button.webp" alt="play video" class="no-shadow-img" (click)="executeVideoScript()" >
              </div>
            </div>
          </div>
          <div class="container-video">
            <div class="class-qopup-video" (click)="executeVideo()">
              <span class="close-video" (click)="executeVideo()">&times;</span>
            </div>
            <video id="videoPlayer" src="../../assets/video/output_HD720.mp4" class="video-ing" type="video/mp4" muted controls></video>
          </div>
        </div>
      </div>
      <div class="col-lg-6 block-1 v-center">
        <div class="common-heading inner-heading text-l pl25">
          <span i18n>About The Project</span>
          <h2 i18n>Envoca mobile app for English learner</h2>
          <p i18n>Envoca offers a fun and efficient way to learn new language. With the renowned learning
            methodology - spaced repetition - Envoca could help you either proactively or passively
            memorize new vocabularies almost effortlessly.</p>
          <p><strong> Swift 5.2 | Java | Python | Lambda | DynamoDB | SQS | S3 </strong></p>
        </div>
        <div class="project-platform mt60 pl25">
          <!-- <div class="project-platform-used -shadow">
            <a href="https://play.google.com/store/apps/details?id=com.envoca.english&hl=en_US&gl=US" target="_blank"
              rel="noopener noreferrer">
              <img src="assets/images/icons/android.svg" alt="android"></a>
          </div> -->
          <div class="project-platform-used -shadow">
            <a href="https://apps.apple.com/us/app/esvoca-spanish-vocabulary/id1529855861" target="_blank"
              rel="noopener noreferrer">
              <img src="assets/images/icons/apple.svg" alt="apple"></a>
          </div>
          <div><strong> ⤺ </strong><span style="font-size: smaller;" i18n>Click here to download the app</span></div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Case Study About-->
<section class="case-study pad-tb bg-gradient6 deep-dark">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <div class="common-heading">
          <span i18n>App Features</span>
          <h2 class="mb30" i18n>As per client's need, we have developed Envoca mobile app for both platform:
            iOS & Android.
          </h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <ul class="list-ul ul-circle">
          <li i18n>Uncluttered, stunning design that let you customize the app skin with your favorite color
            scheme from various elegant, predefined themes.
          </li>
          <li i18n>Thousands of useful words from a most common and widely used topics would be a great way for
            starter as well as advanced learner.</li>
          <li i18n>Envoca use Fibonacci as the golden ratio. It will help you put new words into your
            subconscious mind without you even noticing it.</li>
        </ul>
      </div>
      <div class="col-lg-6">
        <ul class="list-ul ul-circle">
          <li i18n>Add words to favorite then navigate to Favorite menu and start learning them. The more times
            you learn a certain word, the longer time it will get back and so on.</li>
          <li i18n>3 most common quiz games under the Game menu will help you to be entertained and benefited
            at the same time.</li>
          <li i18n>Keep track with 2 beautiful and easy-to-use statistics charts: Pie and Graph.
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>
<!--Start Solutions-->
<section class="case-study pad-tb bg-gradient5 deep-dark">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 block-1 v-center">
        <div class="common-heading inner-heading text-l pr25">
          <span i18n>Solutions</span>
          <h2 i18n>How We Work</h2>
          <h4 i18n>We decided to go with native programming languages for this mobile app development.<br><br></h4>
          <p i18n>First off, we sketch the layout and finetune it various number of times until it meets our
            client expectation. And then, based on that sketch, our designers breathe life into it with
            colors, logo, graphic, animation... So that the applications could have a tangible identity.
          </p>
          <p i18n>During the development stage, we define milestones that we deliver shippable package to our
            client so we could make sure we are going on the right path.</p>
        </div>
        <div class="card-nx row">
          <div class="col-lg-3 col-md-6 col-sm-6 col-6 mt30">
            <div class="icon-card- hfull">
              <img src="assets/images/icons/branding.svg" alt="case study" class="img-fluid">
              <h3>Mobile App Development</h3>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-6 mt30">
            <div class="icon-card- hfull">
              <img src="assets/images/icons/back.svg" alt="case study" class="img-fluid">
              <h3>Fibonacci sequence formula</h3>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-6 mt30">
            <div class="icon-card- hfull">
              <img src="assets/images/icons/seo.svg" alt="case study" class="img-fluid">
              <h3>In-App Purchase Integration</h3>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-6 mt30">
            <div class="icon-card- hfull">
              <img src="assets/images/icons/logo-and-branding.svg" alt="case study" class="img-fluid">
              <h3>UI/UX Graphic Design</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Solutions-->
<div class="case-study pad-tb">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="project-screens owl-carousel">
          <div class="screen-splits"> <img src="assets/images/case-study/envoca-app-1.webp" alt="case study"
              class="img-fluid" /></div>
          <div class="screen-splits"> <img src="assets/images/case-study/envoca-app-2.webp" alt="case study"
              class="img-fluid" /></div>
          <div class="screen-splits"> <img src="assets/images/case-study/envoca-app-3.webp" alt="case study"
              class="img-fluid" /></div>
          <div class="screen-splits"> <img src="assets/images/case-study/envoca-app-4.webp" alt="case study"
              class="img-fluid" /></div>
          <div class="screen-splits"> <img src="assets/images/case-study/envoca-app-5.webp" alt="case study"
              class="img-fluid" /></div>
          <div class="screen-splits"> <img src="assets/images/case-study/envoca-app-6.webp" alt="case study"
              class="img-fluid" /></div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Start Navigate-->
<section class="testinomial-section pad-tb">
  <div class="container">
    <div class="row">
      <div class="col-6 v-center">
        <a [routerLink]="['/', 'portfolio', previousLink]" class="nav_link" i18n>&laquo; Previous</a>
      </div>
      <div class="col-6 v-center">
        <a [routerLink]="['/', 'portfolio', nextLink]" class="nav_link next_nav_link" i18n>Next &raquo;</a>
      </div>
    </div>
  </div>
</section>
<!--End Navigate-->

<!--Start Testinomial-->
<app-testinomial-component></app-testinomial-component>
<!--End Testinomial-->
