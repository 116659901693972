<!--Breadcrumb Area-->
<section class="breadcrumb-area banner-5">
  <div class="text-block">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 v-center">
          <div class="bread-inner">
            <div class="bread-menu wow fadeInUp" data-wow-delay=".2s">
              <ul>
                <li><a [routerLink]="['/']" i18n>Home</a></li>
                <li><a [routerLink]="['/portfolio' , '']" i18n>Portfolio</a></li>
                <li><span i18n>Project Details</span></li>
              </ul>
            </div>
            <div class="bread-title wow fadeInUp" data-wow-delay=".5s">
              <h1 i18n>CarsNow Mobile Applications</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Breadcrumb Area-->

<!--Start Case Study About-->
<section class="case-study pad-tb">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="image-block upset">
          <img src="assets/images/portfolio/image-12.webp" alt="case study" class="img-fluid no-shadow" />
        </div>
      </div>
      <div class="col-lg-6 block-1 v-center">
        <div class="common-heading inner-heading text-l pl25">
          <span i18n>About The Project</span>
          <h2 i18n>CarsNow Mobile Application - Car Rental Service</h2>
          <p i18n>CarsNow is a convenient mobile application that allows approved users in participating properties to easily
            reserve, unlock, drive and pay for short-term carshare rentals. With CarsNow, users can quickly search for
            available vehicles, book them in advance and unlock them with a simple tap on their smartphones. The app
            offers a seamless user experience with easy-to-use features and secure payment options. CarsNow is the
            perfect solution for anyone looking for a flexible, convenient and cost-effective way to get around town.
          </p>
          <p><strong> Objective-C | Java | Python | Lambda | DynamoDB | SQS | S3 </strong></p>
        </div>
        <div class="project-platform mt60 pl25">
          <div class="project-platform-used -shadow"><a
              href="https://play.google.com/store/apps/details?id=co.carsnow&hl=en" target="blank"><img
                src="assets/images/icons/android.svg" alt="android"></a>
          </div>
          <div class="project-platform-used -shadow"><a href="https://apps.apple.com/us/app/carsnow-inc/id1542902076"
              target="blank"><img src="assets/images/icons/apple.svg" alt="apple"></a></div>
          <div><strong> ⤺ </strong><span style="font-size: smaller;" i18n>Click the icon to download the app</span></div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Case Study About-->
<section class="case-study pad-tb bg-gradient6 deep-dark">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <div class="common-heading">
          <span i18n>App Features</span>
          <h2 class="mb30" i18n>As per client's need, we have developed CarsNow mobile app for both platform:
            iOS & Android.
          </h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <h4 class="mt20 mb10" i18n>Book car in advance</h4>
        <ul class="list-style-">
          <li i18n>With CarsNow mobile app, customers can easily book cars in advance with just a few taps on their phone.
            This feature is perfect for people who need to plan ahead and want to ensure they have reliable
            transportation when they need it.
          </li>
        </ul>
      </div>
      <div class="col-lg-6">
        <h4 class="mt20 mb10" i18n>Interact with the car via Bluetooth</h4>
        <ul class="list-style-">
          <li i18n>One of CarsNow standout features is its ability to interact with the car via Bluetooth to lock and unlock
            the door. This is made possible through the hardware kit provided by Geotab, which enables the app to
            communicate with the car and perform remote functions.</li>
        </ul>
      </div>
      <div class="col-lg-6">
        <h4 class="mt20 mb10" i18n>Concierge service</h4>
        <ul class="list-style-">
          <li i18n>CarsNow app also offers a convenient concierge service for users who need assistance with their booking.
            Whether it's answering questions about the service or resolving issues that may arise during the booking
            process, the concierge service is there to help users every step of the way.</li>
        </ul>
      </div>
      <div class="col-lg-6">
        <h4 class="mt20 mb10" i18n>Seamless payment</h4>
        <ul class="list-style-">
          <li i18n>For added convenience, CarsNow app allows customers to easily add their credit card information and pay for
            their booking within the app. This is made possible through a secure payment gateway provided by Stripe,
            which ensures that users' payment information is safe and protected.</li>
        </ul>
      </div>
    </div>
  </div>
</section>
<!--Start Solutions-->
<section class="case-study pad-tb bg-gradient5 deep-dark">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 block-1 v-center">
        <div class="common-heading inner-heading text-l pr25">
          <span i18n>Solutions</span>
          <h2 i18n>How We Work</h2>
          <h4 i18n>Developing and connecting a white-label mobile app with a fleet management system involves several key
            steps.<br><br></h4>
          <p i18n> Firstly, a team of experienced developers will work to create the mobile app with a user-friendly
            interface, customizable branding options, and features specific to the fleet management industry.
          </p>
          <p i18n>Once the app is developed, it must be integrated with the fleet management system. This involves connecting
            the app to the fleet management software and enabling it to access real-time data on vehicle location,
            driver behavior, fuel consumption, and other important metrics.</p>
          <p i18n>To ensure seamless connectivity, APIs (application programming interfaces) are often used to facilitate
            data exchange between the app and the fleet management system. The APIs also enable the app to perform
            functions such as route optimization, vehicle maintenance scheduling, and driver performance monitoring.</p>
          <p i18n>To make the app a white-label solution, branding options are customized to match the fleet management
            company's logo, colors, and other design elements. This creates a cohesive brand experience for the
            end-users of the app, whether they are fleet managers or drivers.</p>
          <p i18n>Overall, developing and connecting a white-label mobile app with a fleet management system requires
            expertise in both software development and fleet management. By working closely with a team of experienced
            developers and fleet management professionals, a white-label app can be created that meets the unique needs
            of the fleet management industry and provides a seamless user experience.</p>
        </div>
        <div class="card-nx row">
          <div class="col-lg-3 col-md-6 col-sm-6 col-6 mt30">
            <div class="icon-card- hfull">
              <img src="assets/images/icons/logo-and-branding.svg" alt="case study" class="img-fluid">
              <h4>UI/UX Graphic Design</h4>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-6 mt30">
            <div class="icon-card- hfull">
              <img src="assets/images/icons/branding.svg" alt="case study" class="img-fluid">
              <h4>Mobile App Development</h4>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-6 mt30">
            <div class="icon-card- hfull">
              <img src="assets/images/icons/tools.svg" alt="case study" class="img-fluid">
              <h4>Geotab Hardware Integration</h4>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-6 mt30">
            <div class="icon-card- hfull">
              <img src="assets/images/icons/price.svg" alt="case study" class="img-fluid">
              <h4>Stripe Payment Gateway Integration</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Solutions-->
<div class="case-study pad-tb">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="project-screens owl-carousel">
          <div class="screen-splits"> <img src="assets/images/case-study/whipev-app-1.webp" alt="case study"
              class="img-fluid" /></div>
          <div class="screen-splits"> <img src="assets/images/case-study/whipev-app-2.webp" alt="case study"
              class="img-fluid" /></div>
          <div class="screen-splits"> <img src="assets/images/case-study/whipev-app-3.webp" alt="case study"
              class="img-fluid" /></div>
          <div class="screen-splits"> <img src="assets/images/case-study/whipev-app-4.webp" alt="case study"
              class="img-fluid" /></div>
          <div class="screen-splits"> <img src="assets/images/case-study/whipev-app-5.webp" alt="case study"
              class="img-fluid" /></div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Start Navigate-->
<section class="testinomial-section pad-tb">
  <div class="container">
    <div class="row">
      <div class="col-6 v-center">
        <a [routerLink]="['/', 'portfolio', previousLink]" class="nav_link" i18n>&laquo; Previous</a>
      </div>
      <div class="col-6 v-center">
        <a [routerLink]="['/', 'portfolio', nextLink]" class="nav_link next_nav_link" i18n>Next &raquo;</a>
      </div>
    </div>
  </div>
</section>
<!--End Navigate-->

<!--Start Testinomial-->
<app-testinomial-component></app-testinomial-component>
<!--End Testinomial-->
