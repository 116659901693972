import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  HttpClient,
  HttpParams,
  HttpHeaders,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { StrapiResponse } from '../types/strapi';
import {
  Category,
  DicomBlog,
  Tag,
} from '../types/dicom-blog';

@Injectable({
  providedIn: 'root',
})
export class BlogService {
  private API_CMS = `${environment.apiStrapi}`;
  blogs: DicomBlog[] = [];
  private headers: HttpHeaders;
  private STRAPI_AUTH_TOKEN = environment.strapiToken; // Replace with your actual Strapi API Token

  constructor(
    private httpClient: HttpClient,
    // private httpHeaders: HttpHeaders

  ) {
    this.headers = new HttpHeaders()
    this.headers.set('Authorization', `Bearer ${this.STRAPI_AUTH_TOKEN}`);
    this.headers.set('Content-Type', `application/json`);
  }


  getBlogs(language = 'en'): Observable<StrapiResponse<DicomBlog[]>> {
    let params = new HttpParams();

    params = params
      .set('locale', language)
      .set('fields[0]', 'title')
      .set('fields[1]', 'slug')
      .set('fields[2]', 'defaultPublishedDate')
      .set('fields[3]', 'publishedAt')
      .set('fields[4]', 'views')
      .set('populate[categories][fields]', 'title')
      .set('populate[tags][fields]', 'title')
      .set('populate[titleImage][fields]', 'formats')
      .set('populate[user][fields]', 'firstName')
      // .set('populate', '*')
      .set('sort[0]', 'defaultPublishedDate:desc')
      .set('sort[1]', 'publishedAt:desc');

    return this.httpClient.get<StrapiResponse<DicomBlog[]>>(
      `${this.API_CMS}/dicom-blogs`,
      {
        headers: this.headers,
        params,
      }
    );
  }

  getRecentBlogs(language = 'en'): Observable<StrapiResponse<DicomBlog[]>> {
    let params = new HttpParams();

    params = params
      .set('locale', language)
      .set('fields[0]', 'title')
      .set('fields[1]', 'slug')
      .set('fields[2]', 'defaultPublishedDate')
      .set('fields[3]', 'publishedAt')
      .set('populate[titleImage][fields]', 'formats')
      .set('sort[0]', 'defaultPublishedDate:desc')
      .set('sort[0]', 'publishedAt:desc')
      .set('pagination[pageSize]', '5')

    return this.httpClient.get<StrapiResponse<DicomBlog[]>>(
      `${this.API_CMS}/dicom-blogs`,
      {
        headers: this.headers,
        params,
      }
    )
  }

  getCategories(language = 'en'): Observable<StrapiResponse<Category[]>> {
    let params = new HttpParams();
    params = params
      .set('locale', language)
      .set('populate[dicomBlogs][fields]', 'id')
      .set('filters[dicomBlogs][$gt]', '0');

    return this.httpClient.get<StrapiResponse<Category[]>>(
      `${this.API_CMS}/blog-categories`,
      {
        headers: this.headers,
        params,
      }
    );
  }

  getTags(language = 'en'): Observable<StrapiResponse<Tag[]>> {
    let params = new HttpParams();
    params = params
      .set('locale', language)
      .set('populate', 'dicomBlogs').set('filters[dicomBlogs][$gt]', '0');

    return this.httpClient.get<StrapiResponse<Tag[]>>(
      `${this.API_CMS}/blog-tags`,
      {
        headers: this.headers,
        params,
      }
    );
  }

  getBlogBySlug(slug: string, language = 'en'): Observable<StrapiResponse<DicomBlog[]>> {
    let params = new HttpParams();
    params = params
      .set('locale', language)
      .set('filters[slug][$eq]', slug || '')
      .set('populate[titleImage][fields]', 'formats')
      .set('populate[user][fields][0]', 'firstName')
      .set('populate[user][populate]', 'avatar')
      .set('populate[tags][fields]', 'title')
      .set('populate[localizations][fields]', 'slug')

    return this.httpClient.get<StrapiResponse<DicomBlog[]>>(
      `${this.API_CMS}/dicom-blogs`,
      {
        headers: this.headers,
        params,
      }
    );
  }

  countBlogView(documentId: string, language = 'en', views: number): Observable<StrapiResponse<DicomBlog>> {
    return this.httpClient.put<StrapiResponse<DicomBlog>>(
      `${this.API_CMS}/dicom-blogs/${documentId}?locale=${language}`,
      {
        data: { views },
      },
      {
        headers: this.headers
      }
    );
  }

  formatBlogDate(blog: DicomBlog) {
    // Extract the date part (ignoring the extra number at the end)
    const datePart = blog?.defaultPublishedDate || blog?.publishedAt?.split(" ")[0];
    // Convert to Date object
    const date = new Date(datePart);

    // Format the date
    blog.blogDate = date.toLocaleDateString("en-US", {
      month: "short", // "Feb"
      day: "2-digit", // "18"
      year: "numeric" // "2025"
    });

    // return may nothing because we already set blog Object
    return blog;
  }

}
